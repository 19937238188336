<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />


<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: none; z-index: 1051;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>


<div id="modal-borrar-prueba" tabindex="-1" role="dialog" class="modal fade in"
  style="display: none; padding-right: 17px;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header bg-danger">
        <button type="button" data-dismiss="modal" aria-hidden="true" class="close">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div class="i-circle text-danger">
            <i class="icon s7-close"></i>
          </div>
          <h4>¿Are you sure?</h4>
          <p>You are about to delete class {{ nombreSeleccionado }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-outline-light"
          style="margin-right: 15px; color: #000000;">
          Cancel
        </button>
        <button (click)="borrarPrueba()" type="button" class="btn btn-outline-danger">
          <i class="mdi mdi-delete-forever"></i>
          Delete
        </button>
      </div>
    </div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="row w-100 ml-0">
        <div class="col-12">
          <div class="card" style="margin-bottom: 50px; margin-top: 35px;">
            <div class="card-body" style="padding:0 !important;">
              <ul class="nav nav-pills bg-light nav-justified mb-3">
                <li class="nav-item">
                  <a href="#home1" data-toggle="tab" aria-expanded="true" class="nav-link rounded-0 active show">
                    <i class="mdi mdi-magnify mr-1"></i>
                    <span class="d-none d-lg-block">Show Info</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#profile1" data-toggle="tab" aria-expanded="false" class="nav-link rounded-0">
                    <i class="mdi mdi-view-list mr-1"></i>
                    <span class="d-none d-lg-block">Class List</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#settings1" data-toggle="tab" aria-expanded="false" class="nav-link rounded-0">
                    <i class="mdi mdi-vector-polyline mr-1"></i>
                    <span class="d-none d-lg-block">Circuits</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#messages1" data-toggle="tab" aria-expanded="false" class="nav-link rounded-0">
                    <i class="mdi mdi-message mr-1"></i>
                    <span class="d-none d-lg-block">Messages</span>
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane active show" id="home1">
                  <div class="tab-content">
                    <div class="col-12">
                      <form>
                        <div class="row">
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <label>Name</label>
                              <input (change)="nombreChange()" type="text" class="form-control"
                                [formControl]="nombreControl"
                                [ngClass]="{'input-error': nombreControl.invalid && (nombreControl.touched || nombreControl.dirty)}">
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-3">
                              <label>Club</label>
                              <input type="text" class="form-control" [matAutocomplete]="autocompleteClub"
                                [formControl]="clubControl"
                                [ngClass]="{'input-error': clubControl.invalid && (clubControl.touched || clubControl.dirty)}">
                              <mat-autocomplete #autocompleteClub="matAutocomplete" [displayWith]="displayClub">
                                <mat-option (click)="clubChange()" *ngFor="let club of clubs | filterClub : queryClub"
                                  [value]="{id_club: club.id_club, club: club.club}">
                                  <img [src]="club.logo_url" aria-hidden height="25" style="vertical-align:middle;"
                                    onerror="this.style.display='none'">
                                  <span>{{ (club.club) ? club.club : '-' }}</span>
                                </mat-option>
                              </mat-autocomplete>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group mb-3 ui-fluid">
                              <label>Start</label>
                              <input type="text" class="form-control date" data-toggle="date-picker" data-single-date-picker="true" [formControl]="inicioControl" onchange="window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunctionInicioChange(this.value); });">
                              <!--input type="text" class="form-control" data-toggle="input-mask"
                                data-mask-format="0000-00-00 00:00" maxlength="19" [formControl]="inicioControl"
                                (change)="inicioChange()"-->
                              <span class="font-13 text-muted">e.g "MM/DD/YYYY"</span>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group mb-3 ui-fluid">
                              <label>End</label>
                              <input type="text" class="form-control date" data-toggle="date-picker"
                                data-single-date-picker="true" [formControl]="finControl"
                                onchange="window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunctionFinChange(this.value); });">
                              <!--input type="text" class="form-control" data-toggle="input-mask"
                                data-mask-format="0000-00-00 00:00" maxlength="19" [formControl]="finControl"
                                (change)="finChange()"-->
                              <span class="font-13 text-muted">e.g "MM/DD/YYYY"</span>
                            </div>
                          </div>
                          <!-- <div class="col-4">
                            <div class="form-group mb-3">
                              <label>Nomination Fee</label>
                              <input (change)="nominacionChange()" type="number" min="0" class="form-control"
                                [formControl]="nominacionControl"
                                [ngClass]="{'input-error': nominacionControl.invalid && (nominacionControl.touched || nominacionControl.dirty)}">
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <label>% to Deduct</label>
                              <input (change)="deduccionChange()" type="number" min="0" class="form-control"
                                [formControl]="deduccionControl"
                                [ngClass]="{'input-error': deduccionControl.invalid && (deduccionControl.touched || deduccionControl.dirty)}">
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <label>Scratch Fee < $50K</label> <input (change)="desinscripcion1Change()" type="number"
                                    min="0" class="form-control" [formControl]="desinscripcion1Control"
                                    [ngClass]="{'input-error': desinscripcion1Control.invalid && (desinscripcion1Control.touched || desinscripcion1Control.dirty)}">
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <label>Scratch Fee >= $50K</label>
                              <input (change)="desinscripcion2Change()" type="number" min="0" class="form-control"
                                [formControl]="desinscripcion2Control"
                                [ngClass]="{'input-error': desinscripcion2Control.invalid && (desinscripcion2Control.touched || desinscripcion2Control.dirty)}">
                            </div>
                          </div> -->
                          <!-- <ng-container *ngIf="!reining">
                            <div class="col-6">
                              <div class="form-group mb-3">
                                <label>FEI Fee 1</label>
                                <input (change)="feiFee1Change()" type="numbre" min="0" class="form-control"
                                  [formControl]="feiFee1Control">
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group mb-3">
                                <label>FEI Fee 2</label>
                                <input (change)="feiFee2Change()" type="numbre" min="0" class="form-control"
                                  [formControl]="feiFee2Control">
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group mb-3">
                                <label>FEI Fee 3</label>
                                <input (change)="feiFee3Change()" type="numbre" min="0" class="form-control"
                                  [formControl]="feiFee3Control">
                              </div>
                            </div>
                          </ng-container> -->
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <label>Admin eMail</label>
                              <input (change)="correoChange()" type="email" class="form-control"
                                [formControl]="correoControl">
                            </div>
                          </div>

                          <ng-container *ngIf="!reining">
                            <div class="col-2">
                              <div class="form-group mb-3">
                                <label>FEI Show ID</label>
                                <input (change)="officialIDChange()" type="text" class="form-control"
                                  [formControl]="officialIDControl">
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="form-group mb-3">
                                <label>Usef ID</label>
                                <input (change)="usefIDChange()" type="text" class="form-control"
                                  [formControl]="usefIDControl">
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="form-group mb-3" *ngIf="organizingCountry.value === 'CAN'">
                                <label>EC ID</label>
                                <input (change)="ecIDChange()" type="text" class="form-control"
                                  [formControl]="ecIDControl">
                              </div>
                            </div>
                          </ng-container>

                          

                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Entries Due</label>
                              <input (change)="entriesDueChange()" type="text" class="form-control" [formControl]="entriesDue" data-toggle="input-mask" data-mask-format="00/00/0000" maxlength="10" placeholder="MM/DD/YYYY" [pattern]="dateFormatRegEx.source" [ngClass]="{'is-invalid': entriesDue?.value && entriesDue?.errors}">
                              <div class="invalid-feedback" *ngIf="entriesDue?.value && entriesDue?.errors && entriesDue?.errors?.pattern">
                                Invalid date format. Please use MM/DD/YYYY
                            </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Deposit Amount</label>
                              <input (change)="entryAmountChange()" type="number" class="form-control"
                                [formControl]="entryAmount">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Close online entries (Central Standard Time Zone)</label>
                              <input (change)="horarioCierreChange()" type="text" class="form-control" [formControl]="horarioCierreOnlineControl" data-toggle="input-mask" data-mask-format="00:00" maxlength="5" placeholder="HH:MM (24 Hrs.)">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Time zone</label>
                              <select class="form-control" [formControl]="timezone" (change)="timeZoneChange()">
                                <option value="America/New_York_DST">Eastern DST</option><!-- -3 -->
                                <option value="America/New_York">Eastern</option><!-- -4 -->
                                <option value="America/Chicago">Central</option><!-- -5 -->
                                <option value="America/Denver">Mountain DST</option><!-- -6 -->
                                <option value="America/Phoenix">Mountain</option><!-- -7 -->
                                <option value="America/Los_Angeles_DST">Pacific DST</option><!-- -7 -->
                                <option value="America/Los_Angeles">Pacific</option><!-- -8 -->
                                <option value="America/Anchorage">Alaska</option><!-- -8 -->
                                <option value="America/Adak">Hawaii</option><!-- -9 -->
                                <option value="Pacific/Honolulu">Hawaii no DST</option><!-- -10 -->
                              </select>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-3">
                              <label>Prize currency</label>
                              <select class="form-control" [formControl]="prizeCurrency" (change)="prizeCurrencyChange()">
                                <option value=""></option>
                                <option value="USD">USD</option>
                                <option value="CAD">CAD</option>
                                <option value="MXN">MXN</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-3">
                              <label>Organizing country</label>
                              <select class="form-control" [formControl]="organizingCountry" (change)="organizingCountryChange()">
                                <option value=""></option>
                                <option [value]="pais.siglas" *ngFor="let pais of paises">{{ pais.country || pais.pais }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-3">
                              <label>Show Type</label>
                              <input type="text" class="form-control" 
                                [formControl]="showType" 
                                placeholder="e.g. Gold, Silver, etc."
                                (change)="showTypeChange()">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Show title link</label>
                              <input (change)="titleLinkChange()" type="text" class="form-control" [formControl]="titleLink">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Show link</label>
                              <input (change)="buttonLinkChange()" type="text" class="form-control" [formControl]="buttonLink">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Show title link 2</label>
                              <input (change)="titleLink2Change()" type="text" class="form-control" [formControl]="titleLink2">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Show link 2</label>
                              <input (change)="buttonLink2Change()" type="text" class="form-control" [formControl]="buttonLink2">
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mb-3">
                              <label>Sales Tax %</label>
                              <input (change)="salesTaxPercentChange()" type="number" min="0" class="form-control" [formControl]="salesTaxPercentControl" [ngClass]="{'input-error': salesTaxPercentControl.invalid && (salesTaxPercentControl.touched || salesTaxPercentControl.dirty)}">
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group mb-3">
                                  <label>Rings</label>
                                  <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="pistaSeleccionada" [ngModelOptions]="{standalone: true}">
                                    <div class="input-group-prepend">
                                      <button type="button" class="btn btn-primary" (click)="agregarPista()">+</button>
                                    </div>
                                  </div>
                                  <ul class="list-group mt-2">
                                    <li class="list-group-item d-flex justify-content-between align-items-center py-1 px-2" *ngFor="let pista of pistas; let i = index">
                                      <p style="display: block;width: 50%;margin: 0;">
                                        {{ pista }} <br>
                                        <small *ngIf="juecesPistas.length">
                                          current judge: {{ returnUser(pista) }}
                                        </small>
                                      </p>
                                      <select class="form-control" style="width: 40%;margin: 0;" (change)="assingUserToRing($event, pista)">
                                        <option value="" selected disabled> {{ returnUser(pista) == 'No judge assigned'  ? '-- Select a user for scoring --' : '-- Select another user for scoring --' }} </option>
                                        <option *ngFor="let juez of jueces" [value]="juez.id">{{ juez.nombre }}</option>
                                      </select>
                                      <a href="javascript:;" (click)="eliminarPista(i)">
                                        <i class="mdi mdi-close"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group mb-3">
                                  <label>Buttons</label>
                                  <table class="table table-sm table-hover">
                                    <thead>
                                      <tr>
                                        <th><b>Title</b></th>
                                        <th><b>Link</b></th>
                                        <th><b>Preview</b></th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr [formGroup]="formButtons" id="form-buttons">
                                        <td><input type="text" class="form-control form-control-sm" formControlName="title" required=""></td>
                                        <td><input type="text" class="form-control form-control-sm" formControlName="link" required=""></td>
                                        <td>
                                          <a [href]="formButtons?.value?.link||null" target="_blank" class="btn btn-sm btn-dark" [ngClass]="{'disabled': formButtons.disabled}">
                                            {{formButtons?.value?.title||'title'}}
                                          </a>
                                        </td>
                                        <td class="table-action">
                                          <a href="javascript: void(0);" class="action-icon text-success" data-toggle="tooltip" title="Add button" (click)="addButtonLink()">
                                            <i class="mdi mdi-link-plus mdi-24px"></i>
                                          </a>
                                        </td>
                                      </tr>
                                      <ng-container *ngFor="let buttonLink of linkButtons; let index = index;">
                                        <tr>
                                          <td><input type="text" class="form-control form-control-sm" [id]="'buttonTitle'+index" [value]="buttonLink.get('title')" required="" [disabled]="!buttonLink.has('edit') || buttonLink.has('saving')"></td>
                                          <td><input type="text" class="form-control form-control-sm" [id]="'buttonLink'+index" [value]="buttonLink.get('link')" required="" [disabled]="!buttonLink.has('edit') || buttonLink.has('saving')"></td>
                                          <td>
                                            <a [href]="buttonLink.get('link')||null" target="_blank" class="btn btn-sm btn-dark" [ngClass]="{'disabled': buttonLink.has('saving')}">
                                              {{buttonLink.get('title')||'title'}}
                                            </a>
                                          </td>
                                          <td class="table-action">
                                            <ng-container *ngIf="!buttonLink.has('edit')">
                                              <a href="javascript: void(0);" class="action-icon" data-toggle="tooltip" title="Edit button" (click)="enableEditButton(index)" [ngClass]="{'disabled': buttonLink.has('saving')}"> <i class="mdi mdi-pencil"></i></a>
                                              <a href="javascript: void(0);" class="action-icon" data-toggle="tooltip" title="Delete button" (click)="deleteButtonLink(index)" [ngClass]="{'disabled': buttonLink.has('saving')}"> <i class="mdi mdi-delete"></i></a>
                                            </ng-container>
                                            <ng-container *ngIf="buttonLink.has('edit')">
                                              <a href="javascript: void(0);" class="action-icon" data-toggle="tooltip" title="Save button" (click)="editButtonLink(index)" [ngClass]="{'disabled': buttonLink.has('saving')}"> <i class="mdi mdi-content-save"></i></a>
                                              <a href="javascript: void(0);" class="action-icon" data-toggle="tooltip" title="Cancel" (click)="disableEditButton(index)" [ngClass]="{'disabled': buttonLink.has('saving')}"> <i class="mdi mdi-close"></i></a>
                                            </ng-container>
                                          </td>
                                        </tr>
                                      </ng-container>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="publicoChange()" id="publico" type="checkbox" class="custom-control-input" [formControl]="publicoControl">
                                <label class="custom-control-label" for="publico">Publish</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="edChange()" id="ed" type="checkbox" class="custom-control-input" [formControl]="edControl">
                                <label class="custom-control-label" for="ed">in ED</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="inscripcionesRemotasChange()" id="inscripcionesRemotas" type="checkbox" class="custom-control-input" [formControl]="inscripcionesRemotasControl">
                                <label class="custom-control-label" for="inscripcionesRemotas">Online Entries</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="videoForSaleChange()" id="videosForSale" type="checkbox" class="custom-control-input" [formControl]="videosForSale">
                                <label class="custom-control-label" for="videosForSale">Videos for sale</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="pagoConChequeChange()" id="pagoConCheque" type="checkbox" class="custom-control-input" [formControl]="pagoConChequeControl">
                                <label class="custom-control-label" for="pagoConCheque">Pay with check (Online entries)</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="salesTaxChange()" id="salesTax" type="checkbox"
                                  class="custom-control-input" [formControl]="salesTaxControl">
                                <label class="custom-control-label" for="salesTax">Sales Tax</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="feedOrdersSalesTaxChange()" id="feedOrdersSalesTax" type="checkbox"
                                  class="custom-control-input" [formControl]="feedOrdersSalesTaxControl">
                                <label class="custom-control-label" for="feedOrdersSalesTax">Feed Orders Sales Tax</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="merchSalesTaxChange()" id="merchSalesTax" type="checkbox" class="custom-control-input" [formControl]="merchSalesTaxControl">
                                <label class="custom-control-label" for="merchSalesTax">Merch Sales Tax</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group mb-3">
                              <div class="custom-control custom-checkbox">
                                <input (change)="divisionChampsChange()" id="divisionChamps" type="checkbox" class="custom-control-input" [formControl]="divisionChampsControl">
                                <label class="custom-control-label" for="divisionChamps">Division Champs</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-4" style="margin-bottom: 20px;float:left;">
                            <a class="mr-2" *ngIf="convocatoria" [href]="convocatoria" target="_blank"><button type="button" class="btn btn-outline-danger"><i class="mdi mdi-file-pdf"></i></button></a>
                            <button class="btn btn-outline-success btn-rounded" onclick="$('#convocatoria').click()" type="button">Upload Prize List (pdf)</button>
                            <input style="display: none;" type="file" accept="application/pdf" name="convocatoria"  id="convocatoria" (change)="subirImagen($event, 'convocatoria')">
                          </div>
                          <div class="col-4" style="margin-bottom: 20px;float:left;">
                            <a class="mr-2" *ngIf="schedule" [href]="schedule" target="_blank"><button type="button"
                                class="btn btn-outline-danger"><i class="mdi mdi-file-pdf"></i></button></a>
                            <button class="btn btn-outline-success btn-rounded" onclick="$('#schedule').click()"
                              type="button">Upload Show Schedule (pdf)</button>
                            <input style="display: none;" type="file" accept="application/pdf" name="schedule"
                              id="schedule" (change)="subirImagen($event, 'schedule')">
                          </div>
                          <div class="col-4" style="margin-bottom: 20px;float:left;">
                            <a class="mr-2" *ngIf="feedForm" [href]="feedForm" target="_blank"><button type="button"
                                class="btn btn-outline-danger"><i class="mdi mdi-file-pdf"></i></button></a>
                            <button class="btn btn-outline-success btn-rounded" onclick="$('#feedForm').click()"
                              type="button">Upload Feed Form (pdf)</button>
                            <input style="display: none;" type="file" accept="application/pdf" name="feedForm"
                              id="feedForm" (change)="subirImagen($event, 'feedForm')">
                          </div>
                          <div class="col-4" style="margin-bottom: 20px;float:left;">
                            <a class="mr-2" *ngIf="entryBlank" [href]="entryBlank" target="_blank"><button type="button"
                                class="btn btn-outline-danger"><i class="mdi mdi-file-pdf"></i></button></a>
                            <button class="btn btn-outline-success btn-rounded" onclick="$('#entryBlank').click()"
                              type="button">Upload Entry Blank (pdf)</button>
                            <input style="display: none;" type="file" accept="application/pdf" name="entryBlank"
                              id="entryBlank" (change)="subirImagen($event, 'entryBlank')">
                          </div>
                          <div class="col-4" style="margin-bottom: 20px;float:left;">
                            <a class="mr-2" *ngIf="facilityMap" [href]="facilityMap" target="_blank"><button type="button"
                                class="btn btn-outline-danger"><i class="mdi mdi-file-pdf"></i></button></a>
                            <button class="btn btn-outline-success btn-rounded" onclick="$('#facilityMap').click()"
                              type="button">Upload Facility Map (pdf)</button>
                            <input style="display: none;" type="file" accept="application/pdf" name="facilityMap"
                              id="facilityMap" (change)="subirImagen($event, 'facilityMap')">
                          </div>
                          <div class="col-4" style="margin-bottom: 20px;float:left;">
                            <a class="mr-2" *ngIf="invoiceFooter" [href]="invoiceFooter" target="_blank"><button
                                type="button" class="btn btn-outline-info"><i class="mdi mdi-image"></i></button></a>
                            <button class="btn btn-outline-success btn-rounded" onclick="$('#invoiceFooter').click()"
                              type="button">Invoice footer</button>
                            <input style="display: none;" type="file" accept="image/png, image/jpeg" name="invoiceFooter"
                              id="invoiceFooter" (change)="subirImagen($event, 'invoiceFooter')">
                          </div>
                          <div class="col-12" style="margin-bottom: 20px;float: left;">
                            <div class="row">
                              <div class="preview">
                                <h4><b>Cover Image</b><small> </small></h4>
                                <div class="col-12">
                                  <img id="img-portada" style="max-width: 100%;" [src]="portada"
                                    onerror="this.src='assets/images/icons/portada_publico_demo.jpg'">
                                  <div class="centered" *ngIf="subiendoPortada">
                                    <div class="progress">
                                      <div class="progress-bar bg-info" role="progressbar"
                                        [style.width]="porcentajeSubida | percent" aria-valuenow="50" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                    </div>
                                  </div>
                                  <div class="loading-background"
                                    style="width: calc(100% - 30px) !important; margin: 0 15px;" *ngIf="subiendoPortada">
                                  </div>
                                </div>
                                <div class="col-12" style="margin:30px 0; text-align:center;">
                                  <p id="portada_estatus"></p>
                                  <button [disabled]="subiendoPortada" type="button"
                                    class="btn btn-outline-info btn-rounded"
                                    onclick="$('#portada').click()">Change</button>
                                  <input style="display: none;" type="file" accept="image/jpeg" name="portada"
                                    id="portada" (change)="subirImagen($event, 'portada')">
                                </div>
                              </div>
                              <div class="preview">
                                <div class="col-12">
                                  <h4><b>Header & footer</b><small>(Sample Images)</small></h4>
                                  <div class="col-4">
                                    <img style="max-width: 25%; float: left" id="img-logo" [src]="logo"
                                      onerror="this.src='assets/images/icons/header_demo.jpg'">
                                  </div>
                                  <div class="col-6">
                                    <img style="max-width: 25%; float: right" id="img-header" [src]="header"
                                      onerror="this.src='assets/images/icons/header_demo.jpg'">
                                  </div>
                                  <div class="col-12" style="text-align: center">
                                    <h4 class="desenfoque">
                                      <b>Order of Go</b>
                                    </h4>
                                  </div>
                                  <div class="col-12 desenfoque" style="text-align: center">
                                    <p>1 CHPI 1050 NAME RIDER HORSE 78454 </p>
                                    <p>2 CHPI 1050 NAME RIDER HORSE 78454 </p>
                                    <p>3 CHPI 1050 NAME RIDER HORSE 78454</p>
                                    <p>4 CHPI 1050 NAME RIDER HORSE 78454</p>
                                    <p>5 CHPI 1050 NAME RIDER HORSE 78454 </p>
                                    <p>6 CHPI 1050 NAME RIDER HORSE 78454 </p>
                                    <p>7 CHPI 1050 NAME RIDER HORSE 78454 </p>
                                  </div>
                                  <div class="col-12">
                                    <img style="max-width: 100%" id="img-footer" [src]="footer"
                                      onerror="this.src='assets/images/icons/footer_demo.jpg'">
                                  </div>
                                  <div class="col-12" style="margin:30px 0; text-align:center;">
                                    <button [disabled]="subiendoLogo" class="btn btn-outline-info btn-rounded"
                                      style="margin-right: 15px" onclick="$('#logo-input').click()"
                                      type="button">Logo</button>
                                    <button [disabled]="subiendoHeader" class="btn btn-outline-info btn-rounded"
                                      style="margin-right: 15px" onclick="$('#header-input').click()"
                                      type="button">Header</button>
                                    <button [disabled]="subiendoFooter" class="btn btn-outline-info btn-rounded"
                                      onclick="$('#footer-input').click()" type="button">Footer</button>
                                    <input style="display: none;" type="file" accept="image/jpeg || image/png"
                                      name="header-input" id="logo-input" (change)="subirImagen($event, 'logo')">
                                    <input style="display: none;" type="file" accept="image/jpeg" name="header-input"
                                      id="header-input" (change)="subirImagen($event, 'header')">
                                    <input style="display: none;" type="file" accept="image/jpeg" name="footer-input"
                                      id="footer-input" (change)="subirImagen($event, 'footer')">
                                  </div>
                                  <div class="centered" *ngIf="subiendoHeader || subiendoFooter">
                                    <div class="progress">
                                      <div class="progress-bar bg-info" role="progressbar"
                                        [style.width]="porcentajeSubida | percent" aria-valuenow="50" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                    </div>
                                  </div>
                                  <div class="loading-background"
                                    style="width: calc(100% - 30px) !important; margin: 0 15px;"
                                    *ngIf="subiendoHeader || subiendoFooter"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="profile1">
                  <div class="tab-content">
                    <div class="col-12" style="margin-bottom: 20px;">
                      <button (click)="createClass()" class="btn btn-outline-info btn-rounded">
                        <i class="mdi mdi-plus-circle-outline mr-1"></i> Add Class
                      </button>
                    </div>
                    <div class="panel-body">
                      <table class="table table-hover table-condensed">
                        <thead>
                          <tr>
                            <th (click)="sortTable('numero_prueba')">
                              <b>#</b>
                              <i *ngIf="currentSort.column === 'numero_prueba'" 
                                 class="mdi" 
                                 [class.mdi-arrow-up]="currentSort.direction === 'asc'"
                                 [class.mdi-arrow-down]="currentSort.direction === 'desc'">
                              </i>
                            </th>
                            <th (click)="sortTable('fullname')">
                              <b>Name</b>
                              <i *ngIf="currentSort.column === 'fullname'" 
                                 class="mdi"
                                 [class.mdi-arrow-up]="currentSort.direction === 'asc'"
                                 [class.mdi-arrow-down]="currentSort.direction === 'desc'">
                              </i>
                            </th>
                            <th (click)="sortTable('modalidad')">
                              <b>Article/Table</b>
                              <i *ngIf="currentSort.column === 'modalidad'" 
                                 class="mdi"
                                 [class.mdi-arrow-up]="currentSort.direction === 'asc'"
                                 [class.mdi-arrow-down]="currentSort.direction === 'desc'">
                              </i>
                            </th>
                            <th (click)="sortTable('division')">
                              <b>Division</b>
                              <i *ngIf="currentSort.column === 'division'" 
                                 class="mdi"
                                 [class.mdi-arrow-up]="currentSort.direction === 'asc'"
                                 [class.mdi-arrow-down]="currentSort.direction === 'desc'">
                              </i>
                            </th>
                            <th (click)="sortTable('charged_by')">
                              <b>Charged by</b>
                              <i *ngIf="currentSort.column === 'charged_by'" 
                                 class="mdi"
                                 [class.mdi-arrow-up]="currentSort.direction === 'asc'"
                                 [class.mdi-arrow-down]="currentSort.direction === 'desc'">
                              </i>
                            </th>
                            <!-- <th><b>Ring</b></th> -->
                            <th><b>Sponsor</b></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let prueba of pruebas">
                            <td>{{ prueba.numero_prueba }}</td>
                            <td>{{ prueba.fullname }}</td>
                            <td> <small>{{ prueba.modalidad.nombre }}</small></td>
                            <td><small>{{ prueba.divisionData?.name }}</small></td>
                            <td>
                              <span *ngIf="prueba.divisionData?.charged_by" 
                                    class="badge" 
                                    [ngClass]="{
                                      'badge-primary': prueba.divisionData?.charged_by === 'class-fees',
                                      'badge-success': prueba.divisionData?.charged_by === 'division-fee',
                                      'badge-warning': prueba.divisionData?.charged_by === 'division-discount'
                                    }">
                                {{ prueba.divisionData?.charged_by | titlecase }}
                              </span>
                            </td>
                            <!-- <td>{{ prueba.pista }}</td> -->
                            <td class="table-action">
                              <a [href]="prueba.sponsorImage" target="_blank"
                                *ngIf="prueba.sponsorImage" class="action-icon"> <i
                                  class="mdi mdi-image-outline"></i></a>
                            </td>
                            <td>
                              <a href="javascript:;" title="Update firestore" (click)="updateFirestoreResults(prueba.id_prueba_concurso)">
                                <i class="mdi mdi-cloud-sync mdi-24px"></i>
                              </a>
                            </td>
                            <td>
                              <button mat-icon-button (click)="editClass(prueba)">
                                <mat-icon>create</mat-icon>
                              </button>
                            </td>
                            <td>
                              <button mat-icon-button (click)="modalBorrar(prueba.id_prueba_concurso, prueba.numero_prueba, prueba.fullname)">
                                <mat-icon>delete</mat-icon>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="settings1">
                  <div class="tab-content" >
                   <app-circuits *ngIf="concurso" [idClub]="concurso?.id_club.toString()" [season]="concurso?.inicio | date: 'yyyy'"></app-circuits>
                  </div>
                </div>
                <div class="tab-pane" id="messages1">
                  <div class="tab-content">
                    <app-show-messages></app-show-messages>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
