<!-- src/app/create-item/create-item.component.html -->
<div class="row" style="width: 100%; display: flex">
  <div class="col-12"><h3>Under development</h3></div>
  <div class="col-6">
    <div class="card mt-4">
      <div class="card-header">
        <h3>Create New Message</h3>
      </div>
      <div class="card-body">
        <form [formGroup]="messageForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="title">Title</label>
            <input type="text"
                   id="title"
                   class="form-control"
                   formControlName="title"
                   maxlength="50">
          </div>
          <div class="form-group">
            <label for="body">Body</label>
            <textarea
              id="body"
              class="form-control"
              (input)="onEditorContentChanged($event)"
              formControlName="body"
              placeholder="Write message body..."
              style="width: 100%; min-height: 100px; resize: vertical;">
            </textarea>
          </div>
          <div class="form-group">
            <label for="link">Link 🔗</label>
            <input type="text"
                   id="link"
                   class="form-control"
                   formControlName="link"
                   maxlength="50">
          </div>
          <button type="submit" class="btn btn-success mt-2" [disabled]="!messageForm.valid"> Create</button>
        </form>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card mt-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="mb-0">Existing Messages</h3>
        <span class="badge bg-primary-subtle">{{ messages.length }} messages</span>
      </div>
      <div class="card-body p-0">
        <div class="messages-container">
            <div class="message-item" *ngFor="let message of messages; let i = index">
                <div class="message-edit-form p-3 bg-light" *ngIf="editingIndex === i">
                  <form [formGroup]="editForm" (ngSubmit)="saveEdit()">
                    <div class="form-group">
                      <label>Title</label>
                      <input type="text"
                             class="form-control"
                             formControlName="title"
                             maxlength="50">
                    </div>
                    <div class="form-group">
                      <label>Body</label>
                      <textarea
                        class="form-control"
                        (input)="onEditContentChanged($event)"
                        formControlName="body"
                        style="width: 100%; min-height: 100px; resize: vertical;">
                      </textarea>
                    </div>
                    <div class="form-group">
                      <label>Link 🔗</label>
                      <input type="text"
                             class="form-control"
                             formControlName="link"
                             maxlength="50">
                    </div>
                    <div class="btn-group mt-2">
                      <button type="submit" class="btn btn-primary" [disabled]="!editForm.valid">Save</button>
                      <button type="button" class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
                    </div>
                  </form>
                </div>
              <div class="message-content p-3" *ngIf="editingIndex !== i">
                <div class="message-header d-flex justify-content-between align-items-start">
                  <h4 class="mb-1">{{ message.title }}</h4>
                  <div class="btn-group">
                      <button (click)="startEditing(i)" class="btn btn-primary btn-sm">
                        <i class="fas fa-edit"></i> Edit
                      </button>
                      <button (click)="deleteMessage(i)" class="btn btn-danger btn-sm ml-2">
                        <i class="fas fa-trash"></i> Delete
                      </button>
                    </div>
                  </div>
                  <div class="message-body mt-2">
                    <p class="mb-2">{{ message.body }}</p>
                      <a [href]="message.link" target="_blank" class="text-primary" *ngIf="message.link">
                        <i class="fas fa-link"></i> {{ message.link }}
                      </a>
                    
                  </div>
                  <div class="message-footer mt-2">
                    <small class="text-muted">
                      <i class="fas fa-clock"></i> {{ message.posted | date: 'short' }}
                      <span class="ms-2" *ngIf="message.edited">
                        <i class="fas fa-edit"></i> edited: {{ message.edited | date: 'short' }}
                      </span>
                    </small>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
