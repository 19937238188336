import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BtrpayStoredCardsComponent } from './btrpay-stored-cards/btrpay-stored-cards.component';
// declare let $: any;

@Component({
  standalone: true,
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BtrpayStoredCardsComponent]
})

export class PaymentFormComponent implements OnInit {

  @Input() amount: number;
  @Input() reference: string;
  @Input() id_responsable: number;
  @Input() id_entrenador: number;
  @Input() id_caballo: number;
  @Input() entry: string;
  @Input() id_concurso: string | number;
  @Input() id_club: string | number;
  @Input() peoplecards: {};
  @Output() close = new EventEmitter<void>();

  firstName: string = '';
  lastName: string = '';
  alertMessage: string | null = null;
  isSubmitting: boolean = false
  public readyToPay: boolean = false;
  public cardLast4: string | null = null;
  private paymentToken: string | null = null;
  declare CollectJS: any;
  public cardValidated: boolean = false;
  public cardValidationMessage: string | null = null;
  public expValidated: boolean = false;
  public expValidationMessage: string | null = null;
  public cvvValidated: boolean = false;
  public cvvValidationMessage: string | null = null;
  public paymentError: string | null = null;
  public showAddNewCard: boolean = false;
  public type: string = 'add_customer';
  public clubCommission: number = 0; 
  customer_vault_billing_id: string;
  customer_vault_id: string;
  cardholder: string;
  userPeople: any[] = [];
  constructor(private http: HttpClient, private changeDetector: ChangeDetectorRef) {
    this.amount = 0
  }
  

  ngOnInit(): void {
    // Add event listener for the pay button
    const payButton = document.getElementById('payButton');
    if (payButton) {
      payButton.addEventListener('click', (event) => {
        console.log('Pay button clicked');
        this.isSubmitting = true; // Set isSubmitting to true immediately when the button is clicked
    event.preventDefault();
        console.log('Submitting:', this.isSubmitting);

      });
    }

    this.getUserPeopleList();

    // add comission fetch
    if (this.id_club) {
      this.http.get(`${environment.api.nest.uri}clubs/${this.id_club}`).subscribe(
        (response: any) => {
          
          this.clubCommission = response.data.comision_stripe || 3.25;
          console.log('Club commission:', this.clubCommission);
        },
        (error) => {
          console.error('Error fetching club commission:', error);
        }
      );
    }

    
  }

  getUserPeopleList(): void {
    const uniqueIds = [...new Set([
      (this.peoplecards as any)?.owner, 
      ...((this.peoplecards as any)?.riders ?? []), 
      (this.peoplecards as any)?.trainer
    ])].join(',');

    console.log('Unique IDs:', uniqueIds);
    try {
      this.http.get(`${environment.api.nest.uri}betterpay/stored-cards/${uniqueIds}`, ).subscribe(
        (response: any) => {

          //Filter unique cardholdersconst allCards = Array.isArray(response[0]) 
          const allCards = Array.isArray(response.data[0]) 
          ? response.data.flat()
          : response.data;

          //Filter unique cardholders
          this.userPeople = Object.values(
            allCards.reduce((acc: any, card: any) => {
              if (!acc[card.cardholder]) {
              acc[card.cardholder] = {
                id: card.id,
                id_persona: card.id_persona,
                cardholder: card.cardholder,
                customer_vault_billing_id: card.customer_vault_billing_id,
                customer_vault_id: card.customer_vault_id
              };
            }
              return acc;
            }, {})
          );
          console.log('User people list:', this.userPeople);
        },
        (error) => {
          console.error('Error fetching stored cards:', error);
        }
     );
    } catch (error) {
      console.error('Error fetching stored cards:', error);
    }
   
  }

  calculateTotal(): string {
    if (!this.amount) return '';
    const baseAmount = parseFloat(this.amount.toString());
    const commission = parseFloat((baseAmount * this.clubCommission/100).toFixed(2));
    return `$${(baseAmount + commission).toFixed(2)}`;
  }

  processPayment(event?: Event): void {
    this.isSubmitting = true;
    this.changeDetector.detectChanges(); // Trigger change detection

    let paymentData: any = {
      first_name: (document.getElementById('first_name') as HTMLInputElement).value,
      last_name: (document.getElementById('last_name') as HTMLInputElement).value,
      address1: (document.getElementById('address') as HTMLInputElement).value,
      zip: (document.getElementById('zip') as HTMLInputElement).value,
      reference: (this.reference),
      amount: this.amount,
      type: this.type,
      api_token: environment.api.key,
      entry: this.entry,
      id_responsable: this.id_responsable,
      id_concurso: this.id_concurso,
      clubCommission: this.clubCommission,
      // payment_token: this.paymentToken,
      // stored_credential_indicator: this.type == 'saved_card' ? 'used' : 'stored',
      // customer_vault: this.type == 'saved_card' ? null : this.type == 'add_card' ? 'update_customer' : 'add_customer',
      // customer_vault_billing_id: this.type == 'saved_card' ? this.customer_vault_billing_id : null,
      // customer_vault_id: this.type == 'saved_card' ? this.customer_vault_id : null,
    }

    // Identify the type of payment
    if (this.type == 'saved_card') {
      console.log('Pay with saved card');
      console.log('Payment id: ', this.customer_vault_billing_id);
      console.log('Customer id: ', this.customer_vault_id);
      console.log('Token id: ', this.paymentToken);
      paymentData.customer_vault_id = this.customer_vault_id;
      paymentData.customer_vault_billing_id = this.customer_vault_billing_id;
      paymentData.stored_credential_indicator = 'used';
    } else if (this.type == 'add_card') {
      console.log('Pay with new card');
      console.log('Customer id: ', this.customer_vault_id);
      console.log('Payment id: ', this.customer_vault_billing_id);
      console.log('Token id: ', this.paymentToken);
      paymentData.customer_vault_id = this.customer_vault_id;
      paymentData.payment_token = this.paymentToken;
      paymentData.customer_vault = 'update_customer';
      paymentData.stored_credential_indicator = 'stored';
    } else if (this.type == 'add_customer') {
      console.log('Pay with new customer');
      console.log('Customer id: ', this.customer_vault_id);
      console.log('Token id: ', this.paymentToken);
      paymentData.payment_token = this.paymentToken;
      paymentData.customer_vault = 'add_customer';
      paymentData.stored_credential_indicator = 'stored';
    }

    try {
      this.http.post(`${environment.api.nest.uri}betterpay/transact`, paymentData).subscribe(
        (response: any) => {
          console.log('Payment response:', response);
          this.isSubmitting = false;
          this.changeDetector.detectChanges(); // Trigger change detection          
          this.close.emit();
        },
        (error: any) => {
          console.error('Payment error:', error);
          this.isSubmitting = false;
          this.paymentError = error.error.message;
          this.changeDetector.detectChanges(); // Trigger change detection

        }
      );
    } catch (error) {
      console.error('Payment error:', error);
      this.isSubmitting = false;
      this.changeDetector.detectChanges(); // Trigger change detection
    }


  }

 
  addNewCard(): void {
    this.showAddNewCard = true;
    this.CollectJS = (window as any).CollectJS;
    this.CollectJS.configure({
      paymentType: 'cc', // valid types Credit Card cc or ck check
      fieldsAvailableCallback: () => {
        console.log("Collect.js loaded the fields onto the form");
      },
      validationCallback: (field, status, message: string) => {
        if (status) {
          if (field === 'ccnumber') {
            this.cardValidated = true;
            this.cardValidationMessage = null;
          } else if (field === 'ccexp') {
            this.expValidated = true;
            this.expValidationMessage = null;
          } else if (field === 'cvv') {
            this.cvvValidated = true;
            this.cvvValidationMessage = null;
          }
          var message = field + " is now OK: " + message;
        } else {
          if (field === 'ccnumber') {
            this.cardValidated = false;
            this.cardValidationMessage = message;
          } else if (field === 'ccexp') {
            this.expValidated = false;
            this.expValidationMessage = message;
          } else if (field === 'cvv') {
            this.cvvValidated = false;
            this.cvvValidationMessage = message;
          }
        }
        console.log(message);

      },
      callback: (response: any) => {
        console.log('CollectJS response:', response);
        const payButton = document.getElementById('payButton') as HTMLButtonElement;
        const payButtonText = payButton?.querySelector('.pay-text');

        if (payButton && payButtonText //&& spinnerIcon
        ) {
          payButton.disabled = true; // Disable the button
          payButtonText.textContent = 'Card Saved. Ready to Pay...'; // Change text
          if (this.customer_vault_id) {
            this.type = 'add_card';
            console.log('Customer vault action: ', this.type);
          } else {
            this.type = 'add_customer';
            console.log('Customer vault action: ', this.type);
          }


        } else {
          console.log('Could not find the necessary elements');
        }

      if (response.error) {
          console.error('Error en la tokenización:', response.error);

          payButtonText.textContent = 'Error'; // Change text
          payButton.style.backgroundColor = 'red';
      } else {
          console.log('Token recibido:', response.token);
          this.readyToPay = true;
          this.paymentToken = response.token;
          this.isSubmitting = false;
          this.changeDetector.detectChanges(); // Trigger change detection


        }
      }, timeoutDuration: 10000,
      timeoutCallback: () => {
        console.log('Timeout callback');
        this.isSubmitting = false;
        this.cardValidationMessage = 'Timed out. Please check your internet connection and try again.';
        this.changeDetector.detectChanges(); // Trigger change detection

      }

    });
  }

  onPayButtonClick(): void {
    console.log('Pay button clicked');
    this.isSubmitting = true; // Set isSubmitting to true immediately when the button is clicked
    console.log('Submitting:', this.isSubmitting);
    //  this.CollectJS.tokenize(); // Trigger CollectJS payment tokenization
  }

  customerVaultId(customerVaultId: string): void {
    this.customer_vault_id = customerVaultId;
    console.log('Customer vault id:', this.customer_vault_id);
    // TODO: 2.1 - Add the customer vault id to the payment data
    // this.type = 'saved_card';
  }

  onCardSelected(cardInfo: {
    cardholder: string; customer_vault_billing_id: string, customer_vault_id: string 
}): void {
    console.log('Selected card info:', cardInfo);
    // Hide the add new card form
    //this.readyToPay = true;
    //this.type = 'saved_card';
    this.customer_vault_billing_id = cardInfo.customer_vault_billing_id;
    this.customer_vault_id = cardInfo.customer_vault_id;
    this.cardholder = cardInfo.cardholder;
    console.log('Using saved payment: ', this.customer_vault_id, this.customer_vault_billing_id, this.cardholder)
    // TODO: 1.1 Add the selected card information to the payment data and process the payment

    
    const nameParts = this.cardholder.split(' ');

    //Fista name is the first part
    const firstName = nameParts[0]; //I will assume that the first name is the first part of the cardholder name

    const lastName = nameParts.slice(1).join(' ');
    // Set the first name and last name in the input fields
    (document.getElementById('first_name') as HTMLInputElement).value = firstName || '';
    (document.getElementById('last_name') as HTMLInputElement).value = lastName || '';
    
  }
}