import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../services/print.service';
import { ClassListService } from '../services/class-list.service';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

interface ClassInfo {
  header: string;
  numero: string;
  nombre: string;
  description?: string;
  pista: string;
  sponsor: string;
  fecha: Date;
  modalidad: string;
  premio: number;
  designer: string;
  ambito: number;
  juez: string;
  altura: string;
  footer?: string;
}

interface Binomio {
  cucarda: string;
  caballo: string;
  heightCategory?: string;
  country: string;
  nombreJinete: string;
  apellidosJinete: string;
  categoria?: string;
  owner: string;
  trainer: string;
  scratched: boolean;
  dateOfBirth?: string;
  gender?: string;
  colorCode?: string;
  colorComplement?: string;
  countryOfBirth?: string;
  breed?: string;
  sire?: string;
  dam?: string;
  sireOfDam?: string;
}

interface Class {
  info: ClassInfo;
  binomios: Binomio[];
}

@Component({
  selector: 'app-print-class-sheets',
  templateUrl: './print-class-sheets.component.html',
  styleUrls: ['./print-class-sheets.component.css']
})
export class PrintClassSheetsComponent implements OnInit {
  private idConcurso: string;
  private documentData: string[];
  public classes: Observable<Class>[] = [];
  public nombreConcurso: string;
  public mostrarInfoCaballo: string;
  public mostrarPaisJinete: string;

  constructor(private route: ActivatedRoute, private printService: PrintService, private classListService: ClassListService) {
    this.idConcurso = this.route.snapshot.paramMap.get('idConcurso');
    this.documentData = this.route.snapshot.paramMap.get('classes').split(',');
    this.nombreConcurso = sessionStorage.getItem('nombreConcurso');
    this.mostrarInfoCaballo = this.route.snapshot.paramMap.get('mostrarInfoCaballo');
    this.mostrarPaisJinete = this.route.snapshot.paramMap.get('mostrarPaisJinete');
  }

  ngOnInit() {
    this.classes = this.documentData.map(c => 
      from(this.classListService.getBinomios(c).toPromise())
    );
    Promise.all(this.classes.map(c => c.toPromise())).then(() => 
      setTimeout(() => { this.printService.onDataReady(); }, 500)
    );
  }

  getClassInfo(class$: any): Class {
    return class$ as Class;
  }

  public infoCaballo(binomio: Binomio): string{
    let datosCaballo = [];
    if(binomio.dateOfBirth){//Calcular la edad en años
        datosCaballo.push(binomio.dateOfBirth);
    }
    if(binomio.gender)
        datosCaballo.push(binomio.gender);
    if(binomio.colorCode)
        datosCaballo.push(binomio.colorCode);
    if(binomio.colorComplement)
        datosCaballo.push(binomio.colorComplement);
    if(binomio.countryOfBirth)
        datosCaballo.push(binomio.countryOfBirth);
    if(binomio.breed)
        datosCaballo.push(binomio.breed);
    if(binomio.sire)
        datosCaballo.push(binomio.sire);
    if(binomio.dam)
        datosCaballo.push(binomio.dam + " x " + binomio.sireOfDam);

    return datosCaballo.join(' / ');
  }
}
