import { Component, Input, OnInit } from '@angular/core';
import { DivisionChartsService } from '../services/division-charts.service';
import { firstValueFrom } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-division-chart',
  templateUrl: './division-chart.component.html',
  styleUrls: ['./division-chart.component.css']
})
export class DivisionChartComponent implements OnInit {
  @Input() public divisionId: number | string;
  @Input() public showName: string;
  @Input() public idConcurso: string | number | null | boolean;
  public divisionChartData: any;
  public divisionName: any;
  public divisionCode: any;
  
  public nombreConcurso: string;

  constructor(
    private divisionChartsService: DivisionChartsService,
  ) {
  }

  ngOnInit() {
    this.getDivisionChartData();
  }

  public async getDivisionChartData() {
    if(!this.divisionId || !this.idConcurso){
      this.handleError('Division ID or Show ID not provided.', 'Something went wrong. Please reload and try again later.');
      return;
    }
    try {
      const response:any = await firstValueFrom(this.divisionChartsService.getDivisionchart(this.divisionId, this.idConcurso));
      if (response.error) {
        this.handleError(response.message, 'Something went wrong. Please reload and try again later.');
        return;
      } else {
        this.divisionChartData = response.data.table;
        this.divisionName = response.data.name;
        this.divisionCode = response.data.code;
      }
    } catch (error) {
      this.handleError(error, 'Something went wrong. Please reload and try again later.');
    }
  }

  public handleError(reason: any, messageToUser?: string) {
    const message = (reason.error || {}).text || (((reason.error || {}).error || {}).message || reason.message || reason.error || 'Error during request.');
    $.NotificationApp.send("Error", messageToUser || message, 'bottom-right', '#fa5c7c', 'error');
    console.log(`%c Handling Error:`, "color: white; font-size: 11px; background-color: #a02d24; padding: 1px 2px; border-radius: 3px;");
    console.error(reason);
  }
}
