<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 1051;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>



<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page p-print-0">
    <!-- Modal rider swap -->
    <ng-container *ngTemplateOutlet="modalRiderSwap"></ng-container>
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid p-print-0">
        <div class="row w-100 mx-0 m-print-0 justify-content-center">
          <div class="col-12">
            <div class="card">
              <div class="card-body p-2">
                <ul class="nav nav-pills bg-light nav-justified mb-3 d-print-none">
                  <li class="nav-item">
                    <a href="#classes" data-toggle="tab" aria-expanded="true" class="nav-link rounded-0 active">
                      <i class="mdi mdi-account-circle mr-1"></i>
                      <span class="d-none d-sm-inline">Results</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#unapplied-prizes" data-toggle="tab" aria-expanded="false" class="nav-link rounded-0">
                      <i class="mdi mdi-face-profile mr-1"></i>
                      <span class="d-none d-sm-inline">See all Unapplied prizes</span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane show active" id="classes">
                    <div class="row d-print-none">
                      <div class="col-12">
                        <div class="form-group">
                          <label>
                            <h4>Select a class..</h4>
                          </label>
                          <select class="form-control" (change)="selectClass($event)" style="max-width: 400px;">
                            <option value=""></option>
                            <option [value]="prueba.ipc" *ngFor="let prueba of pruebas">{{ prueba.numero }} - {{ prueba.nombre }}</option>
                          </select>
                        </div>
                      </div>
                      <ng-container *ngIf="!calificada || (calificada && hunters)">
                        <div class="row col-12">
                          <div class="col-2"  *ngIf="ipc && ((seleccionarDistribucion && distribucion) || !seleccionarDistribucion)">
                            <div class="form-group">
                              <label>Divide class into</label>
                              <select class="form-control" [(ngModel)]="numDivisionesPrueba">
                                <option [ngValue]="1">1</option>
                                <option [ngValue]="2">2</option>
                                <option [ngValue]="3">3</option>
                                <option [ngValue]="4">4</option>
                                <option [ngValue]="5">5</option>
                                <option [ngValue]="6">6</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mb-2" *ngIf="ipc && seleccionarDistribucion && !distribucion">
                          <p>There is no prize distribution assigned to the class, please select one to continue.</p>
                          <p><a href="javascript:;" [routerLink]="['/show-info']"><button type="button" class="btn ptn-primary">Go to class list</button></a></p>
                        </div>
                      </ng-container>
                    </div>
                    <ng-container *ngIf="((!calificada && ipc !='') || (calificada && ipc !='')) || listVerified; else panelEnlaceJueces">
                      <!-- Renglon Cards con Inputs de entries -->
                      <div class="container-fluid p-print-0">
                        <div class="row w-100 mx-0 m-print-0 justify-content-center">
                          <div class="col-12 d-print-none" *ngIf="ipc">
                            <div class="card">
                              <div class="card-body text-center">
                                <button class="btn btn-danger ml-3 mr-3" (click)="clearResults()">Clear results</button>
                                <button class="btn btn-info mr-3" (click)="setClassFinished()" *ngIf="estatusPrueba != '2'">Set as finished</button>
                                <a [href]="'https://scoring.equestrian.digital/score/'+ipc" target="_blank" class="btn btn-info text-white mr-3">Go to scoring</a>
                                <app-import-results [showId]="idConcurso" [classNumber]="classNumber" (onSaved)="onPyramidResultsSaved($event)"></app-import-results>
                                <button *ngIf="(csi || teamCompetition) && privilegios >= 250" class="btn btn-fei ml-3" (click)="teamCompetition ? exportXML() : exportarXML()">
                                  <i class="mdi mdi-sync mr-1"></i>
                                  <span>FEI Results</span>
                                </button>
                              </div>
                            </div>
                          </div>

                          <!-- Card Verify  -->
                          <div class="col-4 mt-2 d-print-none">
                            <div class="card">
                              <div class="card-header py-1 d-flex justify-content-between">
                                <h4 class="m-0">Verify</h4>
                                <span>
                                  <button type="button" class="btn btn-sm btn-success mr-2" (click)="save()">Save</button>
                                  <button type="button" class="btn btn-sm btn-info" (click)="imprimirTablaInscritos()"><i class="mdi mdi-printer mr-1"></i>Print</button>
                                </span>
                              </div>
                              <div class="card-body p-2">
                                <div class="col-12" *ngIf="ipc && ((seleccionarDistribucion && distribucion) || !seleccionarDistribucion)">
                                  <div class="row">
                                    <div class="col-8">
                                      <div class="form-group">
                                        <input type="number" class="form-control" (change)="addEntry($event)" [disabled]="loadAddEntry">
                                      </div>
                                    </div>
                                  </div>
                                  <table class="table table-sm">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th style="width: 30%">Entry</th>
                                        <th>Horse</th>
                                        <th>Did Horse Fall?</th>
                                        <th>Rider</th>
                                        <th>Did Rider Fall?</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let entry of entriesInscritos; let i = index" [ngClass]="{'bg-warning text-white': !isEntryInList(entry.entry)}">
                                        <td>{{ i+1 }}</td>
                                        <td>
                                          <input type="number" [value]="entry.entry" class="form-control input-verifylist" (change)="editEntryRank($event, i)" *ngIf="!isEntryInList(entry.entry)">
                                          <span *ngIf="isEntryInList(entry.entry)">{{ entry.entry }}</span>
                                        </td>
                                        <td>{{ entry.horse }}</td>
                                        <td>
                                          <input type="checkbox" [id]="'horse-fall-'+i" data-switch="success" [(ngModel)]="entry.didHorseFalls"/>
                                          <label [for]="'horse-fall-'+i" [attr.data-on-label]="'Yes'" data-off-label="No"></label>
                                        </td>
                                        <td>{{ entry.rider }}</td>
                                        <td>
                                          <input type="checkbox" [id]="'rider-fall-'+i" data-switch="success" [(ngModel)]="entry.didRiderFalls"/>
                                          <label [for]="'rider-fall-'+i" [attr.data-on-label]="'Yes'" data-off-label="No"></label>
                                        </td>
                                        <td class="table-action">
                                          <a href="javascript: void(0);" class="action-icon" (click)="removeInscrito(i, entry.entry)"> <i class="mdi mdi-close"></i></a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Card resultados-->
                          <div class="col-8 mt-2 d-print-none">
                            <div class="card">
                              <div class="card-header py-1 d-flex justify-content-between">
                                <h4 class="m-0">Place</h4>
                                <button type="button" class="btn btn-sm btn-info" (click)="imprimirTablaResultados()"><i class="mdi mdi-printer mr-1"></i>Print</button>
                              </div>
                              <div class="card-body p-2">
                                <div class="col-12"
                                  *ngIf="ipc && ((seleccionarDistribucion && distribucion) || !seleccionarDistribucion)">
                                  <div class="row">
                                    <ng-container
                                      *ngFor="let num of [].constructor(numDivisionesPrueba); let i = index">
                                      <div class="col-6">
                                        <div class="row">
                                          <div class="col-2">
                                            <h4 *ngIf="numDivisionesPrueba > 1">{{i == 0 ? 'A' : i == 1 ? 'B' : i == 2 ?
                                              'C' : 'D'}}</h4>
                                          </div>
                                          <div class="col-7">
                                            <div class="form-group">
                                              <input type="number" class="form-control" (change)="addEntryRank($event, i)" [disabled]="loadAddEntryRank">
                                            </div>
                                          </div>
                                          <div class="col-2" *ngIf="filtroEntriesPosiciones(i).length > 0">
                                            <div class="form-group">
                                              <button type="button" class="btn btn-success"
                                                (click)="save()">Save</button>
                                            </div>
                                          </div>
                                        </div>
                                        <table class="table table-sm">
                                          <thead>
                                            <tr>
                                              <th>Rank</th>
                                              <th>Entry</th>
                                              <th>Horse</th>
                                              <th>Rider</th>
                                              <th *ngIf="premio > 0">Prize</th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let entry of filtroEntriesPosiciones(i); let i = index">
                                              <td>
                                                <input type="number" class="form-control input-rank"
                                                  [value]="entry.rank" (change)="cambiarPosicion($event, entry.entry)">
                                              </td>
                                              <td>{{ entry.entry }}</td>
                                              <td>{{ entry.horse }}</td>
                                              <td>{{ entry.rider }}</td>
                                              <td *ngIf="premio > 0">
                                                <input type="number" min="0" class="form-control input-verifylist"
                                                  [value]="entry.prize" (change)="editPrize($event, i)">
                                              </td>
                                              <td class="table-action">
                                                <a href="javascript: void(0);" class="action-icon"
                                                  (click)="removeCalificado(entry.entry)"> <i
                                                    class="mdi mdi-close"></i></a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Tabla lista de entrada -->
                      <div class="container-fluid p-print-0" [ngClass]="{'d-print-block': imprimirInscritos}">
                        <div class="row m-print-0 justify-content-center">
                          <div class="col-12">
                            <div class="card m-0">

                              <div class="card-header py-1 d-flex d-print-none justify-content-between">
                                <h4 class="m-0">Entries in class</h4>
                              </div>

                              <!-- Print headers -->
                              <div class="row d-none d-print-none">
                                <div class="col-4 text-center">
                                  <img *ngIf="header" style="max-height: 90px;" [src]="header">
                                </div>
                                <div class="col-4 text-center">
                                  <h3></h3>
                                  <p>{{ nombreConcurso }}<br><b>{{ numeroPrueba }} </b> {{ nombrePrueba }}</p>
                                </div>
                                <div class="col-4">
                                  <img *ngIf="header2" style="max-height: 90px;" [src]="header2">
                                </div>
                              </div>

                              <table class="table table-sm mb-0 d-print-none">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Horse</th>
                                    <th>Rider</th>
                                    <th></th>
                                    <th class="d-print-none"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let entry of tablaEntradasArray; let i = index" [ngClass]="{'scratched': entry.scratched}">
                                    <td>{{ i+1 }}</td>
                                    <td><b>{{ entry.entry }}</b> {{ entry.horse }}</td>
                                    <td>{{ entry.rider }}</td>
                                    <td>{{ entry.message }}</td>
                                    <td class="d-print-none table-action">
                                      <button class="btn btn-link action-icon" (click)="dropEntry(entry?.entry)" *ngIf="entry.message == 'DNS'" [disabled]="loading.get(entry?.entry)">
                                        <span class="badge badge-danger">
                                          <span class="spinner" *ngIf="loading.get(entry?.entry)"></span>
                                          Drop
                                        </span>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p class="pl-2 mt-3 d-print-none"> Powered by <img src="assets/images/logo.png" style="width: 15px;"> EquestrianDigital </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #panelEnlaceJueces>
                      <div class="col-12 d-print-none" *ngIf="ipc">
                        <div class="card">
                          <div class="card-body text-center">
                            <button class="btn btn-danger ml-3 mr-3" (click)="clearResults()">Clear results</button>
                            <button class="btn btn-info mr-3" (click)="setClassFinished()" *ngIf="estatusPrueba != '2'">Set as finished</button>
                            <a href="https://scoring.equestrian.digital/score/{{ ipc }}" target="_blank" class="btn btn-info text-white mr-3">Go to scoring</a>
                            <app-import-results [showId]="idConcurso" [classNumber]="classNumber" (onSaved)="onPyramidResultsSaved($event)"></app-import-results>
                            <button *ngIf="(csi || teamCompetition) && privilegios >= 250" class="btn btn-fei ml-3" (click)="teamCompetition ? exportXML() : exportarXML()">
                              <i class="mdi mdi-sync mr-1"></i>
                              <span>FEI Results</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <ng-container [ngTemplateOutlet]="panelResultados"></ng-container>
                    <!-- Imprimir lista de Resultados -->
                    <ng-template #panelResultados>
                      <div class="container-fluid p-print-0" [ngClass]="{'d-print-none': imprimirInscritos || caliSplit}">
                        <div class="row m-print-0 justify-content-center">
                          <div class="col-12 mt-4 m-print-0">
                            <div class="card">
                              <div class="form-group mt-2 mb-0 ml-auto d-print-none" *ngIf="ipc && resultados.length > 0">
                                <button *ngIf="(csi || teamCompetition) && privilegios >= 250" class="btn btn-fei mr-2" (click)="teamCompetition ? exportXML() : exportarXML()">
                                  <i class="mdi mdi-sync mr-1"></i>
                                  <span>FEI Results</span>
                                </button>
                                <button class="btn btn-light mr-2" (click)="exportarExcel()">
                                  <i class="mdi mdi-file-excel-box mr-1"></i>
                                  <span>Excel</span>
                                </button>
                                <button class="btn btn-info mr-2" (click)="imprimirTablaResultadosCaliSplit()">
                                  <i class="mdi mdi-printer mr-1"></i>
                                  <span>Cali Split</span>
                                </button>
                                <button type="button" class="btn btn-info mr-2" (click)="openCaliSplitModal()">
                                  <i class=" mdi mdi-arrow-split-vertical"></i>
                                  <span>Cali Split</span>
                                </button>
                                <button class="btn btn-info mr-2" (click)="imprimirTablaResultados()">
                                  <i class="mdi mdi-printer mr-1"></i>
                                  <span>Print</span>
                                </button>

                                <ng-container *ngIf="riderCategories.length > 0">
                                  <button type="button" class="btn btn-secondary ml-2 mr-2" (click)="separateCategory(cat)" *ngFor="let cat of riderCategories">
                                    Split
                                    <span [ngSwitch]="cat">
                                      <span *ngSwitchCase="'JUNIOR'"> Jr/Ch</span>
                                      <span *ngSwitchDefault> {{ cat }}</span>
                                    </span>
                                  </button>
                                </ng-container>
                              </div>
                              <div class="col-12 d-print-none" *ngIf="resultados.length > 0 && prueba.categorias.length > 1">
                                <div class="row">
                                  <button class="btn btn-outline-secondary mr-2" *ngFor="let categoria of prueba.categorias" [ngClass]="{'active': categoria == categoriaSeleccionada}">{{ categoria }}</button><!-- (click)="seleccionarCategoria(categoria)" -->
                                </div>
                              </div>
                              <div class="col-12" *ngIf="resultados.length > 0">
                                <div class="row border mt-4 m-print-0" [ngClass]="{'d-print-flex': imprimirResultados}">
                                  <ng-container *ngIf="!teamCompetition;else resultsTeamCompetition">
                                    <div class="col-12 text-center align-items-center">
                                      <div style="flex: 1;">
                                        <img src="/assets/img/clubs/{{prueba.siglasClub}}.png?1" style="max-height: 90px;"  onerror="this.style.display='none'" onload="this.style.display='initial'">
                                      </div>
                                      <div style="flex: 1;">
                                        <h3>Class Results</h3>
                                        <p>{{ nombreConcurso }}
                                          <br><b>{{ prueba.numero }}</b> {{ prueba.nombre }}
                                        </p>
                                      </div>
                                      <div style="flex: 1;">
                                        <img [src]="prueba.sponsorImage ? prueba.sponsorImage : prueba.header" style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='initial'">
                                      </div>
                                    </div>
                                    
                                    <div class="col-4 px-3">
                                      <p ><b>Date Printed:</b> {{ fecha | date : 'dd MMMM yyyy' | titlecase }}<p>
                                      <p class="d-none d-print-flex"><b>Designer:</b> {{ displayPersona(designer) }}</p>
                                      <p  *ngIf="prueba.premio > 0"><b>Prize:</b> <span>{{ prueba.premio | currency }}</span></p>
                                      <p class=" d-none d-print-flex"><b>Judge:</b> {{ displayPersona(judge) }}</p>
                                      <p class=" d-none d-print-flex"><b>Judge2:</b> {{ displayPersona(judge2) }}</p>
                                       <!-- Designer -->
                                      <div class="form-group mb-2 d-print-none">
                                        <label for="designer_id" class="d-print-none">Designer:</label>
                                        <input type="text" class="form-control form-control-sm d-print-none" name="designer_id" id="designer_id" [matAutocomplete]="autocompleteDessigner" (keyup)="getPersonas($event, 'designer')" [(ngModel)]="designer">
                                        <mat-autocomplete #autocompleteDessigner="matAutocomplete" [displayWith]="displayPersona.bind(this)">
                                          <mat-option *ngFor="let designer of designers" [value]="designer.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em" (click)="selectDesigner()">
                                            <img [src]="designer.flag" style="height:.9em" />
                                            <span id="dessignerName" style="font-weight: bold;">{{ designer.fullname||'-' }}</span>
                                            <div>
                                              <span id="USEF">
                                                <img src="/assets/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">
                                                {{ designer.usef }}
                                              </span>
                                              <span *ngIf="designer.fei" id="FEI">
                                                <img src="/assets/images/icons/fei.svg" alt="FEI: " style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                                                {{ designer.fei }}
                                              </span>
                                            </div>
                                          </mat-option>
                                        </mat-autocomplete>
                                      </div>
                                       <!-- Judge 1 -->
                                      <div class="form-group mb-2 d-print-none">
                                        <label for="judge_id" class="d-print-none"><b>Judge:</b></label>
                                        <input type="text" class="form-control form-control-sm d-print-none" name="judge_id" id="judge_id" [matAutocomplete]="autocompleteJudge" (keyup)="getPersonas($event, 'judge')" [(ngModel)]="judge">
                                        <mat-autocomplete #autocompleteJudge="matAutocomplete" [displayWith]="displayPersona.bind(this)">
                                          <mat-option *ngFor="let judge of judges" [value]="judge.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em" (click)="selectJudge()">
                                            <img [src]="judge.flag" style="height:.9em" />
                                            <span id="dessignerName" style="font-weight: bold;">{{ judge.fullname||'-' }}</span>
                                            <div>
                                              <span id="USEF">
                                                <img src="/assets/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">
                                                {{ judge.usef }}
                                              </span>
                                              <span *ngIf="judge.fei" id="FEI">
                                                <img src="/assets/images/icons/fei.svg" alt="FEI: " style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                                                {{ judge.fei }}
                                              </span>
                                            </div>
                                          </mat-option>
                                        </mat-autocomplete>
                                      </div>
                                      <!-- Judge 2 -->
                                      <div class="form-group mb-2 d-print-none">
                                        <label for="judge_id_2" class="d-print-none"><b>Judge2:</b></label>
                                        <input type="text" class="form-control form-control-sm d-print-none" name="judge_id_2" id="judge_id_2" [matAutocomplete]="autocompleteJudge2" (keyup)="getPersonas($event, 'judge2')" [(ngModel)]="judge2">
                                        <mat-autocomplete #autocompleteJudge2="matAutocomplete" [displayWith]="displayPersona.bind(this)">
                                          <mat-option *ngFor="let judge of judges2" [value]="judge.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em" (click)="selectJudge2()">
                                            <img [src]="judge.flag" style="height:.9em" />
                                            <span id="dessignerName" style="font-weight: bold;">{{ judge.fullname||'-' }}</span>
                                            <div>
                                              <span id="USEF">
                                                <img src="/assets/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">
                                                {{ judge.usef }}
                                              </span>
                                              <span *ngIf="judge.fei" id="FEI">
                                                <img src="/assets/images/icons/fei.svg" alt="FEI: " style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                                                {{ judge.fei }}
                                              </span>
                                            </div>
                                          </mat-option>
                                        </mat-autocomplete>
                                      </div>
                                    </div>
                                    <div class="col-4 px-3">
                                      <p ><b>Class / Article:</b> {{ prueba.nombreModalidad }}</p>
                                      <p ><b>Level:</b>
                                        <span *ngIf="prueba.ambito == '1'">Regional</span>
                                        <span *ngIf="prueba.ambito == '2'">National</span>
                                        <span *ngIf="prueba.ambito == '3'">CSI</span>
                                        <span *ngIf="prueba.ambito == '4'">Young Horses</span>
                                        <span *ngIf="prueba.ambito == '5'">FEI Ranking</span>
                                        <span *ngIf="prueba.ambito == '6'">CSAm-A</span>
                                      </p>
                                      <p><b>Height:</b> {{ prueba.altura }}</p>
                                      <p *ngIf="prueba.categorias.length > 1"><b>Category: </b> <span *ngIf="prueba.categorias.length > 1">{{ categoriaSeleccionada }}</span></p>
                                    </div>
                                    <div class="col-4 px-3">
                                      <ng-container *ngIf="ipc && mostrarPremios && prize_distribution">
                                        <div class=" btn-group mb-1 d-print-none">
                                          <button type="button" class="btn btn-light"  (click)="aplicarDistribucionPremios()" *ngIf="arePrizesAssigned()" [disabled]="applyingPrizes">Apply prizes</button>
                                        </div>
                                      </ng-container>
                                    </div>
                                        
                                    <div class="col-12">
                                      <div class="table-responsive-sm">
                                        <table class="table table-striped table-fw-widget table-hover small-print table-sm">
                                          <thead>
                                            <tr>
                                              <th>Rk</th>
                                              <th>#</th>
                                              <th *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">FEI</th>
                                              <th>Horse</th>
                                              <th *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">FEI</th>
                                              <th>Rider</th>
                                              <th *ngIf="prueba.ambito == '3' || prueba.ambito == '4' || prueba.ambito == '5' || prueba.ambito == '6'">
                                                </th>
                                              <th *ngIf="!listVerified" style="min-width: 62px;">F | T</th>
                                              <th style="min-width: 62px;" *ngIf="fases == '2'">F | T</th>
                                              <th *ngIf="prueba.modalidad == '8'">Result</th><!-- 239 -->
                                              <th *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">UnApply
                                              </th>
                                              <th *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">Notes</th>
                                              <th *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">Amount</th>
                                              <th *ngIf="mostrarPremios && prueba.premio > 0"
                                                class="text-center d-print-none">Deduction %</th>
                                              <th *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">Payable
                                              </th>
                                              <th *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none"></th>
                                              <th *ngIf="mostrarPremios && prueba.premio > 0">Prize</th>
                                              <th class="d-print-none">Height</th>
                                              <th class="d-print-none">Decl. Sect. Code</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <ng-container *ngFor="let resultado of filtroOverall(); let i = index;">
                                              <tr *ngIf="resultado.status1 != 'DNS'">
                                                <td *ngIf="resultado.status1 != 'DNP' && resultado.status1 != 'DNF'; else cambiarEstatusDnpDnf">
                                                  {{ prueba.overall && categoriaSeleccionada != 'Overall' ? i+1 : resultado.posicion }}
                                                </td>
                                                <ng-template #cambiarEstatusDnpDnf>
                                                  <td>
                                                    <ng-container *ngIf="resultado.status1 == 'DNP'">
                                                      DNP
                                                      <button class="btn btn-info d-print-none" (click)="cambiarEstatus(resultado, 'DNF')">DNF</button>
                                                    </ng-container>
                                                    <ng-container *ngIf="resultado.status1 == 'DNF'">
                                                      DNF
                                                      <button class="btn btn-info d-print-none" (click)="cambiarEstatus(resultado, 'DNP')">DNP</button>
                                                    </ng-container>
                                                  </td>
                                                </ng-template>



                                                <td>{{ resultado.cucarda }}</td>

                                                <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')" style="font-size:.8em">
                                                  {{ resultado.reg_caballo }}
                                                </td>

                                                <td style="font-size: .9em;">{{ resultado.caballo }}</td>

                                                <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')" style="font-size: .8em;">
                                                  {{ resultado.reg_jinete }}
                                                </td>

                                                <td style="font-size: .9em;">
                                                  {{ resultado.nombre_jinete }} {{ resultado.apellido_jinete }}
                                                  <a href="javascript:;" title="Rider Swap" (click)="riderSwapModal(resultado.ibpc)" class="ml-1 d-print-none"><i class="mdi mdi-swap-horizontal"></i></a>
                                                </td>

                                                <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">
                                                  {{ resultado.pais | uppercase }}
                                                </td>

                                                <td *ngIf="prueba.modalidad != '8' && !listVerified" class="results">
                                                  <ng-container *ngIf="resultado.calificado1 == '1' && resultado.status1 == '0'">
                                                    <span *ngIf="resultado.faltasTpo1" style="color: red;"> {{resultado.faltasTpo1}} + </span>
                                                    <span>{{ resultado.faltas1 }} | </span>
                                                    <span>{{resultado.tiempo1 }} </span>
                                                  </ng-container>
                                                  <ng-container *ngIf="resultado.status1 != '0'">
                                                    {{ resultado.status1 }}
                                                    <button class="btn btn-link action-icon" *ngIf="resultado.status1 == 'WD'" (click)="dropEntry(resultado?.cucarda)" [disabled]="loading.get(resultado?.cucarda)">
                                                      <span class="badge badge-danger d-print-none">
                                                        <span class="spinner" *ngIf="loading.get(resultado?.cucarda)"></span>
                                                        Drop
                                                      </span>
                                                    </button>
                                                  </ng-container>
                                                  <small *ngIf="resultado.falls" class="badge badge-danger">
                                                    {{resultado.falls}}
                                                  </small>
                                                </td>

                                                <td *ngIf="fases == '2'" class="results">
                                                  <ng-container *ngIf="resultado.calificado2 == '1' && resultado.status2 == '0'">
                                                    <span *ngIf="resultado.faltasTpo2" style="color: red;"> {{resultado.faltasTpo2}} + </span>
                                                    <span>{{ resultado.faltas2 }} | </span>
                                                    <span>{{resultado.tiempo2 }} </span>
                                                  </ng-container>
                                                  <ng-container *ngIf="resultado.status2 != '0'">
                                                    {{ resultado.status2 }}
                                                  </ng-container>
                                                </td>

                                                <td *ngIf="prueba.modalidad == '8'">
                                                  {{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.totales1 + ' + ' + resultado.tiempo1 : resultado.status1 ) : '' }}
                                                </td>

                                                <td *ngIf="prueba.modalidad == '8'">
                                                  {{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? formatoSuma239(resultado.totales1 + resultado.tiempo1) : resultado.status1 ) : '' }}
                                                </td>

                                                <td *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">
                                                  <div class="custom-control custom-checkbox">
                                                    <input id="check{{i}}" type="checkbox" class="custom-control-input" [(ngModel)]="resultado.pagarAparte" [attr.checked]="resultado.pagarAparte ? 'checked' : null" (change)="guardarPremio(resultado, i)">
                                                    <label for="check{{i}}" class="custom-control-label" style="margin-bottom: 15px !important;"></label>
                                                  </div>
                                                </td>

                                                <td *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">
                                                  <input type="text" class="form-control form-control-sm notesInput" [value]="resultado.referencia" [(ngModel)]="resultado.referencia" (change)="guardarPremio(resultado, i)">
                                                </td>

                                                <td *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">
                                                  <input type="number" class="form-control prizeInput form-control-sm" onclick="this.select();" [(ngModel)]="resultado.monto" (change)="guardarPremio(resultado, i)">
                                                </td>

                                                <td *ngIf="mostrarPremios && prueba.premio > 0" class=" text-center d-print-none">
                                                  <input *ngIf="!resultado.idPremio" type="number" class="form-control form-control-sm prizeInput" onclick="this.select();" [(ngModel)]="resultado.deduccion" (change)="guardarPremio(resultado, i)">
                                                  <span *ngIf="resultado.idPremio" class="form-control form-control-sm prizeInput">{{ resultado.montoDeduccion | currency }}</span>
                                                </td>

                                                <td *ngIf="mostrarPremios && prueba.premio > 0">
                                                  <ng-container *ngIf="resultado.montoAPagar > 0">{{ (resultado.montoAPagar ? resultado.montoAPagar : '0') | currency }}</ng-container>
                                                </td>

                                                <td *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">
                                                  <button type="button" class="btn btn-outline-success" *ngIf="resultado.plantillaPremio" (click)="guardarPremio(resultado, i)">Confirm</button>
                                                </td>
                                                <td *ngIf="mostrarPremios && prueba.premio > 0" class="d-print-none">
                                                  <button type="button" *ngIf="resultado.idPremio" mat-icon-button (click)="eliminarPremio(resultado.idPremio)">
                                                    <mat-icon>clear</mat-icon>
                                                  </button>
                                                </td>
                                                <td class="d-print-none">
                                                  <!-- *ngIf="!resultado?.height;else txtHeight" -->
                                                  <span class="d-none d-print-flex">{{ resultado?.height }}</span>
                                                  <ng-container >
                                                    <input type="text" class="form-control form-control-sm d-print-none" [value]="resultado?.height" (change)="setHeight($any($event).target.value, resultado)" style="max-width: 5rem;">
                                                  </ng-container>
                                                  <!--ng-template #txtHeight>{{ resultado?.height }}</ng-template-->
                                                </td>
                                                <td class="d-print-none">
                                                  <span class="d-none d-print-flex">{{ resultado?.declaredSectionCode }}</span>
                                                  <ng-container >
                                                    <input type="text" class="form-control form-control-sm d-print-none" [value]="resultado?.declaredSectionCode" (change)="setDeclaredSectionCode($any($event).target.value, resultado)" style="max-width: 10rem;">
                                                  </ng-container>
                                                </td>
                                              </tr>
                                            </ng-container>
                                          </tbody>
                                        </table>
                                      </div>

                                    </div>
                                  </ng-container>
                                  <div class="row mt-4 col-12" *ngIf="resultados.length > 0">
                                    <div class="col-6 text-left p-4" *ngIf="totalResultados > 0">Powered by <img src="assets/images/logo.png" style="width: 15px;"> EquestrianDigital</div>
                                    <div class="col-6 text-right p-4" *ngIf="totalResultados > 0"><b>Total entries: </b> {{ totalResultados }}</div>
                                  </div>
                                  <div class="col-12 text-center">
                                    <img [src]="prueba.footer" style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <!-- Imprimir listas por separado de Cali Split -->
                    <ng-container *ngFor="let lista of listasCaliSplit">
                      <div class="container-fluid p-print-0 lista-resultados hide-on-screen" [ngClass]="{'d-print-none': imprimirInscritos}">
                        <div class="row m-print-0 justify-content-center">
                          <div class="col-12 mt-4 m-print-0">
                            <div class="card">
                              <div class="row">
                                <div class="col-12" *ngIf="lista.length > 0">
                                  <div class="row border mt-4 m-print-0" >
                                    <!-- Printable headers -->
                                    <div class="col-12 text-center align-items-center " [ngClass]="{'d-print-flex': imprimirResultados}">
                                      <div style="flex: 1;">
                                        <img src="/assets/img/clubs/{{prueba.siglasClub}}.png?1" style="max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='initial'">
                                      </div>
                                      <div style="flex: 1;">
                                        <h3>Class Results</h3>
                                        <p>{{ nombreConcurso }}
                                          <br><b>{{ prueba.numero }}</b> {{ prueba.nombre }}
                                        </p>
                                      </div>
                                      <div style="flex: 1;">
                                        <img [src]="prueba.sponsorImage ? prueba.sponsorImage : prueba.header" style="max-width: 100%; max-height: 90px;" onerror="this.style.display='none'" onload="this.style.display='initial'">
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <p class="p-3" style="flex: 1;">
                                        <b>Date Printed:</b> {{ fecha | date : 'dd MMMM yyyy' | titlecase }}
                                        <br>
                                        <!--ng-container *ngIf="isValid(designer)"-->
                                        <span class="d-none d-print-flex">
                                          <b>Designer:</b> {{ displayPersona(designer) }}
                                        </span>
                                        <!--/ng-container-->
                                        <ng-container ><!-- *ngIf="!isValid(designer)" -->
                                          <label for="designer_id" class="d-print-none">Designer:</label>
                                          <input type="text" class="form-control form-control-sm d-print-none" name="designer_id" id="designer_id" [matAutocomplete]="autocompleteDessigner" (keyup)="getPersonas($event, 'designer')" [(ngModel)]="designer">
                                          <mat-autocomplete #autocompleteDessigner="matAutocomplete" [displayWith]="displayPersona.bind(this)">
                                            <mat-option *ngFor="let designer of designers" [value]="designer.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em" (click)="selectDesigner()">
                                              <img [src]="designer.flag" style="height:.9em" />
                                              <span id="dessignerName" style="font-weight: bold;">{{ designer.fullname||'-' }}</span>
                                              <div>
                                                <span id="USEF">
                                                  <img src="/assets/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">
                                                  {{ designer.usef }}
                                                </span>
                                                <span *ngIf="designer.fei" id="FEI">
                                                  <img src="/assets/images/icons/fei.svg" alt="FEI: " style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                                                  {{ designer.fei }}
                                                </span>
                                              </div>
                                            </mat-option>
                                          </mat-autocomplete>
                                        </ng-container>
                                        <br *ngIf="prueba.premio > 0">
                                        <b *ngIf="prueba.premio > 0">Prize:</b>
                                        <span *ngIf="prueba.premio > 0">{{ prueba.premio | currency }}</span>
                                        <br>
                                        <!-- Judge1 -->
                                        <ng-container >
                                          <span class="d-none d-print-flex">
                                            <b>Judge:</b> {{ displayPersona(judge) }}
                                          </span>
                                          <ng-container ><!-- *ngIf="!isValid(judge)" -->
                                            <label for="judge_id" class="d-print-none"><b>Judge:</b></label>
                                            <input type="text" class="form-control form-control-sm d-print-none" name="judge_id" id="judge_id" [matAutocomplete]="autocompleteJudge" (keyup)="getPersonas($event, 'judge')" [(ngModel)]="judge">
                                            <mat-autocomplete #autocompleteJudge="matAutocomplete" [displayWith]="displayPersona.bind(this)">
                                              <mat-option *ngFor="let judge of judges" [value]="judge.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em" (click)="selectJudge()">
                                                <img [src]="judge.flag" style="height:.9em" />
                                                <span id="dessignerName" style="font-weight: bold;">{{ judge.fullname||'-' }}</span>
                                                <div>
                                                  <span id="USEF">
                                                    <img src="/assets/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">
                                                    {{ judge.usef }}
                                                  </span>
                                                  <span *ngIf="judge.fei" id="FEI">
                                                    <img src="/assets/images/icons/fei.svg" alt="FEI: " style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                                                    {{ judge.fei }}
                                                  </span>
                                                </div>
                                              </mat-option>
                                            </mat-autocomplete>
                                          </ng-container>
                                        </ng-container>
                                        <br>
                                        <!-- Judge2 -->
                                        <ng-container >
                                          <span class="d-none d-print-flex">
                                            <b>Judge2:</b> {{ displayPersona(judge2) }}
                                          </span>
                                          <label for="judge_id_2" class="d-print-none"><b>Judge2:</b></label>
                                          <input type="text" class="form-control form-control-sm d-print-none" name="judge_id_2" id="judge_id_2" [matAutocomplete]="autocompleteJudge2" (keyup)="getPersonas($event, 'judge2')" [(ngModel)]="judge2">
                                          <mat-autocomplete #autocompleteJudge2="matAutocomplete" [displayWith]="displayPersona.bind(this)">
                                            <mat-option *ngFor="let judge of judges2" [value]="judge.id_persona" class="fila-autocomplete" style="height:5em; line-height: 2em" (click)="selectJudge2()">
                                              <img [src]="judge.flag" style="height:.9em" />
                                              <span id="dessignerName" style="font-weight: bold;">{{ judge.fullname||'-' }}</span>
                                              <div>
                                                <span id="USEF">
                                                  <img src="/assets/images/icons/usef.svg" alt="USEF: " style="height: 1em; padding-right: 5px;">
                                                  {{ judge.usef }}
                                                </span>
                                                <span *ngIf="judge.fei" id="FEI">
                                                  <img src="/assets/images/icons/fei.svg" alt="FEI: " style="height: 1em; padding-left: 10px; padding-bottom: 5px;">
                                                  {{ judge.fei }}
                                                </span>
                                              </div>
                                            </mat-option>
                                          </mat-autocomplete>
                                        </ng-container>
                                      </p>
                                      <p class="p-3" style="flex: 1;">
                                        <b>Class / Article:</b> {{ prueba.nombreModalidad }}
                                        <br><b>Level:</b>
                                        <span *ngIf="prueba.ambito == '1'">Regional</span>
                                        <span *ngIf="prueba.ambito == '2'">National</span>
                                        <span *ngIf="prueba.ambito == '3'">CSI</span>
                                        <span *ngIf="prueba.ambito == '4'">Young Horses</span>
                                        <span *ngIf="prueba.ambito == '5'">FEI Ranking</span>
                                        <span *ngIf="prueba.ambito == '6'">CSAm-A</span>
                                        <br><b>Height:</b> {{ prueba.altura }}
                                        <br *ngIf="prueba.categorias.length > 1">
                                        <b *ngIf="prueba.categorias.length > 1">Category: </b>
                                        <span *ngIf="prueba.categorias.length > 1">{{ categoriaSeleccionada }}</span>
                                      </p>
                                    </div>
                                    <div class="col-12">
                                      <div class="table-responsive-sm">
                                        <table class="table table-striped table-fw-widget table-hover small-print table-sm">
                                          <thead>
                                            <tr>
                                              <th>Rank</th>
                                              <th>#</th>
                                              <th *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">FEI</th>
                                              <th>Horse</th>
                                              <th *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">FEI</th>
                                              <th>Rider</th>
                                              <th *ngIf="prueba.ambito == '3' || prueba.ambito == '4' || prueba.ambito == '5' || prueba.ambito == '6'"> Country</th>
                                              <th style="min-width: 62px;">F | T</th>
                                              <th style="min-width: 62px;" *ngIf="fases == '2'">F | T</th>
                                              <th *ngIf="prueba.modalidad == '8'">Result</th><!-- 239 -->
                                              <th *ngIf="mostrarPremios && prueba.premio > 0">Prize</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <ng-container *ngFor="let resultado of lista; let i = index;">
                                              <tr *ngIf="resultado.status1 != 'DNS'">
                                                <td *ngIf="resultado.status1 != 'DNP'">{{ prueba.overall && categoriaSeleccionada != 'Overall' ? i+1 : resultado.posicion }}</td>
                                                <td *ngIf="resultado.status1 == 'DNP'">DNP</td>


                                                <td>{{ resultado.cucarda }}</td>
                                                <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.reg_caballo }}</td>
                                                <td>{{ resultado.caballo }}</td>
                                                <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.reg_jinete }}</td>
                                                <td>
                                                  {{ resultado.nombre_jinete }} {{ resultado.apellido_jinete }}
                                                  <a href="javascript:;" title="Rider Swap" (click)="riderSwapModal(resultado.ibpc)" class="ml-1"><i class="mdi mdi-swap-horizontal"></i></a>
                                                </td>
                                                <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.pais | uppercase }}</td>
                                                <td *ngIf="prueba.modalidad != '8' && !listVerified">
                                                  {{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.totales1 + ' | ' + resultado.tiempo1 : resultado.status1  ) : '' }}

                                                </td>
                                                <td *ngIf="fases == '2'">
                                                  {{ resultado.calificado2 == '1' ? (resultado.status2 == '0' ? resultado.totales2 + ' | ' + resultado.tiempo2 : resultado.status2 ) : '' }}
                                                </td>
                                                <td *ngIf="prueba.modalidad == '8'">
                                                  {{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.totales1 + ' + ' + resultado.tiempo1 : resultado.status1 ) : '' }}
                                                </td>
                                                <td *ngIf="prueba.modalidad == '8'">
                                                  {{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? formatoSuma239(resultado.totales1 + resultado.tiempo1) : resultado.status1) : '' }}
                                                </td>
                                                <td *ngIf="mostrarPremios && prueba.premio > 0">
                                                  <ng-container *ngIf="resultado.montoAPagar > 0">{{ (resultado.montoAPagar ? resultado.montoAPagar : '0') | currency }}</ng-container>
                                                </td>
                                              </tr>
                                            </ng-container>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <h1 style="color:#FFF;">.</h1>
                                    <div class="row mt-4 col-12">
                                      <div class="col-6 text-left p-4">Powered by <img src="assets/images/logo.png" style="width: 15px;"> EquestrianDigital</div>
                                      <div class="col-6 text-right p-4" *ngIf="totalResultados > 0"><b>Total entries:</b>
                                        {{ totalResultados }}</div>
                                    </div>
                                    <div class="col-12 text-center">
                                      <img [src]="prueba.footer" style="max-width: 100%; max-height: 90px;">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="tab-pane" id="unapplied-prizes">
                    <table class="table table-fw-widget table-sm">
                      <thead>
                        <tr>
                          <th><b>Class</b></th>
                          <th><b>Rank</b></th>
                          <th><b>Amount</b></th>
                          <th><b>Payable</b></th>
                          <th><b>Rider</b></th>
                          <th><b>Notes</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let premio of premiosPagarAparte">
                          <td>{{ premio.numeroPrueba }} - {{ premio.nombre }}</td>
                          <td>{{ premio.premio }}</td>
                          <td>{{ premio.cantidad | currency }}</td>
                          <td>{{ premio.cantidadAPagar | currency }}</td>
                          <td>{{ premio.jinete }}</td>
                          <td>
                            <input type="text" class="form-control"
                              (change)="guardarReferenciaPremio(premio.idPremio, $event)" [value]="premio.referencia">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="modal-cali-split" tabindex="-1" role="dialog" aria-labelledby="modal-borrar-resultados-label" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header p-2">
            <h4 class="modal-title" id="modal-borrar-resultados-label">California Split</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body p-2">
            <form class="form-horizontal m-auto col-md-10 col-xs-12 row">
              <div class="form-group row mb-3 col-8">
                <label for="caliSplitValue" class="col-3 col-form-label">Stagger:</label>
                <div class="col-9">
                  <input type="number" id="caliSplitValue" class="form-control" (keyup)="validateCaliSplitInput($event)">
                </div>
              </div>
              <div class="mb-3 d-flex justify-content-center col-4">
                <button type="button" class="btn btn-success mr-3" (click)="californiaSplit()" *ngIf="visibleElements.caliSplitButton" >Split</button>
                <button type="button" class="btn btn-success mr-3" (click)="saveCaliSplitResults()" *ngIf="visibleElements.caliSplitSave" >Save</button>
              </div>
            </form>
            <div class="table-responsive-sm">
              <table class="table table-striped table-fw-widget table-hover small-print table-sm">
                <thead>
                  <tr>
                    <th>Rk</th>
                    <th>#</th>
                    <th *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">FEI</th>
                    <th>Horse</th>
                    <th *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">FEI</th>
                    <th>Rider</th>
                    <th *ngIf="prueba.ambito == '3' || prueba.ambito == '4' || prueba.ambito == '5' || prueba.ambito == '6'"> Country</th>
                    <ng-container *ngIf="visibleElements.FTColumn">
                      <th style="min-width: 62px;">F | T</th>
                      <th style="min-width: 62px;" *ngIf="fases == '2'">F | T</th>
                      <th *ngIf="prueba.modalidad == '8'">Result</th><!-- 239 -->
                    </ng-container>
                    <ng-container *ngIf="visibleElements.prizeColumn">
                      <th *ngIf="mostrarPremios && prueba.premio > 0">Prize</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let resultado of californiaSplitList; let i = index;">
                    <tr *ngIf="resultado.status1 != 'DNS'">
                      <td *ngIf="resultado.status1 != 'DNP'">{{ prueba.overall &&
                        categoriaSeleccionada != 'Overall' ? i+1 : resultado.rank_split }}</td>
                      <td *ngIf="resultado.status1 == 'DNP'">DNP</td>


                      <td>{{ resultado.cucarda }}</td>
                      <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.reg_caballo }}</td>
                      <td>{{ resultado.caballo }}</td>
                      <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.reg_jinete }}</td>
                      <td>{{ resultado.nombre_jinete }} {{ resultado.apellido_jinete }}</td>
                      <td *ngIf="!(prueba.ambito == '1' || prueba.ambito == '2')">{{ resultado.pais | uppercase }}</td>
                      <ng-container *ngIf="visibleElements.FTColumn">
                        <td *ngIf="prueba.modalidad != '8' && !listVerified">
                          {{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.totales1 + ' | ' + resultado.tiempo1 : resultado.status1  ) : '' }}
                        </td>
                        <td *ngIf="fases == '2'">
                          {{ resultado.calificado2 == '1' ? (resultado.status2 == '0' ? resultado.totales2 + ' | ' + resultado.tiempo2 : resultado.status2 ) : '' }}
                        </td>
                        <td *ngIf="prueba.modalidad == '8'">
                          {{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? resultado.totales1 + ' + ' + resultado.tiempo1 : resultado.status1 ) : '' }}
                        </td>
                        <td *ngIf="prueba.modalidad == '8'">
                          {{ resultado.calificado1 == '1' ? (resultado.status1 == '0' ? formatoSuma239(resultado.totales1 + resultado.tiempo1) : resultado.status1) : '' }}
                        </td>
                      </ng-container>
                      <ng-container *ngIf="visibleElements.prizeColumn">
                        <td *ngIf="mostrarPremios && prueba.premio > 0">
                          <ng-container *ngIf="resultado.montoAPagar > 0">{{ (resultado.montoAPagar ? resultado.montoAPagar : '0') | currency }}</ng-container>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>

    <ng-template #resultsTeamCompetition>
      resultsTeamCompetition
    </ng-template>

    <!-- Modal RiderSwap -->
    <ng-template #modalRiderSwap>
      <div id="riderSwapModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="riderSwapModalLabel" style="display: none;" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="riderSwapModalLabel">Rider swap</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
              <form class="needs-validation" (ngSubmit)="riderSwap()" [formGroup]="formRiderSwap" novalidate>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Rider</label>
                      <input class="form-control" type="text" [matAutocomplete]="autocompleteRiderSwap" (keyup)="getPersonas($event, 'rider')" required formControlName="rider">
                      <mat-autocomplete #autocompleteRiderSwap="matAutocomplete" [displayWith]="displayJinete.bind(this)">
                        <mat-option *ngFor="let jinete of jinetes" [value]="jinete.id_persona" class="fila-autocomplete">
                          {{ (jinete.fullname) ? jinete.fullname : '-' }}
                        </mat-option>
                      </mat-autocomplete>
                      <div class="invalid-feedback">
                        Please choose a rider.
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button type="button" class="btn btn-light mr-3" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save changes</button>
                  </div>
                </div>
              </form>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>
    </ng-template>
