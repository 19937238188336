<div class="row pantalla" *ngIf="pantalla.pantalla == 1">
    <div class="col-12 pantalla1">
        <!-- En pista -->
        <!-- TODO: cambiar *ngIf="pantalla.letreros" para que se esconda con la clase cambiando -->
        <div class="en-pista" [ngClass]="{'esconder': cambiando}" >
          <div class="logo-sr">
            <img src="assets/images/sponsors/srjt-round.png">
          </div>
            <div class="rank" *ngIf="enPistaFB.calificado_uno == '1'">
                <b>Rank {{ enPistaFB.posicion | number}}</b>
            </div>
            <div class="binomio-jinete">
              <span class="posicion">{{ enPistaFB.entrada | number}}</span> <!-- TODO: Agregar dato -->
              <span class="flag">
                <img [src]="(enPistaFB.logo_url)" onerror="this.style.display='none'" onload="this.style.display='unset'">
              </span>
              <span class="jinete"> &nbsp;{{ enPistaFB.jinetePantalla ? enPistaFB.jinetePantalla.substring(0,25) : '' | uppercase}}</span>
              <div class="livestream-sponsor">LIVESTREAM <br> PRESENTED BY</div>
              <div class="livestream-sponsor-logo"><img src="assets/images/sponsors/restylane.png"></div>
            </div>
            <div class="binomio-caballo">
              <div class="cucarda">{{ enPistaFB.cucarda }}</div>
              <span class="caballo">{{ enPistaFB.caballo }}</span>
            </div>
            <div class="avance">
              <img src="assets/images/icons/caballo.png">
              {{ mostrarProgreso() }}
              <div class="progress mb-2 progress-md">
                <div class="progress-bar bg-info" role="progressbar" [style.width]="mostrarProgresoPorcentaje()" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <!--div class="class-sponsor">
                <img [src]="'assets/images/sponsors/biozyme-w.png'" onerror="this.style.opacity='0'" onload="this.style.opacity='1'">
            </div-->
        </div>
        <!--Fin en pista -->
        <!-- Resultados en pista -->
        <div class="resultados-en-pista">
            <div class="faltas" [ngClass]="{'faltas-cambiando': cambiandoFaltas}" *ngIf="enPistaFB.faltas > 0 && enPistaFB.estatus == '0'" >
                <div>{{ enPistaFB.faltas | number }}</div>
              </div>
            <div class="tiempo">
                {{ enPistaFB.estatus == '0' ? (enPistaFB.tiempo > 0 ? (enPistaFB.tiempo | number) : '&nbsp;') : traductorEstatus(enPistaFB.estatus) }}
            </div>

            <!-- Lider -->
            <div class="lider" *ngIf="enPistaFB.calificado_uno != '1'; else diferencia">
              <div class="ttb">
                <b *ngIf="lider.caballo && lider.tiempo; else ta">TTB</b>
                <ng-template #ta>
                  <b>TA</b>
                </ng-template>
              </div>
              <div class="tiempo" style='line-height: 1em; font-size: 1.2em; float: left; width:130px; padding: 5px; text-align: center'>
                {{ (lider.tiempo ? lider.tiempo : tiempoAcordado) | number }}
              </div>
            </div>
            <!-- Fin lider -->

            <!-- Diferencia -->
            <ng-template #diferencia>
              <div class="lider" [style.opacity]="enPistaFB.calificado_uno == '1' ? 1 : 0">
                <div class="ttb" *ngIf="lider.caballo && lider.tiempo">
                  <b>DIFF</b>
                </div>
                <div class="tiempo-lider">
                  {{ lider.tiempo > enPistaFB.tiempo ? '' : lider.tiempo == enPistaFB.tiempo ? 'L' : '+'}}
                  {{ lider.tiempo ? ((enPistaFB.tiempo - lider.tiempo) | number : '1.2-2') : tiempoAcordado | number }}
                </div>
              </div>
            </ng-template>
            <!-- Fin Diferencia -->
        </div>
        <!-- fin resultados en pista -->
        <div class="prueba-fecha">
            <span class="prueba">{{ numeroPrueba}} - {{ nombrePrueba }}</span> <span class="fecha">{{ fecha | date:'MMM / d / yyyy' }}</span>
        </div>
    </div>
</div>
<div class="row justify-content-center pantalla tabla-resultados" *ngIf="pantalla.pantalla == 2">
    <div class="col-9 text-right mt-4">
      <span class="headerR1">{{ nombreConcurso }}</span>
    </div>
    <div class="col-9">
      <div class="headerR2">
        {{ nombrePrueba }}
      </div>
    </div>
    <div class="col-9">
      <div class="headerR3">
        {{ fecha | date:'MMM / d / yyyy' }}
      </div>
    </div>
    <div class="col-11 mt-4">
      <div class="row">
        <ng-container *ngFor="let binomio of resultados">
          <div class="col-5 row-resultado mb-2">
            <div class="row">
              <div class="col-1 posicion">{{ binomio.posicion | number }}</div>
              <div class="col-8 jinete">{{ binomio.jinetePantalla }}</div>
              <div class="col-1 faltas">{{ binomio.faltas | number }}</div>
              <div class="col-2 tiempo">{{ binomio.tiempo | number:'.2-2' }}</div>
              <div class="col-1"></div>
              <div class="col-11 caballo">{{ binomio.caballo }}</div>
            </div>
          </div>
          <div class="w-100"></div>
        </ng-container>
      </div>
    </div>
</div>
<div class="row pantalla" *ngIf="pantalla.pantalla == 3">
  <div class="col-12">
    
    <div class="col-8 offset-2 show-container" >
      <div class="red-spacer"></div>
      <div class="data">
        <div class="red">SRJT</div>
        <div class="white-spacer"></div>
        <div class="show-name">{{concurso.nombre | uppercase}}</div>
        <div class=white-spacer></div>
        <div class="red">
          <div class="date">{{concurso.inicio.toDate() | date:'MMMM yyyy' | uppercase }}</div>
          <div class="logo"><img src="/assets/images/sponsors/srjt-round.png"></div>
        </div>
      </div>
    </div>
    <!--pre>{{ concurso | json }}</pre-->
  </div>
</div>
<div class="row pantalla" *ngIf="pantalla.pantalla == 4">
  <div class="col-12">
    <div class="col-8 offset-2 class-container" >
      <div class="red-spacer"></div>
      <div class="data white">
        <div class="article">{{prueba.nombre_modalidad | uppercase}}</div>
        <div class="white-spacer"></div>
        <div class="class-name">{{prueba.nombre}}</div>
        <div class="entries">{{prueba.participantes}} Entries</div>
        <div class="white-spacer"></div>
        <div class="red">#SPLITROCKJUMPINGTOUR</div>
      </div>
    </div>
    <!--pre>{{ prueba | json }}</pre-->
  </div>
</div>