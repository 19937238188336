import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, NgZone, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CaballoService } from '../services/caballo.service';
import { ConcursoService } from '../services/concurso.service';
import { EntrenadorService } from '../services/entrenador.service';
import { ResponsablesService } from '../services/responsables.service';
import { ConceptoService } from '../services/concepto.service';
import { EntriesService } from '../services/entries.service';
import { PersonaService } from '../services/persona.service';
import { PaisService } from '../services/pais.service';
import { ChaseService } from '../services/chase.service';
import { PruebaService } from '../services/prueba.service';
import { UsefService } from '../services/usef.service';
import { StripeService } from '../services/stripe.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder } from '@angular/forms';
import { FeedOrdersService } from '../services/feed-orders.service';
import { COMMA, ENTER, M, NUMPAD_PERIOD } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProductService } from '../services/product.service';
import { AppService } from '../services/app.service';
import { firstValueFrom } from 'rxjs';
import { USEFLogo } from '../enums/usef-logo';
import { USHJALogo } from '../enums/ushja-logo';
import * as moment from 'moment';
import { LogService } from '../services/log.service';
declare let $: any;

export interface Entries {
  entry: number;
  horse: string;
}

interface Concurso {
  id?: string;
  name?: string;
  id_club?: string;
  apply_prizes?: boolean;
  header?: string;
  header2?: string;
  footer?: string;
  concepto_comision?: string;
  invoice_footer?: string;
  use_stripe?: boolean;
  paymentGateway?: string;
  club?: {
    id_club: string;
    public_key?: string;
    [key: string]: any;
  };
}

@Component({
  selector: 'app-entries',
  templateUrl: './entries.component.html',
  styleUrls: ['./entries.component.css'],
})
export class EntriesComponent implements OnInit, AfterViewInit {
  // Datos sesion
  public idUsuario: number | null;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  // Show info
  public concurso: Concurso;
  // Fecha actual
  public currentDate: moment.Moment;
  // Entries
  public entryForm: UntypedFormGroup;
  public entries: any[];
  public newEntryRiders: any[];
  public entrySeleccionado: string;
  public nuevoEntry: string;
  public editEntryForm: UntypedFormGroup;
  public binomioSeleccionado: number | null;
  //STRIPE
  public stripeFormVisible: boolean = false;
  // Info Entry Seleccionado
  public logs: any[];
  public nsbaId: string;
  public nrhaId: string;
  public searchEntry: string;
  // Variables de control cuenta seleccionada
  public pantalla: number; // 0 Crear entries, 1 Cuenta entry
  public cargando: boolean;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, NUMPAD_PERIOD, 190];
  // Anular Multa
  public ibpcAAnular: string;
  public motivo: UntypedFormControl;
  public mensajeMotivo: string;
  // Parametros de entry
  public actions: any;
  public horse: Horse;
  public owner: Person | null;
  public payee: Person | null;
  public riders: Person[] | null;
  public trainer: Person | null;
  public riders_clases: Map<number, any>;
  public binomios: any[];
  public charges: any[] | null;
  public total_charges: number;
  public orders_summary: any[];
  public total_orders: number;
  public class_fees: any[] | null;
  public total_class_fees: number;
  public balance: number;
  public stripeCustomers: StripeCustomer[];
  public emails: any[];
  public notes: any;
  public editarNotas: boolean;
  public validated: boolean;
  public caballos: any[];
  public caballoParametro: string;
  public horseFormAdd: UntypedFormGroup;
  // Personas
  public personForm: UntypedFormGroup;
  public organization: boolean;
  public tipoPersona: 'rider' | 'payee' | 'trainer' | 'owner' | '';
  public searchPerson: string;
  public panel: Map<string, boolean>;
  public add: Map<string, boolean>;
  // Rider Info
  public jinetes: any[];
  public editRiderTrainerForm: UntypedFormGroup;
  public addRiderTrainerForm: UntypedFormGroup;
  public ibpcSwapSeleccionado: string; // Rider swap
  public formRiderSwap: UntypedFormGroup;
  // Payee Info
  public responsables: any[];
  // Owner Info
  public owners: any[];
  public changeOwnerForm: UntypedFormGroup;
  // public owner: any = { usef: false, ushja: false, suspension: true, reason: '', usefType: '-', ushjaType: '-' };// USEF Owner Validation
  // Trainer Info
  public entrenadores: any[];
  public changeTrainerForm: UntypedFormGroup;
  // Inscripciones
  public pruebas: any[];
  public pruebasRepetidas: any[];
  public inscritosChip: any;
  public extemporaneos: any;
  public scratcheados: any;
  public inscritos: any;
  public totalInscripciones: number;
  public pruebasFiltradas: any[];
  // Cargos
  public formCargo: UntypedFormGroup;
  public cargos: any[];
  public cargosInscripciones: any[];
  public conceptos: any[];
  public nominaciones: any[];
  public totalCargos: number;
  public totalesFeiFee: any[];
  public cargosSeleccionados: any[];
  // Splits
  public splits: any[];
  public total_splits: number;
  // Pagos
  public formPago: UntypedFormGroup;
  public pagos: any[];
  public totalPagos: number;
  public numeroCheque: string;
  public paymentDetails: any;
  // Premios
  public premios: any[];
  public premiosAplicados: any[];
  public premiosChampionshipSeries: any[];
  public totalPremios: number;
  public totalPremiosAplicados: number;
  public totalPremiosChampionshipSeries: number;
  public aplicarPremios: boolean;
  // Feed Orders
  public summaryOrders: any[];
  // Paises
  public paises: any[];
  public queryPais: string;
  // Traspaso
  public formTraspaso: UntypedFormGroup;
  public concursos: any[];
  public entriesTraspaso: any[];
  public entrenadoresTraspaso: any[];
  public motivoAnulacionTraspaso: string;
  public idTransaccionAnulacionTraspaso: string;
  public montoAnulacionTraspaso: string;
  public filterBalanceTransferEntries: string;
  public peoplecards: {};

  public get trainersBalanceTransfer(): any[] {
    return (this.entrenadoresTraspaso || []).filter(
      t => (this.filterBalanceTransferEntries && ((t.nombre || '')
        .toLowerCase()
        .includes(this.filterBalanceTransferEntries.toLowerCase()) || (t.usefId || '')
          .toLowerCase()
          .includes(this.filterBalanceTransferEntries.toLowerCase()))) || !this.filterBalanceTransferEntries);
  }


  // Merch
  public products: any[];
  public selectedProducts: any[];
  public selectedProduct: string;
  public selectedQty: number;
  public selectedTotal: number;
  // Resultados
  public resultados: any[];
  public championshipSeries: boolean;
  // Tarjetas
  public cardForm: UntypedFormGroup;
  public errorTarjeta: string;
  public cards: any[];
  public notasTarjeta: string;
  public retryTrace: string;
  public tarjetaSeleccionada: string;
  public newCard: boolean;
  // Stripe
  public stripeMetadata: {
    entry?: string;
    payee_id?: string;
    trainer_id?: string;
    horse_id?: string;
    show?: string;
    [key: string]: string | undefined;
  } = {};
  public stripeDescription: string = '';
  public totalPayment: number = 0;
  public defaultCardHolder: string = '';
  // 100x/Reining
  public reining: boolean;
  // Pagination
  private _current: number;
  public get current(): number {
    return this._current;
  }
  public set current(value: number) {
    this.getEntriesPaginated(value);
    this._current = value;
  }
  public total: number;
  public totalItems: number;
  public loading: Map<string, boolean>;
  public usefLogo = USEFLogo;
  public ushjaLogo = USHJALogo;
  // Variable de control para mostrar/ocultar elementos
  public show: Map<string, boolean>;


  //@ViewChild('modalBetterpay') modalBetterpay: any;
  @ViewChild('modalContent', { read: ViewContainerRef, static: false }) modalContent: ViewContainerRef;
  modalReferencia: string;
  modalMonto: number;

  showPaymentForm: boolean = false; // Add this property



  constructor(
    private authService: AuthService,
    private caballoService: CaballoService,
    private concursoService: ConcursoService,
    private entrenadorService: EntrenadorService,
    private responsablesService: ResponsablesService,
    private conceptoService: ConceptoService,
    private entriesService: EntriesService,
    private personaService: PersonaService,
    private paisService: PaisService,
    private chaseService: ChaseService,
    private pruebaService: PruebaService,
    private router: Router,
    private route: ActivatedRoute,
    private feedOrdersService: FeedOrdersService,
    private ngZone: NgZone,
    private db: AngularFirestore,
    private productService: ProductService,
    private usefService: UsefService,
    private stripeService: StripeService,
    private appService: AppService,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    private logService: LogService
  ) {
    this.concurso = {
      id: sessionStorage.getItem('concurso') ?? '',
      name: sessionStorage.getItem('nombreConcurso') ?? '',
      id_club: sessionStorage.getItem('club') ?? '',
    };
    this.idUsuario = null;
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.caballos = [];
    this.entrySeleccionado = this.route.snapshot.params.entry || '';
    this.nuevoEntry = '';
    this.currentDate = moment();
    this.totalInscripciones = 0;
    this.totalCargos = 0;
    this.cargos = [];
    this.cargosInscripciones = [];
    this.nominaciones = [];
    this.totalesFeiFee = [];
    this.splits = [];
    this.total_splits = 0;
    this.totalPagos = 0;
    this.pagos = [];
    this.premios = [];
    this.totalPremios = 0;
    this.premiosAplicados = [];
    this.totalPremiosAplicados = 0;
    this.ibpcAAnular = '';
    this.motivo = new UntypedFormControl('', [Validators.required]);
    this.mensajeMotivo = '';
    this.formCargo = new UntypedFormGroup({
      concepto: new UntypedFormControl('', [Validators.required]),
      notas: new UntypedFormControl('', []),
      cantidad: new UntypedFormControl('', [Validators.required]),
      monto: new UntypedFormControl({ value: '', disabled: false }, [Validators.required])
    });
    this.conceptos = [];
    this.formPago = this.fb.group({
      metodo: ['', Validators.required],
      fecha: [''],
      monto: ['', [Validators.required, Validators.min(0.01)]],
      referencia: ['']
    });
    this.caballoParametro = '';
    this.pantalla = 0;
    this.entries = [];
    this.entryForm = new UntypedFormGroup({
      entry: new UntypedFormControl('', [Validators.required]),
      horse: new UntypedFormControl('', [Validators.required]),
      owner: new UntypedFormControl('', [Validators.required]),
      payee: new UntypedFormControl('', [Validators.required]),
      rider: new UntypedFormControl('', []),
      trainer: new UntypedFormControl('', [Validators.required])
    });
    this.responsables = [];
    this.jinetes = [];
    this.entrenadores = [];
    this.actions = {
      showHorseForm: false,
    };
    this.personForm = new UntypedFormGroup({
      id: new UntypedFormControl('', []),
      fei: new UntypedFormControl('', []),
      usef: new UntypedFormControl('', []),
      ec_id: new UntypedFormControl('', []),
      ushja: new UntypedFormControl('', []),
      nsba: new UntypedFormControl('', []),
      nrha: new UntypedFormControl('', []),
      name: new UntypedFormControl('', [Validators.required]),
      lastname: new UntypedFormControl('', []),
      maternalLastname: new UntypedFormControl('', []),
      notas: new UntypedFormControl('', []),
      amateurProStatus: new UntypedFormControl('', []),
      w9: new UntypedFormControl('', []),
      email: new UntypedFormControl('', []),
      age: new UntypedFormControl('', []),
      nationality: new UntypedFormControl('', []),
      dateOfBirth: new UntypedFormControl('', []),
      telephone: new UntypedFormControl('', []),
      cellPhone: new UntypedFormControl('', []),
      gender: new UntypedFormControl('', []),
      usefAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      taxAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      mailAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      taxId: new UntypedFormControl('', []),
      taxName: new UntypedFormControl('', []),
      suspensionStatus: new UntypedFormControl('', []),
      amateur: new UntypedFormControl(false, []),
      stripeCustomerId: new UntypedFormControl('', []),
    });
    this.paises = [];
    this.queryPais = '';
    this.cardForm = new UntypedFormGroup({
      number: new UntypedFormControl('', [Validators.required, Validators.minLength(18)/*, Validators.maxLength(19)*/]),
      date: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
      name: new UntypedFormControl('', []),
      save: new UntypedFormControl(false, [])
    });
    this.errorTarjeta = '';
    this.editEntryForm = new UntypedFormGroup({
      horse: new UntypedFormControl('', [Validators.required]),
      payee: new UntypedFormControl('', [Validators.required])
    });
    this.editRiderTrainerForm = new UntypedFormGroup({
      rider: new UntypedFormControl('', [Validators.required]),
      trainer: new UntypedFormControl('', [Validators.required])
    });
    this.changeOwnerForm = new UntypedFormGroup({
      owner: new UntypedFormControl('', [Validators.required])
    });
    this.changeTrainerForm = new UntypedFormGroup({
      trainer: new UntypedFormControl('', [Validators.required])
    });
    this.addRiderTrainerForm = new UntypedFormGroup({
      rider: new UntypedFormControl('', [Validators.required]),
      trainer: new UntypedFormControl('', [Validators.required])
    });
    this.binomioSeleccionado = null;
    this.pruebas = [];
    this.extemporaneos = {};
    this.scratcheados = {};
    this.inscritos = {};
    this.organization = false;
    this.ibpcSwapSeleccionado = '';
    this.formRiderSwap = new UntypedFormGroup({
      rider: new UntypedFormControl('', [Validators.required])
    });
    this.summaryOrders = [];
    this.logs = [];
    this.owners = [];
    this.formTraspaso = new UntypedFormGroup({
      concurso: new UntypedFormControl('', [Validators.required]),
      entry: new UntypedFormControl('', []),
      entrenador: new UntypedFormControl('', []),
      fecha: new UntypedFormControl(this.currentDate.format('YYYY-MM-DD'), [Validators.required]),
      notas: new UntypedFormControl('', []),
      monto: new UntypedFormControl('', [Validators.required]),
      deEntry: new UntypedFormControl('', [Validators.required]),
      deConcurso: new UntypedFormControl(this.concurso?.id, [Validators.required]),
      deEntrenador: new UntypedFormControl('', []),
      idUsuario: new UntypedFormControl(this.idUsuario, [Validators.required])
    });
    this.concursos = [];
    this.entriesTraspaso = [];
    this.entrenadoresTraspaso = [];
    this.pruebasRepetidas = [];
    this.inscritosChip = [];
    this.cards = [];
    this.tarjetaSeleccionada = '';
    this.validated = false;
    this.notes = '';
    this.motivoAnulacionTraspaso = '';
    this.idTransaccionAnulacionTraspaso = '';
    this.montoAnulacionTraspaso = '';
    this.selectedProducts = [];
    this.selectedProduct = '';
    this.selectedQty = 1;
    this.selectedTotal = 0;
    this.pruebasFiltradas = [];
    this.nsbaId = '';
    this.tipoPersona = '';
    this.cargosSeleccionados = [];
    this.notasTarjeta = '';
    this.retryTrace = '';
    this.searchEntry = '';
    this.searchPerson = '';
    this.newEntryRiders = [];
    this.numeroCheque = '';
    this.newCard = false;
    this.stripeCustomers = [];
    this.editarNotas = false;
    this.resultados = [];
    this.championshipSeries = false;
    this.premiosChampionshipSeries = [];
    this.totalPremiosChampionshipSeries = 0;


    this.emails = [];
    this.reining = sessionStorage.getItem('reining') == '1';
    this.filterBalanceTransferEntries = '';
    this.paymentDetails = {};
    this.stripeMetadata = {};
    this.stripeDescription = '';
    this.totalPayment = 0;
    this.defaultCardHolder = '';
    this.total = 0;
    this.totalItems = 0;
    // Trigger pagination
    this.current = 1;
    this.horse = {};
    this.owner = null;
    this.payee = null;
    this.riders = null;
    this.trainer = null;
    this.class_fees = [];
    this.total_class_fees = 0;
    this.charges = [];
    this.total_charges = 0;
    this.orders_summary = [];
    this.total_orders = 0;
    this.splits = [];
    this.total_splits = 0;
    this.class_fees = [];
    this.total_class_fees = 0;
    this.balance = 0;
    this.panel = new Map<string, boolean>([
      ['horse', false],
      ['rider', false],
      ['payee', false],
      ['owner', false],
      ['trainer', false]
    ]);
    this.add = new Map<string, boolean>([
      ['horse', false],
      ['rider', false],
      ['payee', false],
      ['owner', false],
      ['trainer', false]
    ]);
    this.loading = new Map<string, boolean>([
      ['show', false],
      ['info', false],
      ['riders_clases', false],
      ['class_fees', false],
      ['charges', false],
      ['orders_summary', false],
      ['stripe_customers', false],
      ['splits', false],
      ['payments', false],
      ['prizes', false],
      ['results', false],
    ]);
    this.show = new Map<string, boolean>([
      ['add-card-form', false],
      ['paysafe-form', false],
      ['stripe-form', false],
    ]);

    this.logs = [];
    this.peoplecards = {}
  }

  @ViewChild('classList') classList: MatChipList;


  async ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else if (!this.authService.validarConcurso()) {
      this.router.navigate(['']);
      return;
    }
    this.token = this.authService.getAuthorizationToken();
    this.sessionData = this.authService.getSessionData(this.token);
    this.idUsuario = this.sessionData['idUsuario'];
    this.privilegios = this.sessionData.privilegios;
    // TODO: Cambiar por typeahead
    this.personForm.get('nationality').valueChanges.subscribe(data => {
      this.queryPais = data;
    });
    // Async
    this.getConcurso();
    // Async
    this.getConcursos();
    // Async
    this.getPaises();
    // Async
    this.getConceptos();
    // Async
    this.getPruebas();
    // Async
    this.getProducts();
  }

  ngAfterViewInit() {
    // Shortcuts
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key === 'l') {
        e.preventDefault();
        this.showAddEntry();
      } else if (e.key === 'PageUp' && this.entrySeleccionado) {
        e.preventDefault();
        const index = this.entries.findIndex(e => e.entry == this.entrySeleccionado);
        if (this.entries[index - 1]) {
          this.ngZone.run(() => {
            this.selectEntry(this.entries[index - 1].entry);
          });
        }
      } else if (e.key === 'PageDown' && this.entrySeleccionado) {
        e.preventDefault();
        const index = this.entries.findIndex(e => e.entry == this.entrySeleccionado);
        if (this.entries[index + 1]) {
          this.ngZone.run(() => {
            this.selectEntry(this.entries[index + 1].entry);
          });
        }
      }
    }, false);
    // Listeners
    $('#addDrops').on('hidden.bs.modal', () => {
      $('#loader').show();
      Promise.all([this.getRidersClasses(), this.getCharges(), this.getEntryInfo()]).then(v => {
        // Limpiar formularios
        this.formCargo.reset();
        this.formCargo.setValue({
          concepto: '',
          notas: '',
          cantidad: '',
          monto: ''
        });
        this.formPago.reset();
        this.formPago.setValue({
          metodo: '',
          referencia: '',
          fecha: this.currentDate.format('YYYY-MM-DD'),
          monto: ''
        });
        $('#modal-productos').modal('hide');
        $('#loader').hide();
      });
    });
    $('#cardPaymentStripeModal').on('hide.bs.modal', (e) => {
      this.show.set('stripe-form', false);
    });
    $('#modal-paysafe').on('hide.bs.modal', (e) => {
      this.show.set('paysafe-form', false);
    });
    // Al cargar componente preseleccionar el campo de entry en automatico
    $('#input-entry').focus();
    // Plugin Typeahead
    $('#entryTraspaso').typeahead({
      hint: !0,
      highlight: !0,
      minLength: 1
    },
      {
        name: 'entryTraspaso',
        display: value => `${value.entry} - ${value.horse}`,
        templates: {
          notFound: '<div class="empty-message">No matches.</div>',
          suggestion: data => {
            return `<div>${data.entry} - ${data.horse}</div>`;
          }
        },
        source: (this.entriesTraspaso,
          (e, a) => {
            const substrRegex = new RegExp(e, 'i');
            const t = this.entriesTraspaso.filter(tm => substrRegex.test(tm.entry) || substrRegex.test(tm.horse));
            a(t.map(tm => tm));
          }
        )
      });
    $('#entryTraspaso').bind('typeahead:select', (ev, suggestion) => {
      this.formTraspaso.get('entry').setValue(suggestion.entry);
    });
    // Plugin Select 2
    $('#select-tarjeta').on('select2:select', (e) => {
      this.tarjetaSeleccionada = e.target.value || '';
    });
  }

  public async getConcurso() {
    this.loading.set('show', true);
    const response = await firstValueFrom(
      this.concursoService.getConcursoNest(this.concurso?.id)
    ).then(response => ({
      concurso: response.data.concurso,
      error: response.message ?? false
    })).catch(e => ({
      concurso: null,
      error: e.message ?? true
    }));

    if (response.error) {
      $.NotificationApp.send('Error', response.error, 'bottom-right', '#fa5c7c', 'error');
      this.loading.set('show', false);
      return;
    }

    const { concurso } = response;


    // Update concurso properties
    this.concurso = {
      ...this.concurso,
      apply_prizes: concurso.aplicar_premios ?? false,
      header: concurso.img_encabezado ?? '',
      header2: concurso.img_encabezado_2 ?? '',
      footer: concurso.img_footer ?? '',
      concepto_comision: concurso.idConceptoComision ?? '',
      invoice_footer: concurso.invoice_footer ?? '',
      use_stripe: !!(concurso.club?.public_key ?? ''),
      paymentGateway: concurso.payment_gateway ?? '',
      club: concurso.club // Make sure club data is included
    };

    this.aplicarPremios = concurso.aplicar_premios ?? false;

    this.loading.set('show', false);
  }

  public async getConcursos(): Promise<void> {
    const { error, consursos } = await firstValueFrom(
      this.concursoService.getConcursos(this.concurso?.id)
    ).then(r => ({ ...r, error: r.message ?? false })).catch(e => ({ error: e.message ?? true }));
    if (error) {
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }
    this.concursos = consursos;
  }

  public async getPaises() {
    const { error, paises } = await firstValueFrom(
      this.paisService.getPaises()
    ).then(r => ({ ...r, error: r.message ?? false })).catch(e => ({ error: e.message ?? true }));
    if (error) {
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }
    this.paises = paises;
  }

  private async getConceptos() {
    const { conceptos, error } = await firstValueFrom(
      this.conceptoService.getConceptos(this.concurso?.id)
    ).then(r => ({ ...r, error: r.message ?? false })).catch(e => ({ error: e.message ?? true }));
    if (error) {
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }
    this.conceptos = conceptos.filter(c => !c.feed);
  }

  public async getPruebas() {
    const { error, pruebas } = await firstValueFrom(
      this.pruebaService.getPruebas(this.concurso?.id)
    ).then(r => ({ pruebas: r ?? [], error: r.message ?? false })).catch(e => ({ error: e.message ?? true, pruebas: [] }));
    if (error) {
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }
    this.pruebas = pruebas;
  }

  private async getProducts() {
    const { products, error } = await firstValueFrom(
      this.productService.getProducts()
    ).then(r => ({ ...r, error: r.message ?? false })).catch(e => ({ error: e.message ?? true }));
    if (error) {
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }
    this.products = products;
  }

  public applyFilter(filterValue: string) {
    if (filterValue.length > 2) {
      return firstValueFrom(this.entriesService.searchEntries(this.concurso?.id, filterValue)).then(
        response => {
          if (!response.error) {
            this.entries = response.data;
            this.total = Math.ceil(this.entries.length / 10); // 10: Items per page
            this.totalItems = this.entries.length;
            $('#loader').hide();
          } else {
            $('#loader').hide();
            $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
          return response;
        },
        error => {
          $('#loader').hide();
          $.NotificationApp.send('Error', 'It has not been possible to query the entry list.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
          return error;
        }
      );
    } else if (filterValue == '') {
      this.current = 1;
    }
  }

  public async selectEntry(entry): Promise<void> {
    this.pantalla = 1;
    this.entrySeleccionado = entry;
    this.pruebasRepetidas = [];
    this.cargosSeleccionados = [];
    this.cards = [];
    this.emails = [];

    this.tipoPersona = '';
    this.searchEntry = '';
    this.searchPerson = '';
    this.editarNotas = false;

    this.horse = {};
    this.owner = null;
    this.riders = null;
    this.trainer = null;
    this.payee = null;
    this.riders_clases = new Map<number, any>();
    this.binomios = [];
    this.balance = 0;

    // Limpiar formularios
    this.formCargo.reset({
      concepto: '',
      notas: '',
      cantidad: '',
      monto: ''
    });
    this.formPago.reset({
      metodo: '',
      referencia: '',
      fecha: this.currentDate.format('YYYY-MM-DD'),
      monto: ''
    });

    $('#modal-productos').modal('hide');

    this.loading = new Map<string, boolean>([
      ['show', false],
      ['info', true],
      ['riders_clases', true],
      ['class_fees', true],
      ['charges', true],
      ['orders_summary', true],
      ['stripe_customers', true],
      ['splits', true],
      ['payments', true],
      ['prizes', true],
      ['results', true],
    ]);

    Promise.all([
      // Async php -> Async Nestjs getEntryInfo -> Async Nestjs getClassFees
      //this.recalcularDivisiones(),
      // Async Nestjs
      this.getEntryInfo(),
      // Async Nestjs
      this.getClassFees(),
      // Async Nestjs
      this.getStripeCustomers(),
      // Async Nestjs
      this.getRidersClasses(),
      // Async Nestjs
      this.getCharges(),
      // Async Nestjs
      this.getOrdersSummary(),
      // Async Nestjs
      this.getSplits(),
      // TODO: Async Nestjs
      this.getPayments(),
      // TODO: Async Nestjs
      this.getPrizes()
    ]).then(v => {
      setTimeout(() => {
        $(() => {
          $('[data-toggle="tooltip"]').tooltip();
        });
      });
      // Handle invoice finish loading
      $('#loader').hide();
    });
  }

  /**
   *
   * Recalcular divisiones e inscripciones al cargar la cuenta
   */
  public async recalcularDivisiones(): Promise<void> {
    const { error } = await firstValueFrom(
      this.entriesService.recalcularDivisiones(this.entrySeleccionado, this.concurso?.id, this.idUsuario)
    ).then(r => ({ error: r?.message ?? false }));
    if (error) {
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }

    return;
  }

  public async getEntryInfo(): Promise<any> {
    this.loading.set('info', true);
    const { data, error } = await this.entriesService.getInfo(`${this.concurso?.id}`, [this.entrySeleccionado]);
    if (error) {
      this.loading.set('info', false);
      return;
    }
    const [info] = data;
    this.horse = info?.horse ?? {};
    this.owner = info.owner ?? {};
    this.payee = info.payee ?? {};
    this.riders = info.riders ?? [];
    this.riders?.forEach(rider => {
      if (!this.riders_clases.has(rider.id_binomio)) {
        this.riders_clases.set(rider.id_binomio, rider);
      }
    });
    this.binomios = [...this.riders_clases.values()];
    this.trainer = info.trainer ?? {};
    this.balance = info.balance ?? 0;
    // Actualizar tooltips de suspension
    $(function () {
      $('[data-toggle="tooltip"]').tooltip('dispose');
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.validated = info.validated ?? false;
    this.notes = info.notes ?? '';
    this.editarNotas = !this.notes;
    // Si esta marcada como validada desactivar formularios
    this.validated ? this.formCargo.disable() : this.formCargo.enable();
    // Consultar Stripe customers con los emails guardados en mysql
    this.loading.set('info', false);
  }

  public async getStripeCustomers(): Promise<void> {
    if (!this.concurso?.use_stripe) {
      this.loading.set('stripe_customers', false);
    }
    this.loading.set('stripe_customers', true);
    const { data, error } = await this.entriesService.getStripeCustomers(this.concurso?.id, this.entrySeleccionado);
    if (error) {
      this.loading.set('stripe_customers', false);
      return;
    }
    const { customers, emails } = data;
    this.stripeCustomers = customers ?? [];
    this.emails = emails ?? [];
    this.loading.set('stripe_customers', false);
  }

  public async getRidersClasses(): Promise<void> {
    this.loading.set('riders_clases', true);
    const { data, error } = await this.entriesService.getRidersClasses(this.concurso?.id, [this.entrySeleccionado]);
    if (error) {
      this.loading.set('riders_clases', false);
      console.log(error);
      return;
    }
    const [{ riders }] = data;
    console.log('riders', riders);
    for (const rider of (riders ?? [])) {
      this.riders_clases.set(rider.id_binomio, rider);
    }
    this.binomios = [...this.riders_clases.values()];
    this.loading.set('riders_clases', false);
    return;
  }

  public async getClassFees(): Promise<void> {
    this.loading.set('class_fees', true);
    const { data, error } = await this.entriesService.getClassFees(this.concurso?.id, [this.entrySeleccionado]);
    if (error) {
      this.loading.set('class_fees', false);
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }
    const [{ fees, total }] = data;
    this.class_fees = fees ?? [];
    this.total_class_fees = total ?? 0;
    this.loading.set('class_fees', false);
    return;
  }

  public async getCharges(): Promise<void> {
    this.loading.set('charges', true);
    const { data, error } = await this.entriesService.getCharges(this.concurso!.id, [this.entrySeleccionado]);
    if (error) {
      this.loading.set('charges', false);
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }
    const [{ charges, total }] = data;
    this.charges = charges ?? [];
    this.total_charges = total ?? 0;
    setTimeout(() => {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
      });
    });
    this.loading.set('charges', false);
    return;
  }

  public async getOrdersSummary(): Promise<void> {
    this.loading.set('orders_summary', true);
    const { data, error } = await this.entriesService.getOrdersSummary(this.concurso?.id, [this.entrySeleccionado]);
    if (error) {
      console.log(error);
      this.loading.set('orders_summary', false);
      return;
    }
    const [{ charges, total }] = data;

    this.orders_summary = charges ?? [];
    this.total_orders = total ?? 0;
    this.loading.set('orders_summary', false);
    return;
  }

  public async getSplits(): Promise<void> {
    this.loading.set('splits', true);
    const { data, error } = await this.entriesService.getSplits(this.concurso?.id, [this.entrySeleccionado]);
    if (error) {
      this.loading.set('splits', false);
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }
    const [{ splits, total }] = data;
    this.splits = splits ?? [];
    this.total_splits = total ?? 0;
    this.loading.set('splits', false);
    return;
  }

  public async getPayments(): Promise<void> {
    this.loading.set('payments', true);
    const { totalPagos, pagos, error } = await firstValueFrom(
      this.entriesService.getPayments(this.entrySeleccionado, this.concurso?.id)
    ).then(r => ({ ...r, error: r?.message ?? false })).catch(r => ({ error: r.message ?? '' }));
    if (error) {
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      this.loading.set('payments', false);
      return;
    }
    this.totalPagos = totalPagos ?? 0;
    this.pagos = (pagos ?? []).filter(p => !(p.referencia.includes('Check number: ') && p.referencia.includes(', amount: $') && p.monto == '0.00'));
    this.changeDetector.detectChanges();
    let index = 0;
    // Payment details
    try {
      for (const pago of this.pagos) {
        if (pago.metodo === 'Tc') {
          const pi_ = this.cleanString(pago.referencia);
          if (!pi_) { continue; }
          const paymentDetails = await this.stripeService.getPaymentDetails(pi_, this.concurso?.id).toPromise();
          if (paymentDetails.error) {
            console.log(paymentDetails.message);
            continue;
          } else {
            this.pagos[index].paymentDetails = paymentDetails;
            this.pagos[index].referencia = `Payment ID: ${pi_}`;
            console.log(this.pagos[index]);
          }
        }
        index++;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getPrizes(): Promise<void> {
    this.loading.set('prizes', true);
    this.loading.set('results', true);
    const {
      resultados,
      premios,
      totalPremios,
      premiosAplicados,
      totalPremiosAplicados,
      championshipSeries,
      premiosChampionshipSeries,
      totalPremiosChampionshipSeries,
      error
    } = await firstValueFrom(
      this.entriesService.getPrizes(this.entrySeleccionado,
        this.concurso?.id))
      .then(r => ({ ...r, error: r?.message ?? false }))
      .catch(r => ({ error: r.message ?? '' }));
    if (error) {
      this.loading.set('prizes', false);
      this.loading.set('results', false);
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      console.log(error);
      return;
    }

    this.resultados = resultados;
    this.premios = premios;
    this.totalPremios = totalPremios;
    this.premiosAplicados = premiosAplicados;
    this.totalPremiosAplicados = totalPremiosAplicados;
    this.championshipSeries = championshipSeries;
    this.premiosChampionshipSeries = premiosChampionshipSeries;
    this.totalPremiosChampionshipSeries = totalPremiosChampionshipSeries;
    this.loading.set('prizes', false);
    this.loading.set('results', false);
    return;
  }

  public showChargeDetails(charge) {
    charge.show = !charge?.show;
    setTimeout(() => {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
      });
    });
  }

  public async searchEntryTraspaso(searchString: any) {
    this.entriesTraspaso = await firstValueFrom(
      this.entriesService.searchEntries(this.formTraspaso.value.concurso || this.concurso?.id, searchString || '')
    ).then(r => r.data || []);
    const theVal = $('#entryTraspaso').val();
    $('#entryTraspaso').typeahead('val', '');
    $('#entryTraspaso').focus().typeahead('val', theVal).focus();
    // $("#entryTraspaso").trigger("input");
    return this.entriesTraspaso;
  }

  public valorAbsoluto(num: number): number {
    return Math.abs(num);
  }

  public modalAnularMulta(ibpc) {
    this.ibpcAAnular = ibpc;
    // Limpiar modal
    this.motivo.reset();
    this.motivo.setValue('');
    $('#modal-anular-multa').modal('show');
  }

  public async anularMulta() {
    if (this.motivo.valid) {
      const motivo = this.motivo.value;
      $('#loader').show(); // Mostrar loader antes de la solicitud
      try {
        const response = await firstValueFrom(
          this.responsablesService.anularMulta(this.ibpcAAnular, motivo, this.idUsuario, this.concurso?.id)
        );
        if (!response.error) {
          this.selectEntry(this.entrySeleccionado);
          $.NotificationApp.send('Saved', 'The penalty fee has been canceled.', 'bottom-right', '#06d5a1', 'success');
          $('#modal-anular-multa').modal('hide');
        } else {
          this.handleError(response.message, 'It has not been possible to cancel the penalty fee..');
        }
      } catch (error) {
        this.handleError(error, 'It has not been possible to cancel the penalty fee.');
      }
    }
  }

  public mostrarMotivo(motivo) {
    this.mensajeMotivo = motivo;
    $('#motivoAnularMulta').modal('show');
  }

  public calcularCargo() {
    if (this.formCargo.value.concepto && this.formCargo.value.cantidad) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.formCargo.value.concepto;
      });
      this.formCargo.get('monto').setValue(parseFloat(this.conceptos[index].monto) * this.formCargo.value.cantidad);
    } else if (this.formCargo.value.concepto && !this.formCargo.value.cantidad) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.formCargo.value.concepto;
      });
      this.formCargo.get('cantidad').setValue(1);
      this.formCargo.get('monto').setValue(parseFloat(this.conceptos[index].monto));
    }
  }

  public async agregarCargo() {
    this.formCargo.get('concepto')!.markAsTouched();
    this.formCargo.get('cantidad')!.markAsTouched();
    this.formCargo.get('monto')!.markAsTouched();
    if (this.formCargo.valid) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.formCargo.value.concepto;
      });
      console.log('index', this.conceptos[index]);
      const datos = {
        id_concepto: this.formCargo.get('concepto')!.value,
        quantity: this.formCargo.get('cantidad')!.value,
        monto_pagar: this.formCargo.get('monto')!.value,
        referencia: this.formCargo.get('notas')!.value,
        entry: this.entrySeleccionado,
        recibido_id: this.idUsuario,
        id_concurso: this.concurso?.id,
        nombre: this.conceptos[index].nombre
      };
      try {
        const response = await firstValueFrom(this.entriesService.addChargeNest(datos));
        if (!response.error) {
          this.selectEntry(this.entrySeleccionado);
          $.NotificationApp.send('Saved', response.message, 'bottom-right', '#06d5a1', 'success');
        } else {
          this.handleError(response.message, 'Error adding charge.');
        }
      } catch (error) {
        this.handleError(error, 'It has not been possible to add the charge.');
      }
    }
  }


  handlePayment() {
    console.log('handlePayment called');
    // Check the selected payment method and call the appropriate modal
    if (this.formPago.get('metodo').value === 'Tc') {
      console.log(this.concurso)
      console.log(this.concurso.paymentGateway)
      if (this.concurso.paymentGateway === 'betterpay') {
        // alert("ENTRY\n" + JSON.stringify(this.entrySeleccionado))
        // alert("HORSE\n" + JSON.stringify(this.horse.id))
        // alert("OWNER\n" + JSON.stringify(this.owner.id))
        // alert("RIDERS\n" + JSON.stringify(this.riders.map(r => r.id)))
        // alert("TRAINER\n" + JSON.stringify(this.trainer.id))

        this.peoplecards = { "entry": this.entrySeleccionado, "horse": this.horse.id, "owner": this.owner.id, "riders": this.riders.map(r => r.id), "trainer": this.trainer.id }
        this.openBetterpayModal();
      } else if (this.concurso.paymentGateway === 'stripe') {
        console.log('Stripe module');
        this.concurso.use_stripe = true;
        this.openStripeModal();
      } else {
        this.agregarPago();
      }
    } else if (this.formPago.get('metodo').value === 'Cheque') {
      this.openBetterpayModal();
    } else {
      console.log('Adding payment');
      this.addPayment();
    }
  }


  public async openBetterpayModal() {
    console.log('Betterpay module');
    this.formPago.get('metodo').markAsTouched();
    this.formPago.get('fecha').markAsTouched();
    this.formPago.get('monto').markAsTouched();
    if (!this.formPago.get('metodo').value
      || this.formPago.get('monto').value == '') {
      console.log('Metodo: ', this.formPago.get('metodo').value);
      console.log('Monto: ', this.formPago.get('monto').value);
      $.NotificationApp.send('Error', 'Please select a payment method and enter an amount.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    if (this.formPago.valid) {
      console.log('Form is valid');
      // Validar si metodo de pago es tarjeta
      // TODO: Validar si vamos a usar stripe o betterpay

      if (this.formPago.get('metodo').value === 'Tc') {

        this.showPaymentForm = true;
        return;
      } else {
        // Chase bank
        // this.cardForm.reset();
        // this.cardForm.setValue({
        //   number: '',
        //   date: '',
        //   name: '',
        //   save: false
        // });
        // this.errorTarjeta = '';
        // $('#select-tarjeta').val('').trigger('change');
        // this.tarjetaSeleccionada = '';
        // this.notasTarjeta = this.formPago.get('referencia').value;
        // this.retryTrace = this.makeid(16);
        // $('#cardPaymentModal').modal('show');
        // return;
      }

      if (this.formPago.get('metodo').value === 'Cheque') {
        this.numeroCheque = '';
        $('#numero-cheque').val('');
        $('#checkPaymentModal').modal('show');
        setTimeout(() => {
          $('#numero-cheque').focus();
        }, 400);
        return;
      }
      this.addPayment();
    }
    console.log('Form is not valid');
    console.log(this.formPago.errors);

  }

  public async openStripeModal() {
    this.formPago.get('metodo').markAsTouched();
    this.formPago.get('fecha').markAsTouched();
    this.formPago.get('monto').markAsTouched();

    if (this.formPago.valid) {
      // Validate show and Stripe configuration
      if (!this.concurso?.id || !this.concurso?.use_stripe) {
        $.NotificationApp.send(
          "Error",
          "Stripe is not properly configured for this show",
          'bottom-right',
          '#fa5c7c',
          'error'
        );
        return;
      }

      const amount = parseFloat(this.formPago.get('monto').value || '0');

      // Set all required values
      this.totalPayment = amount;
      this.stripeMetadata = {
        entry: this.entrySeleccionado?.toString(),
        payee_id: this.payee?.id?.toString(),
        trainer_id: this.trainer?.id?.toString(),
        horse_id: this.horse?.id?.toString(),
        show: this.concurso.id.toString()
      };
      this.stripeDescription = `Show: ${this.concurso.name}, Entry: ${this.entrySeleccionado}`;
      this.defaultCardHolder = this.payee ?
        `${this.payee.name || ''} ${this.payee.lastname || ''}`.trim() : '';

      // Show modal
      this.stripeFormVisible = true;
      console.log('stripeFormVisible set to true in line 1319');
      $('#cardPaymentStripeModal').modal('show');
    }
  }

  public closePaymentForm() {
    console.log('closePaymentForm called');
    this.showPaymentForm = false;
    console.log('showPaymentForm set to: ', this.showPaymentForm);
    this.formPago.reset();
    this.changeDetector.detectChanges();
    this.getPayments();
    this.getCharges
  }

  public async agregarPago() {
    console.log('agregarPago called');
    if (this.formPago.valid) {
      if (this.formPago.get('metodo').value === 'Tc' && this.concurso?.use_stripe) {
        const total = parseFloat(this.formPago.value.monto || '0');

        // Set values before opening modal
        this.totalPayment = total;
        this.stripeMetadata = {
          entry: this.entrySeleccionado,
          user: this.idUsuario?.toString(),
          show: this.concurso?.id?.toString()
        };
        this.stripeDescription = `Show: ${this.concurso?.name}, Entry: ${this.entrySeleccionado}`;
        this.defaultCardHolder = this.payee?.name ?
          `${this.payee.name} ${this.payee.lastname}`.trim() : '';

        // Show modal
        this.stripeFormVisible = true;
        $('#cardPaymentStripeModal').modal('show');
      }
    }
  }


  public async addPayment(stripe?: { pi: string, comision: number }) {
    if (this.formPago.get('metodo')!.value === 'Cheque') {
      if (this.numeroCheque !== '') {
        const referencia = this.formPago.get('referencia')!.value ?
          this.formPago.get('referencia')!.value + '. Check number: ' + this.numeroCheque :
          'Check number: ' + this.numeroCheque;
        this.formPago.get('referencia')!.setValue(referencia);
      } else {
        $('#numero-cheque').addClass('no-valido');
        return;
      }
    }

    // Monto pago
    let amount = parseFloat(this.formPago.value.monto || '0');
    // Referencia
    let reference = this.formPago.value.referencia || '';
    let comisiones: any[] = [];

    // Si es pago con tarjeta se agrega la comision al total y se crea transaccion de comision
    if (this.formPago.value.metodo === 'Tc' && stripe) {// Stripe
      if (stripe.pi) {
        reference = [`Payment ID: ${stripe.pi}`, `${reference || ''}`].filter(v => v).join(', ');
      }
      if (stripe.comision) {
        amount = this.agregarComision(this.formPago.value.monto, stripe.comision);
        // Agregar cargo de comision de tarjeta
        comisiones.push({
          cantidad: 1,
          monto: this.agregarComision(this.formPago.value.monto, stripe.comision, true),
          entry: this.entrySeleccionado,
          notas: `Payment ID: ${stripe.pi || ''}`,
          concepto: null,
          idConcurso: this.concurso?.id,
          idUsuario: this.idUsuario
        });
      }
    } else if (this.formPago.value.metodo === 'Tc') {// Chase
      amount = this.agregarComision(this.formPago.value.monto, .03);
      // TODO: Agregar referencia de pago con tarjeta chase
      // En chase la transaccion del pago se crea en el backend
    }

    const pago = {
      metodo: this.formPago.value.metodo,
      referencia: reference || '',
      fecha: this.formPago.value.fecha,
      monto: amount,
      entry: this.entrySeleccionado,
      idUsuario: this.idUsuario,
      idConcurso: this.concurso?.id
    };

    const payload = {
      pago,
      comisiones,
      id_concurso: this.concurso?.id
    };
    // Guardar pago en mysql
    const response = await this.entriesService.addPaymentNest(payload);
    console.log('response', response);
    if (response.data.error) {
      console.log('Error adding payment: ', response);
      $('#checkPaymentModal').modal('hide');
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    $('#checkPaymentModal').modal('hide');
    this.selectEntry(this.entrySeleccionado);
    $.NotificationApp.send('Saved', response.message, 'bottom-right', '#06d5a1', 'success');
  }

  public checkNumberPayment(e) {
    this.numeroCheque = e.currentTarget.value;
    if (this.numeroCheque == '') {
      $('#numero-cheque').addClass('no-valido');
    } else {
      $('#numero-cheque').removeClass('no-valido');
    }
  }

  public imprimir() {
    this.currentDate = moment();
    window.print();

  }

  public async anularPago(idTransaccion, monto, metodo) {
    if (metodo === 'Traspaso') {
      $('#motivoAnulacionTraspaso').modal('show');
      this.motivoAnulacionTraspaso = '';
      this.idTransaccionAnulacionTraspaso = idTransaccion;
      this.montoAnulacionTraspaso = monto;
      return;
    }
    $('#loader').show();
    try {
      const response = await firstValueFrom(
        this.entriesService.cancelPaymentNest(idTransaccion, this.concurso?.id, this.entrySeleccionado, monto, metodo)
      );
      console.log('response', response)
      if (!response.error) {
        this.selectEntry(this.entrySeleccionado);
        $.NotificationApp.send('Canceled', response.message, 'bottom-right', '#06d5a1', 'success');
      } else {
        this.handleError(response.message, 'Error canceling payment.');
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to cancel the payment.');
    }
  }

  public async eliminarCargo(idTransaccion, monto, nombre, referencia, quantity) {
    try {
      const response = await firstValueFrom(
        this.entriesService.cancelChargeNest(idTransaccion, this.concurso?.id, this.entrySeleccionado, nombre, referencia, quantity, monto)
      );
      console.log('response', response.data)
      if (!response.data.error) {
        await this.getEntryInfo();
        const [chargesResponse, ordersResponse] = await Promise.all([
          this.entriesService.getCharges(this.concurso!.id, [this.entrySeleccionado]),
          this.feedOrdersService.entrySummary(this.concurso!.id, this.entrySeleccionado).toPromise()
        ]);

        this.getCharges();
        this.summaryOrders = ordersResponse.summary;

        $.NotificationApp.send('Charge removed', response.message, 'bottom-right', '#06d5a1', 'success');
      } else {
        this.handleError(response.message, 'Error removing charge.');
      }
    } catch (error) {
      this.handleError(error, 'It was not possible to remove this charge.');
    }
  }

  public async getCaballos(e) {
    const filtro: string = e.target.value;
    if (filtro.length > 2) {
      const { caballos, error } = await firstValueFrom(
        this.caballoService.getCaballosFiltrados(filtro, this.concurso?.id)
      ).then(r => ({ ...r, error: r.message ?? false })).catch(r => ({ error: r.message ?? '' }));
      if (error) {
        $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return;
      }
      this.caballos = caballos;
      this.add.set('horse', this.caballos.length === 0);
    } else {
      this.caballos = [];
      this.add.set('horse', false);
    }
  }

  public displayCaballo(idCaballo): string {
    const caballo = this.caballos.find(c => c.id_caballo == idCaballo);
    return caballo ? caballo.fullname : idCaballo;
  }

  public async getPersonas(e, tipo: '' | 'payee' | 'rider' | 'trainer' | 'owner') {
    const filtro: string = e.target.value; // Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    this.filterBalanceTransferEntries = filtro;
    if (filtro.length > 3) {
      const { personas, error } = await firstValueFrom(
        this.personaService.getPersonasFiltradas(filtro, this.concurso?.id)
      ).then(r => ({ ...r, error: r.message ?? false })).catch(r => ({ error: r.message ?? '' }));
      if (error) {
        $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return;
      }
      switch (tipo) {
        case 'payee':
          this.responsables = personas;
          console.log(this.responsables);
          this.add.set('payee', this.responsables.length === 0);
          break;
        case 'rider':
          this.jinetes = personas;
          this.add.set('rider', this.jinetes.length === 0);
          break;
        case 'trainer':
          this.entrenadores = personas;
          this.add.set('trainer', this.entrenadores.length === 0);
          break;
        case 'owner':
          this.owners = personas;
          this.add.set('owner', this.owners.length === 0);
          break;
      }
    } else {
      switch (tipo) {
        case 'payee':
          this.responsables = [];
          this.add.set('payee', false);
          break;
        case 'rider':
          this.jinetes = [];
          this.add.set('rider', false);
          break;
        case 'trainer':
          this.entrenadores = [];
          this.add.set('trainer', false);
          break;
        case 'owner':
          this.owners = [];
          this.add.set('owner', false);
          break;
      }
    }
  }

  public displayResponsable(id_persona): string {
    const responsable = this.responsables.find(p => p.id_persona == id_persona) ?? null;
    return responsable?.fullname ?? (id_persona ?? '');
  }

  public displayJinete(id_persona): string {
    const jinete = this.jinetes.find(p => p.id_persona == id_persona) ?? null;
    return jinete?.fullname ?? (id_persona ?? '');
  }

  public displayEntrenador(id_persona): string {
    const entrenador = this.entrenadores.find(p => p.id_persona == id_persona) ?? null;
    return entrenador?.fullname ?? (id_persona ?? '');
  }

  public displayOwner(id_persona): string {
    const owner = this.owners.find(p => p.id_persona == id_persona) ?? null;
    return owner?.fullname ?? (id_persona ?? '');
  }

  public displayPais(idPais): string {
    const pais = this.paises.find(p => p.id == idPais);

    return pais ? pais.name : idPais;
  }

  public async addEntry(e) {
    // Validar que el valor de caballo sea tomado desde la lista
    const caballo = this.caballos.find(c => c.id_caballo == this.entryForm.value.horse);
    if (!caballo) {
      this.entryForm.patchValue({ horse: '' });
      return;
    }
    // Validar que el valor de responsable sea tomado desde la lista
    const payee = this.responsables.find(p => p.id_persona == this.entryForm.value.payee);
    if (!payee) { this.entryForm.patchValue({ payee: '' }); }
    // Validar que el valor de entrenador sea tomado desde la lista
    const trainer = this.entrenadores.find(p => p.id_persona == this.entryForm.value.trainer);
    if (!trainer) { this.entryForm.patchValue({ trainer: '' }); }
    // Validar que el valor de owner sea tomado desde la lista
    const owner = this.owners.find(o => o.id_persona == this.entryForm.value.owner);
    if (!owner) { this.entryForm.patchValue({ owner: '' }); }
    // Validar que formulario este completo
    if (this.entryForm.valid && this.newEntryRiders.length > 0) {
      $('#loader').show();
      let index = 0;
      for (const r of this.newEntryRiders) {
        this.entryForm.get('rider').setValue(r.id);
        if (index === 0) {
          index = index + 1;
          await this.entriesService.addEntry(this.entryForm.value, this.concurso?.id, this.idUsuario).toPromise().then(
            response => {
              if (!response.error) {
                e.target.classList.remove('was-validated');
                // Actualizar info usef del caballo y personas relacionadas al agregar o editar un entry
                this.selectEntry(this.entryForm.value.entry);
                this.entryForm.setValue({
                  entry: '',
                  horse: '',
                  owner: '',
                  payee: '',
                  rider: '',
                  trainer: ''
                });
                this.caballos = [];
                this.owners = [];
                this.responsables = [];
                this.newEntryRiders = [];
                this.entrenadores = [];
                $('#loader').hide();
                $.NotificationApp.send('Success', 'The entry has been saved successfully.', 'bottom-right', '#0acf97', 'success');
              } else {
                $('#loader').hide();
                $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
                index = this.newEntryRiders.length + 1; // terminar ciclo
              }
            },
            error => {
              $('#loader').hide();
              $.NotificationApp.send('Error', 'It has not been possible to add the entry.', 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
            }
          );
        } else if (index <= this.newEntryRiders.length) {
          index = index + 1;
          // SI hay mas de un jinete .....
          await this.entriesService.addCombination(
            r.id,
            this.entryForm.get('trainer').value,
            this.entryForm.get('horse').value,
            this.entryForm.get('payee').value,
            this.entryForm.get('owner').value,
            this.entryForm.get('entry').value,
            this.concurso?.id
          ).toPromise().then(
            response => {
              if (!response.error) {
              } else {
                $('#loader').hide();
                $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
              }
            },
            error => {
              $('#loader').hide();
              $.NotificationApp.send('Error', 'It has not been possible to add the combination.', 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
            }
          );
        }
      }

    } else {
      $('#loader').hide();
      $.NotificationApp.send('Error', 'Please fill the form correctly.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public riderSelected(e): void {
    const index = this.jinetes.findIndex(p => p.id_persona == e);
    if (index !== -1) {
      this.newEntryRiders.push({
        'id': e,
        'fullname': this.jinetes[index].fullname,
        'usef': this.jinetes[index].usef,
        'fei': this.jinetes[index].fei
      });
      this.entryForm.get('rider').setValue('');
    }
  }

  public removeRider(id): void {
    const index = this.newEntryRiders.findIndex(r => r.id == id);
    this.newEntryRiders.splice(index, 1);
  }

  public mostrarPanelPersona(entidad) {
    this.organization = false;
    this.cerrarPaneles();
    $('#personForm').removeClass('was-validated');
    // Limpiar formulario al abrir
    this.personForm.reset({
      id: '',
      fei: '',
      usef: '',
      ushja: '',
      nsba: '',
      nrha: '',
      name: '',
      lastname: '',
      maternalLastname: '',
      notas: '',
      amateurProStatus: '',
      w9: '',
      email: '',
      age: '',
      nationality: '',
      dateOfBirth: '',
      telephone: '',
      cellPhone: '',
      gender: '',
      usefAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      mailAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxId: '',
      taxName: '',
      suspensionStatus: '',
      amateur: false,
      stripeCustomerId: ''
    });

    this.organization = false;

    switch (entidad) {
      case 'rider':
        this.personForm.patchValue({
          id: '',
          name: this.entryForm.value.rider
        });
        this.panel.set('rider', true);
        break;
      case 'payee':
        this.personForm.patchValue({
          id: '',
          name: this.entryForm.value.payee
        });
        this.panel.set('payee', true);
        break;
      case 'trainer':
        this.personForm.patchValue({
          id: '',
          name: this.entryForm.value.trainer
        });
        this.panel.set('trainer', true);
        break;
      case 'owner':
        this.personForm.patchValue({
          id: '',
          name: this.entryForm.value.owner
        });
        this.panel.set('owner', true);
        break;
    }
  }

  public mostrarAddPerson(tipo: '' | 'rider' | 'payee' | 'trainer' | 'owner') {
    $('#personForm').removeClass('was-validated');
    // Limpiar formulario al abrir
    this.personForm.patchValue({
      id: '',
      fei: '',
      usef: '',
      ushja: '',
      nsba: '',
      nrha: '',
      name: '',
      lastname: '',
      maternalLastname: '',
      notas: '',
      amateurProStatus: '',
      w9: '',
      email: '',
      age: '',
      nationality: '',
      dateOfBirth: '',
      telephone: '',
      cellPhone: '',
      gender: '',
      usefAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      mailAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxId: '',
      taxName: '',
      suspensionStatus: '',
      amateur: false,
      stripeCustomerId: ''
    });
    this.organization = false;
    this.tipoPersona = tipo;
    switch (tipo) {
      case 'rider':
        this.personForm.patchValue({ name: this.editRiderTrainerForm.value.rider });
        break;
      case 'payee':
        this.personForm.patchValue({ name: this.editEntryForm.value.payee });
        break;
      case 'trainer':
        this.personForm.patchValue({ name: this.changeTrainerForm.value.trainer });
        this.personForm.patchValue({ name: this.editRiderTrainerForm.value.trainer });
        break;
      case 'owner':
        this.personForm.patchValue({ name: this.changeOwnerForm.value.owner });
        break;
    }
    $('#editOwner').modal('show');
  }

  public mostrarAddHorse() {
    console.log('Add Horse');
    $('#editHorse').modal('show');
  }



  public cerrarPaneles() {
    this.panel = new Map<string, boolean>([
      ['horse', false],
      ['rider', false],
      ['payee', false],
      ['trainer', false],
      ['owner', false],
    ]);
  }

  public async getHorseFeiInfo() {
    const fei = this.horseFormAdd?.get('fei').value.toUpperCase();
    console.log('FEI:', fei);

    if (fei) {
      $('#loader-fei').show();
      try {
        const response = await firstValueFrom(this.caballoService.getFeiInfo(fei));
        if (!response.error) {
          $('#loader-fei').hide();
          const caballo = response?.caballo ?? {};
          const { name, chip, country, color, breed, gender, dateOfBirth, sire, dam, owner } = this.horseFormAdd.value;
          this.horseFormAdd.patchValue({
            name: caballo?.name ?? name,
            chip: caballo?.chipId ?? chip,
            country: caballo?.countryOfOrigin ?? country,
            color: caballo?.color ?? color,
            breed: caballo?.breed ?? breed,
            gender: caballo?.gender ?? gender,
            dateOfBirth: caballo?.dateOfBirth ?? dateOfBirth,
            sire: caballo?.sire ?? sire,
            dam: caballo?.dam ?? dam,
            owner: caballo?.owner ?? owner,
          });
        } else {
          $('#loader-fei').hide();
          this.handleError(response.message, 'Error getting FEI information.');
        }
      } catch (error) {
        $('#loader-fei').hide();
        this.handleError(error, 'It has not been possible to perform the query.');
      }
    } else {
      $.NotificationApp.send('Invalid FEI.', 'The FEI number is invalid or not assigned.', 'bottom-right', '#ffbc00', 'warning');
    }
  }

  public async getHorseUsefData() {
    const usef = this.horseFormAdd.get('usef').value;
    if (usef) {
      $('#loader-usef').show();
      try {
        const response = await firstValueFrom(this.caballoService.getUsefData(usef, this.concurso?.id));
        if (!response.error) {
          $('#loader-usef').hide();
          const caballo = response?.usefData ?? {};
          const { name, chip, country, color, breed, gender, dateOfBirth, sire, dam, owner, fei, height } = this.horseFormAdd.value;
          this.horseFormAdd.patchValue({
            name: caballo?.name ?? name,
            chip: caballo?.chip ?? chip,
            country: caballo?.countryOfOrigin ?? country,
            color: caballo?.color ?? color,
            breed: caballo?.breed ?? breed,
            gender: caballo?.gender ?? gender,
            dateOfBirth: caballo?.dateofBirth ?? dateOfBirth,
            sire: caballo?.sire ?? sire,
            dam: caballo?.dam ?? dam,
            owner: caballo?.owner ?? owner,
            fei: caballo?.fei ?? fei,
            height: caballo?.height ?? height,
          });
        } else {
          $('#loader-usef').hide();
          this.handleError(response.message);
        }
      } catch (error) {
        $('#loader-usef').hide();
        this.handleError(error, 'It has not been possible to perform the query.');
      }
    } else {
      $.NotificationApp.send('Invalid Usef ID.', 'The Usef ID is invalid or not assigned.', 'bottom-right', '#ffbc00', 'warning');
    }
  }

  public async addHorse(caballo) {
    this.caballos = [caballo];
    this.entryForm.patchValue({ horse: caballo.id_caballo });
    this.editEntryForm.patchValue({ horse: caballo.id_caballo });
    if (caballo?.owner) {
      this.entryForm.patchValue({ owner: caballo?.owner });
      this.responsables = this.owners;
      this.entryForm.patchValue({ payee: caballo?.owner });
    }
    this.closeHorseModal();
  }

  public async getPersonFeiInfo() {
    const fei = this.personForm.value.fei;
    if (!fei) {
      $.NotificationApp.send('Invalid FEI.', 'The FEI number is invalid or not assigned.', 'bottom-right', '#ffbc00', 'warning');
      return;
    }
    $('#loader-fei').show();

    await firstValueFrom(
      this.personaService.getFeiInfo(fei)
    ).then(
      (response: any) => {
        if (!response.error) {
          $('#loader-fei').hide();
          const feiInfo: FEIAthleteResponse = response;
          // Verificar si el pais de nacimiento está en la lista de passes
          const pais = this.paises.find(p => p.acronym === feiInfo.CompetingFor) ?? {};
          const countryOfBirth = pais?.id ?? '';
          const { name, lastname, nationality, dateOfBirth, gender, age } = this.personForm.value;
          this.personForm.patchValue({
            name: feiInfo.Firstname ?? name,
            lastname: feiInfo.FamilyName ?? lastname,
            nationality: countryOfBirth ?? nationality,
            dateOfBirth: feiInfo.DateOfBirth.substring(0, 10) ?? dateOfBirth,
            // Calculate this person's age based on the date of birth
            age: feiInfo.DateOfBirth ? new Date().getFullYear() - new Date(feiInfo.DateOfBirth).getFullYear() : age,
            gender: feiInfo.GenderCode === 'M' ? 'Male' : feiInfo.GenderCode === 'F' ? 'Female' : gender
          });

        } else {
          $('#loader-fei').hide();
          $.NotificationApp.send('Error', response.error, 'bottom-right', '#fa5c7c', 'error');
          return;
        }
      }
    );
    $('#loader-fei').hide();
  }

  public async getPersonUsefData() {
    const usef = this.personForm.value.usef;
    if (!usef) {
      $('#loader-usef').hide();
      $.NotificationApp.send('Invalid Usef ID.', 'The Usef ID is invalid or not assigned.', 'bottom-right', '#ffbc00', 'warning');
      return;
    }
    $('#loader-usef').show();
    const { usefData, error } = await firstValueFrom(
      this.personaService.getUsefData(usef, this.organization)
    ).then(r => ({ ...r, error: r?.error ?? false })).catch(r => ({ error: r?.message ?? '' }));
    if (error) {
      $('#loader-usef').hide();
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const {
      name,
      lastname,
      email,
      age,
      fei,
      telephone,
      dateOfBirth,
      gender,
      amateurProStatus,
      country,
      state,
      city,
      address,
      address2,
      zip
    } = this.personForm.value;
    this.personForm.patchValue({
      name: usefData.name ?? name,
      lastname: usefData.lastname ?? lastname,
      email: usefData.email ?? email,
      age: usefData.age ?? age,
      fei: usefData.fei ?? fei,
      telephone: usefData.telephone ?? telephone,
      dateOfBirth: usefData.dateofBirth ?? dateOfBirth,
      gender: usefData.gender ?? gender,
      amateurProStatus: usefData.amateurProStatus ?? amateurProStatus,
      usefAddress: {
        country: usefData.country ?? country,
        state: usefData.state ?? state,
        city: usefData.city ?? city,
        address: usefData.address ?? address,
        address2: usefData.address2 ?? address2,
        zip: usefData.zip ?? zip,
      }
    });
    $('#loader-usef').hide();
  }


  isLoading = false;

  public async getPersonECInfo() {

    const ec = this.personForm.value.ec_id;
    if (!ec) {
      this.isLoading = false;
      $.NotificationApp.send('Invalid Sport licence number.', 'The Sport licence number is invalid or empty.', 'bottom-right', '#ffbc00', 'warning');
      return;
    }
    this.isLoading = true;

    let ecData = null;
    let error = null;
    try {
      // API call to EC backend

      ecData = await firstValueFrom(this.personaService.getECData(ec));
      error = ecData?.error ?? false; //error is properly checked in the response
    } catch (err) {
      error = err?.message ?? 'An unknown error occurred';
    } finally {
      this.isLoading = false;
    }

    if (error) {
      this.isLoading = false;
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      return;
    }

    const formData = this.personForm.value;

    this.personForm.patchValue({
      ...formData, // spread the existing form data
      name: ecData.name ?? formData.name,
      lastname: ecData.lastname ?? formData.lastname,
      email: ecData.email ?? formData.email,
      age: ecData.age ?? formData.age,
      fei: ecData.fei ?? formData.fei,
      telephone: ecData.telephone ?? formData.telephone,
      dateOfBirth: ecData.dateOfBirth ?? formData.dateOfBirth,
      gender: ecData.gender ?? formData.gender,
      amateurProStatus: ecData.amateurProStatus ?? formData.amateurProStatus,
      usefAddress: {
        ...formData.usefAddress, // spread the existing usefAddress data
        country: ecData.country ?? formData.usefAddress.country,
        state: ecData.state ?? formData.usefAddress.state,
        city: ecData.city ?? formData.usefAddress.city,
        address: ecData.address ?? formData.usefAddress.address,
        address2: ecData.address2 ?? formData.usefAddress.address2,
        zip: ecData.zip ?? formData.usefAddress.zip,
      }
    });

    this.isLoading = false;
  }


  public async agregarPersona() {
    // Validar que el valor de pais sea tomado desde la lista
    const pais = this.paises.find(p => p.id == this.personForm.value.nationality);
    if (!pais) { this.personForm.patchValue({ nationality: '' }); }
    if (this.personForm.valid) {
      $('#loader').show();
      // se hace stringify y parse para que no se haga un bind entre las variables
      const person = {
        ...this.personForm.value,
        address: this.personForm.value.usefAddress.address,
        address2: this.personForm.value.usefAddress.address2,
        country: this.personForm.value.usefAddress.country,
        city: this.personForm.value.usefAddress.city,
        state: this.personForm.value.usefAddress.state,
        zip: this.personForm.value.usefAddress.zip,
      };
      person.dateOfBirth = person?.dateOfBirth?.length === 10 ? person?.dateOfBirth : '';
      person.entity_type = this.organization ? 'O' : 'I';
      // Si no tiene id es una nueva persona
      if (!person.id) {
        const { persona, error } = await firstValueFrom(
          this.personaService.addPersona(person, this.concurso?.id, this.idUsuario)
        ).then(r => ({ ...r, error: r?.message ?? false })).catch(e => ({ error: e?.message ?? '' }));
        if (error) {
          $('#loader').hide();
          $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
          return;
        }
        $('#loader').hide();
        if (this.panel.get('rider') || this.tipoPersona === 'rider') {
          this.jinetes.push(persona);
          this.entryForm.patchValue({ rider: persona?.id_persona });
          this.editRiderTrainerForm.patchValue({ rider: persona.id_persona });
        } else if (this.panel.get('payee') || this.tipoPersona === 'payee') {
          this.responsables.push(persona);
          this.entryForm.get('payee').patchValue({ payee: persona.id_persona });
          this.editEntryForm.get('payee').patchValue({ payee: persona.id_persona });
        } else if (this.panel.get('trainer') || this.tipoPersona === 'trainer') {
          this.entrenadores.push(persona);
          this.entryForm.get('trainer').patchValue({ trainer: persona.id_persona });
          this.editRiderTrainerForm.get('trainer').patchValue({ trainer: persona.id_persona });
          this.changeTrainerForm.get('trainer').patchValue({ trainer: persona.id_persona });
        } else if (this.panel.get('owner') || this.tipoPersona === 'owner') {
          this.owners.push(persona);
          this.responsables.push(persona);
          this.entryForm.get('owner').patchValue({ owner: persona.id_persona });
          this.entryForm.get('payee').patchValue({ payee: persona.id_persona });
          this.changeOwnerForm.get('owner').patchValue({ owner: persona.id_persona });
        }
        this.add.set(this.tipoPersona, false);
        this.tipoPersona = '';
        this.cerrarPaneles();
        $('#editOwner').modal('hide');
        $.NotificationApp.send('Success', 'The person has been added successfully.', 'bottom-right', '#06d5a1', 'success');
      } else {
        // Si tiene id es una persona existente

        console.log('Person:', person);

        // Mapeo correcto para Nest
        const personaNest = {
          id_persona: person.id,
          name: person.name,
          lastname: person.lastname,
          fei: person.fei,
          usef_id: person.usef,
          ec_id: person.ec_id,
          ushja_id: person.ushja,
          amateur: person.amateurProStatus === 'Amateur' ? true : false,
          w9: person.w9,
          email: person.email,
          age: person.age,
          id_nacionalidad: person.nationality,
          fecha_de_nacimiento: person.dateOfBirth,
          phone: person.telephone,
          celular: person.cellPhone,
          gender: person.gender,
          stripe_customer_id: person.stripeCustomerId,
          rfc: person.taxId,
          razon_social: person.taxName,
          usef_address: person.usefAddress,
          tax_address: person.taxAddress,
          mail_address: person.mailAddress,
          notas: person.notas,
        };

        const { error } = await firstValueFrom(
          // this.personaService.editPerson(person, person?.id, this.concurso?.id, this.idUsuario)
          this.personaService.editPersonNest(personaNest, personaNest.id_persona)
        ).then(r => ({ ...r, error: r?.message ?? false })).catch(e => ({ error: e?.message ?? '' }));
        if (error) {
          $('#loader').hide();
          $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
          return;
        }
        this.getEntryInfo();
        this.getRidersClasses();
        this.tipoPersona = '';
        $('#editOwner').modal('hide');
        $('#loader').hide();
      }
    }
    $('#loader').hide();
  }

  private makeid(length) {
    const result = [];
    const characters = '123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  }

  public reset() {
    // Limpiar formulario entry
    this.entryForm.reset({
      entry: '',
      horse: '',
      payee: '',
      rider: '',
      trainer: '',
      owner: '',
    });
    // Limpiar arreglo de jinetes
    this.newEntryRiders = [];
    // Cambiar a pantalla inicial
    this.pantalla = 0;
    // Limpiar entry seleccionado
    this.entrySeleccionado = '';
    // Quitar clase de validacion de formularios
    $('form').removeClass('was-validated');
    // Limpiar arrays de consulta
    this.caballos = [];
    this.responsables = [];
    this.jinetes = [];
    this.entrenadores = [];
    this.owners = [];
    // Quitar botones de añadir
    this.add = new Map<string, boolean>([
      ['horse', false],
      ['payee', false],
      ['rider', false],
      ['trainer', false],
      ['owner', false]
    ]);
    // Ocultar todos los paneles de agregar
    this.panel = new Map<string, boolean>([
      ['horse', false],
      ['payee', false],
      ['rider', false],
      ['trainer', false],
      ['owner', false]
    ]);
    // Entidades de entry
    this.horse = {};
    this.trainer = null;
    this.payee = null;
    this.owner = null;
    this.riders = null;
    // Limpiar tipo persona
    this.tipoPersona = '';
  }

  public showAddEntry() {
    this.reset();
    $('#input-entry').focus();
  }

  public showEditRiderTrainer(idBinomio: number, jinete: number, nombreJinete: string, entrenador: number, nombreEntrenador: string) {
    this.binomioSeleccionado = idBinomio;
    // Quitar clase de validacion de formulario entry
    $('#editRiderTrainerForm').removeClass('was-validated');
    // Limpiar arrays de consulta y solo dejar el jinete y entrenador actual
    this.jinetes = [{
      id_persona: jinete,
      fullname: nombreJinete
    }];
    this.entrenadores = [{
      id_persona: entrenador,
      fullname: nombreEntrenador
    }];
    this.editRiderTrainerForm.patchValue({
      rider: jinete,
      trainer: entrenador
    });
    $('#editRider').modal('show');
  }

  public showEditEntry(id_caballo, horse, id_responsable, payee) {
    // Quitar clase de validacion de formulario entry
    $('#editEntryForm').removeClass('was-validated');
    // const index = this.entries.findIndex(e => e.entry == this.entrySeleccionado);
    // Limpiar arrays de consulta y solo dejar el caballo y responsable actual
    this.caballos = [{
      id_caballo: id_caballo,
      fullname: horse
    }];
    this.responsables = [{
      id_persona: id_responsable,
      fullname: payee
    }];
    this.editEntryForm.patchValue({
      horse: id_caballo,
      payee: id_responsable
    });
    $('#editEntry').modal('show');
  }

  public showchangeOwner(name: string, id: number) {
    // Quitar clase de validacion de formulario entry
    $('#changeOwnerForm').removeClass('was-validated');
    // Limpiar arrays de consulta y solo dejar el del dueño
    if (id) {
      this.owners = [{
        id_persona: id,
        fullname: name
      }];
      this.changeOwnerForm.patchValue({ owner: id });
    } else {
      this.owners = [];
      this.changeOwnerForm.patchValue({ owner: '' });
    }
    $('#changeOwner').modal('show');
  }

  public showchangeTrainer(name: string, id: number) {
    // Quitar clase de validacion de formulario entry
    $('#changeTrainerForm').removeClass('was-validated');
    // Limpiar arrays de consulta y solo dejar el del dueño
    if (id) {
      this.entrenadores = [{
        id_persona: id,
        fullname: name
      }];
      this.changeTrainerForm.patchValue({ trainer: id });
    } else {
      this.entrenadores = [];
      this.changeTrainerForm.patchValue({ trainer: '' });
    }
    $('#changeTrainer').modal('show');
  }

  public async showChangeHorse() {
    console.log('Changing horse');
    this.actions.showChangeHorse = true;
    $('#editHorse').modal('show');
  }

  public mostrarPanelHorse() {
    console.log('Add New Horse');
    this.openCloseHorseForm();
    // $('#editHorse').modal('show');
  }

  /* Opens and closes the horse form */
  public openCloseHorseForm() {
    this.actions.showHorseForm = !this.actions.showHorseForm;
    if (this.actions.showHorseForm) {
      console.log('Showing horse form');
      $('#editHorse').modal('show');
    } else {
      console.log('Hiding horse form');
      $('#editHorse').modal('hide');
    }
  }

  editHorse(caballo) {
    console.log('Edit Horse', caballo);
    this.horse.name = caballo.fullname || '';
    this.horse.usef.id = caballo.usef_id || '';
    this.horse.ushja.id = caballo.ushja_id || '';
    this.closeHorseModal();
  }

  closeHorseModal() {
    this.actions.showHorseForm = false;
    $('#editHorse').modal('hide');
  }

  public async showEditPerson(id: number, tipo: '' | 'payee' | 'trainer' | 'owner' | 'rider') {
    $('#personForm').removeClass('was-validated');
    $('#loader').show();
    this.tipoPersona = tipo;
    this.organization = false;

    const { info, error } = await firstValueFrom(
      this.personaService.getInfo(id))
      .then(r => ({ info: r?.error ? null : r?.data, error: r?.error ? r?.message : null }))
      .catch(e => ({ info: null, error: e?.message || e }));
    if (error) {
      $('#loader').hide();
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.personForm.patchValue({
      id: id,
      fei: info?.fei,
      usef: info?.usef_id,
      ec_id: info?.ec_id,
      ushja: info?.ushja_id,
      nsba: info?.nsba_id,
      nrha: info?.nrha,
      name: info?.name,
      lastname: info?.lastname,
      maternalLastname: info?.lastname_2,
      notas: info?.notas,
      amateurProStatus: info?.categoria,
      w9: info?.w9 || '',
      email: info?.email,
      age: info?.age,
      nationality: info?.id_nacionalidad == '' ? null : info?.id_nacionalidad,
      dateOfBirth: info?.fecha_de_nacimiento,
      telephone: info?.telefono,
      cellPhone: info?.phone,
      gender: info?.gender,
      usefAddress: {
        country: info?.usef_address?.country || '',
        state: info?.usef_address?.state || '',
        city: info?.usef_address?.city || '',
        address: info?.usef_address?.address || '',
        address2: info?.usef_address?.address2 || '',
        zip: info?.usef_address?.zip || '',
      },
      taxAddress: {
        country: info?.tax_address?.country || '',
        state: info?.tax_address?.state || '',
        city: info?.tax_address?.city || '',
        address: info?.tax_address?.address || '',
        address2: info?.tax_address?.address2 || '',
        zip: info?.tax_address?.zip || '',
      },
      mailAddress: {
        country: info?.mail_address?.country || '',
        state: info?.mail_address?.state || '',
        city: info?.mail_address?.city || '',
        address: info?.mail_address?.address || '',
        address2: info?.mail_address?.address2 || '',
        zip: info?.mail_address?.zip || '',
      },
      taxId: info?.rfc,
      taxName: info?.razon_social,
      suspensionStatus: info?.suspensionStatus,
      amateur: info?.amateur,
      stripeCustomerId: info?.stripe_customer_id || ''
    });
    this.organization = info.entity_type === 'O';
    $('#loader').hide();
    $('#editOwner').modal('show');
  }

  public showAddCombination() {
    // Quitar clase de validacion de formulario entry
    $('#addRiderTrainerForm').removeClass('was-validated');
    // Limpiar arrays de consulta
    this.jinetes = [];
    this.entrenadores = [];
    this.addRiderTrainerForm.patchValue({ rider: '', trainer: '' });
    if (this.trainer) {
      this.entrenadores.push({
        id_persona: this.trainer?.id,
        fullname: this.trainer?.fullname
      });
      this.addRiderTrainerForm.patchValue({ trainer: this.trainer?.id });
    }
    $('#addCombination').modal('show');
    setTimeout(() => {
      $('#addCombinationInput').focus();
    }, 400);
  }

  public async editRider() {
    // Validar que el valor de responsable sea tomado desde la lista
    const rider = this.jinetes.find(p => p.id_persona == this.editRiderTrainerForm.value.rider);
    if (!rider) {
      this.editRiderTrainerForm.patchValue({ rider: '' });
      return;
    }
    // Validar que el valor de responsable sea tomado desde la lista
    const trainer = this.entrenadores.find(p => p.id_persona == this.editRiderTrainerForm.value.trainer);
    if (!trainer) {
      this.editRiderTrainerForm.patchValue({ trainer: '' });
      return;
    }
    // Validar que formulario este completo
    if (this.editRiderTrainerForm.valid) {
      $('#loader').show();
      const { error } = await firstValueFrom(
        this.entriesService.editRiderTrainer(
          this.editRiderTrainerForm.value.rider,
          this.editRiderTrainerForm.value.trainer,
          this.binomioSeleccionado,
          this.concurso?.id,
          this.entrySeleccionado)
      ).then(r => ({ ...r, error: r.message ?? false })).catch(r => ({ error: r.message ?? '' }));
      if (error) {
        $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
        return;
      }
      $('#editRider').modal('hide');
      this.selectEntry(this.entrySeleccionado);
    }
  }

  public async editEntry() {
    // Validar que el valor de caballo sea tomado desde la lista
    const caballo = this.caballos.find(c => c.id_caballo == this.editEntryForm.value.horse);
    if (!caballo) {
      this.editEntryForm.patchValue({ horse: '' });
      return;
    }
    // Validar que el valor de responsable sea tomado desde la lista
    const responsable = this.responsables.find(p => p.id_persona == this.editEntryForm.value.payee);
    if (!responsable) {
      this.editEntryForm.patchValue({ payee: '' });
      return;
    }
    // Validar que formulario este completo
    if (this.editEntryForm.valid) {
      $('#loader').show();
      const { error } = await firstValueFrom(
        this.entriesService.editHorsePayee(
          this.editEntryForm.value.horse,
          this.editEntryForm.value.payee,
          this.entrySeleccionado,
          this.concurso?.id,
          this.idUsuario)
      ).then(r => ({ ...r, error: r.message ?? false })).catch(r => ({ error: r.message ?? '' }));
      if (error) {
        $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
        return;
      }
      $('#editEntry').modal('hide');
      $('#loader').hide();
      if (this.entrySeleccionado) { this.selectEntry(this.entrySeleccionado); }
    }
  }

  public async changeOwner() {
    // Validar que el valor de owner sea tomado desde la lista
    const owner = this.owners.find(o => o.id_persona == this.changeOwnerForm.value.owner);
    if (!owner) {
      this.changeOwnerForm.patchValue({ owner: '' });
      return;
    }
    // Validar que formulario este completo
    if (this.changeOwnerForm.valid) {
      $('#loader').show();
      const { error } = await firstValueFrom(
        this.entriesService.changeOwner(this.horse?.id, this.changeOwnerForm.value.owner, this.concurso?.id)
      ).then(r => ({ ...r, error: r.message ?? false })).catch(r => ({ error: r.message ?? '' }));
      if (error) {
        $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
        return;
      }
      this.getEntryInfo();
      this.getRidersClasses();
      $('#changeOwner').modal('hide');
      $('#loader').hide();
    }
  }

  public async changeTrainer() {
    // Validar que el valor de owner sea tomado desde la lista
    const trainer = this.entrenadores.find(o => o.id_persona == this.changeTrainerForm.value.trainer);
    if (!trainer) {
      this.changeTrainerForm.patchValue({ trainer: '' });
      return;
    }
    // Validar que formulario este completo
    if (this.changeTrainerForm.valid) {
      $('#loader').show();
      const { error } = await firstValueFrom(
        this.entriesService.changeTrainer(
          this.horse?.id,
          this.changeTrainerForm.value.trainer,
          this.concurso?.id
        )
      )
        .then(r => ({ ...r, error: r.message ?? false })).catch(r => ({ error: r.message ?? '' }));
      if (error) {
        $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
        return;
      }
      this.getEntryInfo();
      this.getRidersClasses();
      $('#changeTrainer').modal('hide');
      $('#loader').hide();
    }
  }

  public async addCombination() {
    const entry = this.entries.find(e => e.entry == this.entrySeleccionado) ?? {};
    const horse = entry?.id_caballo ?? '';
    const payee = entry?.id_responsable ?? '';
    const owner = this.owner?.id;
    // Validar que el valor de responsable sea tomado desde la lista
    const rider = this.jinetes.find(p => p.id_persona == this.addRiderTrainerForm.get('rider').value);
    if (!rider) {
      this.addRiderTrainerForm.patchValue({ rider: '' });
      return;
    }
    // Validar que el valor de responsable sea tomado desde la lista
    const trainer = this.entrenadores.find(p => p.id_persona == this.addRiderTrainerForm.get('trainer').value);
    if (!trainer) {
      this.addRiderTrainerForm.patchValue({ trainer: '' });
    }
    // Validar que formulario este completo
    if (this.addRiderTrainerForm.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(
          this.entriesService.addCombination(
            this.addRiderTrainerForm.get('rider').value,
            this.addRiderTrainerForm.get('trainer').value,
            horse, payee, owner,
            this.entrySeleccionado,
            this.concurso?.id
          )
        );
        if (!response.error) {
          $('#addCombination').modal('hide');
          this.selectEntry(this.entrySeleccionado);
        } else {
          this.handleError(response.message);
        }
      } catch (error) {
        this.handleError(error, 'It has not been possible to add the combination.');
      }
    }
  }

  public showAddDrops(idBinomio, inscripciones) {
    this.extemporaneos = {};
    this.scratcheados = {};
    this.inscritos = {};

    for (const inscripcion of inscripciones) {
      this.scratcheados[`${inscripcion.ipc}-0`] = inscripcion.scratched;
      this.inscritos[`${inscripcion.ipc}-0`] = true;
    }

    // Arreglo formateado para mostrar las inscripciones en input chips
    let pruebas = [];
    for (const i in this.pruebas) {
      pruebas = pruebas.concat(this.pruebas[i]);
    }
    const inscritosChip = Object.keys(this.inscritos).map(p => {
      const ipc = p.split('-').shift();
      const index = pruebas.findIndex(pba => pba.ipc == ipc);
      return {
        ipc: pruebas[index].ipc,
        numero: pruebas[index].numero,
        fecha: pruebas[index].inicio,
        extemporaneo: false,
        estatus_prueba: pruebas[index].estatus
      };
    });
    for (const i in this.extemporaneos) {
      if (this.extemporaneos[i]) {
        const ipc = i.split('-').shift();
        const index = inscritosChip.findIndex(p => p.ipc == ipc);
        if (index > -1) {
          inscritosChip[index].extemporaneo = true;
        }
      }
    }
    this.inscritosChip = inscritosChip.sort((a, b) => {
      if (a.fecha > b.fecha) { return 1; }
      if (a.fecha < b.fecha) { return -1; }

      if (a.numero > b.numero) { return 1; }
      if (a.numero < b.numero) { return -1; }

      return 0;
    });
    this.binomioSeleccionado = idBinomio;
    this.pruebasFiltradas = [];
    for (const i in this.pruebas) {
      const pbas: any[] = this.pruebas[i].filter(p => Object.keys(this.inscritos).indexOf(`${p.ipc}-0`) > -1);
      if (pbas.length > 0) { this.pruebasFiltradas.push({ key: i, value: pbas }); }
    }
    $('#addDrops').modal('show');
    setTimeout(() => {
      // this.classList.focus();
      $('#input-pruebas').focus();
    }, 400);
  }

  public guardarFirestore(ipc) {
    this.appService.updateShow(this.concurso?.id, ipc);
  }

  public async eliminarBinomio(idBinomio, ipc) {
    $('#loader').show();
    try {
      const response = await firstValueFrom(this.entriesService.eliminarBinomioNP(idBinomio, ipc, this.concurso?.id, this.idUsuario));
      if (!response.error) {
        this.db.collection('resultados').doc(`us_${ipc}`).collection('resultados').doc(`us_${idBinomio}`).delete();
        this.selectEntry(this.entrySeleccionado);
        $.NotificationApp.send('Deregistered', 'Successfully deregistered the rider/horse combination.', 'bottom-right', '#06d5a1', 'success');
      } else {
        this.handleError(response.message, 'It has not been possible to deregister the rider/horse combination.');
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to deregister the entry.');
    }
  }

  public riderSwapModal(ibpc) {
    this.ibpcSwapSeleccionado = ibpc;
    this.jinetes = [];
    this.formRiderSwap.setValue({
      rider: ''
    });
    $('form').removeClass('was-validated');
    $('#riderSwapModal').modal('show');
  }

  public async riderSwap() {
    // Validar que el valor de jinete sea tomado desde la lista
    const indexJinete = this.jinetes.findIndex(p => p.id_persona == this.formRiderSwap.get('rider').value);
    if (indexJinete === -1) {
      this.formRiderSwap.get('rider').setValue('');
    }

    if (this.formRiderSwap.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entriesService.riderSwap(this.ibpcSwapSeleccionado, this.formRiderSwap.value.rider));
        if (!response.error) {
          this.selectEntry(this.entrySeleccionado);
          $('#riderSwapModal').modal('hide');
        } else {
          this.handleError(response.message, 'It has not been possible to perform the rider swap.');
        }
      } catch (error) {
        this.handleError(error, 'It has not been possible to perform the rider swap.');
      }

    }
  }

  public traducirMetodoDePago(metodo): string {
    const metodos = {
      'Tc': 'Credit Card',
      'Cheque': 'Check',
      'Deposito': 'Bank Deposit',
      'Efectivo': 'Cash',
      'Transferencia': 'Wire Transfer',
      'Reembolso': 'Refund',
      'Cortesia': 'Courtesy',
      'Intercambio': 'Exchange',
      'Traspaso': 'Balance Transfer',
      'CC PrePayment': 'CC PrePayment',
      'ach': 'ACH',
      'PrizeMoney': 'Prize Money',
    };

    return metodos[metodo] ? metodos[metodo] : '';
  }

  public mostrarLog() {
    this.getLogs();
  }

  public async mostrarModalTraspaso() {
    $('form').removeClass('was-validated');
    this.entriesTraspaso = []; // this.entries.filter(e => e.entry != this.entrySeleccionado);
    this.formTraspaso.setValue({
      concurso: this.concurso?.id,
      entry: '',
      fecha: this.currentDate.format('YYYY-MM-DD'),
      notas: '',
      monto: '',
      deEntry: this.entrySeleccionado,
      deEntrenador: '',
      entrenador: '',
      deConcurso: this.concurso?.id,
      idUsuario: this.idUsuario
    });
    this.filterBalanceTransferEntries = '';
    this.entrenadoresTraspaso = [];
    const response = await firstValueFrom(
      this.entrenadorService.getEntrenadores(this.formTraspaso.value.concurso)
    ).catch(r => ({ error: true, message: 'It has not been possible to consult the trainers list' }));
    if (response.error) {
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
      console.log(response.message);
      $('#loader').hide();
      return;
    }
    this.entrenadoresTraspaso = response.entrenadores;
    console.log(this.entrenadoresTraspaso);
    $('#loader').hide();
    $('#modal-traspaso').modal('show');
  }

  public async consultarEntrenadoresTraspaso() {
    $('#loader').show();
    this.formTraspaso.get('entrenador').setValue('');
    if (this.formTraspaso.value.concurso == this.concurso?.id) {
      $('#loader').hide();
    } else {
      this.filterBalanceTransferEntries = '';
      this.entrenadoresTraspaso = [];
      const response = await firstValueFrom(
        this.entrenadorService.getEntrenadores(this.formTraspaso.value.concurso)
      ).catch(r => ({ error: true, message: 'It has not been possible to consult the trainers list' }));
      if (response.error) {
        $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
        return;
      }
      this.entrenadoresTraspaso = response.entrenadores;
      console.log(this.entrenadoresTraspaso);
      $('#loader').hide();
    }
  }

  public async consultarEntriesTraspaso() {
    $('#loader').show();
    this.formTraspaso.get('entry').setValue('');
    if (this.formTraspaso.value.concurso == this.concurso?.id) {
      this.entriesTraspaso = this.entries.filter(e => e.entry != this.entrySeleccionado);
      $('#loader').hide();
    } else {
      this.entriesTraspaso = [];
      try {
        const response = await firstValueFrom(this.entriesService.getEntries(this.formTraspaso.value.concurso));
        if (!response.error) {
          this.entriesTraspaso = response.data;
          $('#loader').hide();
        } else {
          this.handleError(response.message, 'It has not been possible to consult the entry list.');
        }
      } catch (error) {
        this.handleError(error, 'It has not been possible to consult the entry list.');
      }
    }
  }

  public async balanceTransfer() {
    if (this.formTraspaso.valid) {
      $('#loader').show();
      // verifica que el valor del entrenador pertenezca a un entrenador válido
      const trainer = this.trainersBalanceTransfer.find(t => t.idEntrenador == this.formTraspaso.value.entrenador);
      console.log(this.formTraspaso.value);
      if (trainer || this.formTraspaso.value.entry != '') {
        try {
          const response = await firstValueFrom(this.entriesService.balanceTransfer(this.formTraspaso.value));
          if (!response.error) {
            $('#modal-traspaso').modal('hide');
            this.selectEntry(this.entrySeleccionado);
            $.NotificationApp.send('Success', response.message, 'bottom-right', '#0acf97', 'success', 10000);
          } else {
            this.handleError(response.message, 'It has not been possible to perform balance transfer.');
          }
        } catch (error) {
          this.handleError(error, 'It has not been possible to perform balance transfer.');
        }
      } else {
        // si no lo encuentra y da click en save, limpia el campo e indica que el entrenador no es valido
        this.formTraspaso.get('entrenador').setValue('');
        $.NotificationApp.send('Error', 'The selected trainer is not valid. Please Try again', 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
        return;
      }
    } else {
      $.NotificationApp.send('Error', 'Some fields are empty', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public validarPruebaRepetida(ipc: string): boolean {
    // TODO: Hacer que funcione con la nueva estructura
    return this.pruebasRepetidas.indexOf(ipc) !== -1;
  }

  public async addClass(event: MatChipInputEvent): Promise<void> {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      // Validar si el valor ingresado es una division o una prueba
      if (['d', 'D'].includes(value[0] || '')) {
        const division = value.slice(1);

        // Validar si valor ingrsado coincide con alguna division del concurso
        let pruebas = [];
        for (const i in this.pruebas) {
          pruebas = pruebas.concat(this.pruebas[i]);
        }

        const pruebasDivision = pruebas.filter(p => p.division_number == division);

        // Si no existen pruebas asignadas a esta division mostrar alerta
        if (pruebasDivision.length === 0) {
          $.NotificationApp.send(
            'Warning', 'The division entered does not exist in this show.',
            'bottom-right',
            '#ffbc00',
            'warning',
            10000);
          if (input) { input.value = ''; }
          return;
        }

        // Pruebas a las que ya esta inscrito
        const pruebasInscrito = this.inscritosChip.map(p => p.ipc);

        // Filtrar solo pruebas en las que aun no esta inscrito
        const pruebasFaltantes = pruebasDivision.filter(p => pruebasInscrito.indexOf(p.ipc) === -1);

        if (pruebasFaltantes.length === 0) {
          $.NotificationApp.send('Warning', 'This entry is already registered in the selected division.', 'bottom-right', '#ffbc00', 'warning', 10000);
          if (input) { input.value = ''; }
          return;
        }
        // Validar si el cabllo ya esta inscrito en esta prueba
        const inscritosChip = Object.keys(this.inscritos).map(p => {
          const ipc = p.split('-').shift();
          const index = pruebas.findIndex(pba => pba.ipc == ipc);

          return {
            ipc: pruebas[index].ipc,
            numero: pruebas[index].numero,
            fecha: pruebas[index].inicio,
            extemporaneo: false
          };
        });

        const promesasInscripcion = pruebasFaltantes.map(p => this.inscribir(p.ipc, '0'));

        Promise.all(promesasInscripcion).then(() => {
          if (input) { input.value = ''; }
          // TODO: Accion a realizar cuando se inscriba a todas las pruebas de la division
        });
        return;
      }

      // Validar si valor ingrsado coincide con alguna prueba del concurso
      let pruebas = [];
      for (const i in this.pruebas) {
        pruebas = pruebas.concat(this.pruebas[i]);
      }

      const index = pruebas.findIndex(p => p.numero == value.trim());
      if (index > -1) {
        const ipc = pruebas[index].ipc;
        const class_number = pruebas[index].numero;
        console.log(ipc, class_number);
        if (this.inscritosChip.findIndex(p => p.ipc == ipc) === -1) {
          this.inscribirNest(ipc, class_number);
        } else {
          $.NotificationApp.send('Warning', 'This rider/horse combination is already registered in the selected class.', 'bottom-right', '#ffbc00', 'warning', 10000);
        }
        if (input) { input.value = ''; }
      } else {
        $.NotificationApp.send('Warning', 'The class entered does not exist in this show.', 'bottom-right', '#ffbc00', 'warning', 10000);
        if (input) { input.value = ''; }
      }
    } else {
      if (input) { input.value = ''; }
    }
  }

  public fechaExp(fecha: string): string {
    return fecha.split('/').reverse().join('/'); // `${fecha.substr(4, 2)}/${fecha.substr(0, 4)}`;
  }

  public fechaExpStripe(month: number, year: number): string {
    return `${month.toString().padStart(2, '0')}/${year}`;
  }

  public async validarCuenta(e) {
    const validated = e.target.checked ? 1 : 0;
    try {
      const response = await firstValueFrom(this.entriesService.validarCuenta(this.concurso?.id, this.entrySeleccionado, validated));
      if (!response.error) {
        if (validated == 1) {
          this.guardarSaldoFS();
          // Desactivar formularios
          this.formCargo.disable();
          // this.formPago.disable();
        } else {
          this.borrarSaldoFS();
          // Activar formularios
          this.formCargo.enable();
          // this.formPago.enable();
        }
      } else {
        this.handleError(response.message, 'It has not been possible to validate the invoice.');
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to validate the invoice.');
    }
  }

  public guardarSaldoFS() {
    // Solo guardar en FS si hay un mail al cual ligarlo y el saldo es mayor a 0
    // TODO: enviar saldo
    if (this.emails.length > 0/* && this.saldo > 0*/) {
      const cargos = this.cargos.map(c => {
        return {
          nombre: c.nombre,
          notas: '',
          cantidad: c.cantidad,
          monto: parseFloat(c.monto),
          detalle: c.detalle.map((d, index) => {
            return {
              index: index,
              nombre: c.nombre === 'Feed Orders' ? d.referencia : c.nombre,
              notas: d.referencia,
              cantidad: d.cantidad,
              monto: parseFloat(d.monto),
              anulado: false
            };
          })
        };
      });

      for (const binomio of [...this.riders_clases.values()]) {
        cargos.unshift({
          nombre: `Class Fees - ${binomio.nombre}`,
          notas: '',
          cantidad: '',
          monto: parseFloat(binomio.totalBinomio),
          detalle: binomio.inscripciones.map((i, index) => {
            return {
              index: index,
              nombre: `Class ${i.numero}`,
              notas: '',
              cantidad: '',
              monto: parseFloat(i.costo),
              anulado: i.scratched
            };
          })
        });
      }

      this.db.collection('saldos').doc(`us_${this.concurso?.id}`).collection('entries').doc(this.entrySeleccionado).set({
        entry: this.entrySeleccionado,
        horse: this.horse?.name ?? '',
        saldo: 0/*this.saldo*/,
        inscripciones: [...this.riders_clases.values()].map(b => {
          return {
            jinete: b.nombre,
            total: parseFloat(b.totalBinomio),
            pruebas: b.inscripciones.map((i, index) => {
              return {
                index: index,
                nombre: i.numero,
                inicio: i.inicio,
                costo: parseFloat(i.costo),
                scratched: i.scratched
              };
            })
          };
        }),
        // totalInscripciones: this.totalInscripciones,
        cargosInscripciones: this.cargosInscripciones,
        cargos: cargos,
        totalCargos: this.totalCargos,
        feedOrders: this.summaryOrders.map(o => {
          return {
            nombre: o.producto,
            cantidad: o.cantidad,
            total: parseFloat(o.total)
          };
        }),
        splits: this.splits.map(s => {
          return {
            nombre: s.entrenador,
            notas: s.notas,
            fecha: s.fecha,
            monto: parseFloat(s.monto),
            anulado: s.anulado
          };
        }),
        totalSplits: this.total_splits,
        pagos: this.pagos.map(p => {
          return {
            metodo: this.traducirMetodoDePago(p.metodo),
            notas: p.referencia,
            monto: parseFloat(p.monto),
            fecha: p.fecha_pago,
            anulado: p.anulado
          };
        }),
        totalPagos: this.totalPagos,
        permios: this.premios.map(p => {
          return {
            rank: p.nombre,
            class: p.nombrePrueba,
            rider: p.jinete,
            amount: parseFloat(p.monto)
          };
        }),
        totalPremios: this.totalPremios,
        totalPremiosAplicados: this.totalPremiosAplicados,
        emails: this.emails
      });
    }
  }

  public async borrarSaldoFS() {
    if (this.trainer) {
      return this.db.collection('saldos').doc(`us_${this.concurso?.id}`).collection('entries').doc(this.entrySeleccionado).delete();
    }
  }

  public async anularTraspaso() {
    $('#loader').show();
    try {
      const response = await firstValueFrom(
        this.entriesService.cancelPayment(
          this.idTransaccionAnulacionTraspaso,
          'Traspaso',
          this.montoAnulacionTraspaso,
          this.idUsuario,
          this.concurso?.id,
          this.entrySeleccionado,
          this.motivoAnulacionTraspaso
        )
      );
      if (!response.error) {
        this.selectEntry(this.entrySeleccionado);
        $('#motivoAnulacionTraspaso').modal('hide');
        $.NotificationApp.send('Canceled', response.message, 'bottom-right', '#06d5a1', 'success', 10000);
      } else {
        this.handleError(response.message, 'It has not been possible to cancel the balance transfer.');
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to cancel the balance transfer.');
    }
  }

  public mostrarModalProductos(): void {
    this.selectedProducts = [];
    this.selectedProduct = '';
    this.selectedQty = 1;
    this.selectedTotal = 0;
    $('#modal-productos').modal('show');
  }

  public changeTotal(): void {
    if (this.selectedProduct && this.selectedQty > 0) {
      const product = this.products.find(p => p.id == this.selectedProduct);
      this.selectedTotal = parseFloat(product.price) * this.selectedQty;
    }
  }

  public addProduct(): void {
    if (this.selectedProduct && this.selectedQty > 0) {
      const product = this.products.find(p => p.id == this.selectedProduct);
      if (this.selectedQty > parseInt(product.qty)) {// No hay inventario suficiente
        $.NotificationApp.send('Error', 'Not enough products.', 'bottom-right', '#fa5c7c', 'error');
        return;
      }
      // Validar que hay suficiente inventario
      this.selectedProducts.push({
        id: product.id,
        name: product.name,
        sku: product.sku,
        qty: this.selectedQty,
        price: this.selectedTotal
      });
      this.selectedProduct = '';
      this.selectedQty = 1;
      this.selectedTotal = 0;
    }
  }

  public removeProduct(id) {
    const index = this.selectedProducts.findIndex(p => p.id == id);
    this.selectedProducts.splice(index, 1);
  }

  public async saveProductCharges(): Promise<void> {
    if (this.selectedProducts.length > 0) {
      $('#loader').show();
      const concepto = await this.productService.concept(this.concurso?.id).toPromise().then(r => r.concepto);
      const requests = this.selectedProducts.map(p => {
        return this.entriesService.addCharge({
          concepto: concepto.id,
          cantidad: p.qty,
          monto: p.price,
          notas: p.name,
          entry: this.entrySeleccionado,
          idUsuario: this.idUsuario,
          idConcurso: this.concurso?.id
        }).toPromise();
      });
      const requests2 = this.selectedProducts.map(p => {
        return this.productService.updateQty(p).toPromise();
      });
      Promise.all(requests.concat(requests2)).then(r => {
        this.getProducts();
        this.selectEntry(this.entrySeleccionado);
      });
    }
  }

  public selectCharge(charge, selected): void {
    charge.selected = selected;
    if (selected) {
      this.cargosSeleccionados.push({ id: charge.id, monto: charge.monto_pagar });
    } else {
      this.cargosSeleccionados = this.cargosSeleccionados.filter(c => c.id != charge.id);
    }
  }

  public async deleteSelectedCharges() {
    $('#loader').show();
    const chargePromises: Promise<any>[] = this.cargosSeleccionados.map(c => {
      return this.entriesService.cancelCharge(c.id, c.monto, this.idUsuario, this.concurso?.id, this.entrySeleccionado).toPromise();
    });
    Promise.all(chargePromises).then(responses => {
      for (const response of responses) {
        if (response.error) {
          $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
          return;
        }
      }
      this.getEntryInfo();
      const orders = this.feedOrdersService.entrySummary(this.concurso!.id, this.entrySeleccionado).toPromise().then(r => {
        this.summaryOrders = r.summary;
        return r;
      });
      Promise.all([this.getCharges(), orders]).then(v => {
        $.NotificationApp.send('Charges removed', 'The charges have been deleted successfully. ', 'bottom-right', '#06d5a1', 'success');
        $('#loader').hide();
      });
    });
  }

  public actualizarNotasTarjeta(): void {
    this.formPago.get('referencia').setValue(this.notasTarjeta);
  }

  public seleccionarCaballo(caballo) {
    if (caballo.owner_id && caballo.owner) {
      this.owners = [{
        id_persona: caballo.owner_id,
        fullname: caballo.owner
      }];
      this.responsables = [{
        id_persona: caballo.owner_id,
        fullname: caballo.owner
      }];
      this.entryForm.get('owner').setValue(caballo.owner_id);
      this.entryForm.get('payee').setValue(caballo.owner_id);
    }
  }

  public nuevoEntryInput(event) {
    this.nuevoEntry = event.target.value;
  }

  public showEditEntryNumber() {
    $('#editEntryNumber').modal('show');

  }

  public async changeEntryNumber() {
    $('#loader').show();
    try {
      const response = await firstValueFrom(
        this.entriesService.changeEntry(this.idUsuario, this.concurso?.id, this.entrySeleccionado, this.nuevoEntry));
      if (!response.error) {
        this.selectEntry(this.nuevoEntry);
        // this.getEntries();
        this.nuevoEntry = '';
        $('#editEntryNumber').modal('hide');
        $('#loader').hide();
        $.NotificationApp.send('Success', response.message, 'bottom-right', '#06d5a1', 'success', 10000);
      } else {
        this.handleError(response.message, 'It has not been possible to change the entry number.');
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to change the entry number.');
    }
  }

  public async applyPrize(event, premio) {
    $('#loader').show();
    const checked = event.target.checked;
    const datos = {
      idUsuario: this.idUsuario,
      ipc: premio.ipc,
      idPremio: premio.id,
      idBinomio: premio.id_binomio,
      nombre: premio.nombre,
      monto: Number(premio.monto),
      deduccion: Number(premio.deduccion),
      pagarAparte: checked ? '1' : '0',
      referencia: premio.referencia
    };

    try {
      const response = await firstValueFrom(this.pruebaService.agregarPremio(datos));
      if (!response.error) {
        this.getEntryInfo();
        this.getRidersClasses();
        const prizes = this.entriesService.getPrizes(this.entrySeleccionado, this.concurso?.id).toPromise().then(r => {
          this.premios = r.premios;
          this.totalPremios = r.totalPremios;
          this.premiosAplicados = r.premiosAplicados;
          this.totalPremiosAplicados = r.totalPremiosAplicados;
          this.championshipSeries = r.championshipSeries;
          this.premiosChampionshipSeries = r.premiosChampionshipSeries;
          this.totalPremiosChampionshipSeries = r.totalPremiosChampionshipSeries;
          return;
        });
        Promise.all([prizes]).then(v => {
          $('#loader').hide();
        });
      } else {
        this.handleError(response.message, 'It has not been possible to apply the prize.');
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to apply the prize.');
    }
  }

  public async saveNotes(e) {
    try {
      const response = await firstValueFrom(this.entriesService.saveNotes(this.concurso?.id, this.entrySeleccionado, e));
      if (!response.error) {
        this.editarNotas = e == '';
        this.notes = e;
      } else {
        this.handleError(response.message, 'It has not been possible to save the notes.');
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to save the notes.');
    }
  }

  async getHorseUsefSuspensionInfo() {
    this.horse.usef.loading = true;
    const { data, error } = await this.usefService.getUSEFhorseSuspensionInfo(+this.horse.usef.id);
    if (error) {
      this.horse.usef.loading = false;
      console.log('Error: ', error);
      return;
    }
    const suspensionData = data.find(h => (h.id_caballo || this.horse?.id) == this.horse.id) || {};
    this.horse.usef.suspended = (suspensionData.usef_suspended || 0) == 1;
    this.horse.usef.suspension_reason = suspensionData.usef_suspension_reason || '';
    this.horse.usef.suspension_updated = moment(suspensionData.usef_suspension_updated).isValid()
      ? moment(suspensionData.usef_suspension_updated).format('DD/MM/YYYY HH:MM a') : null;
    this.horse.usef.loading = false;
    setTimeout(() => {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
      });
    });
    $.NotificationApp.send('Success', 'USEF suspension info was updated successfully.', 'bottom-right', '#06d5a1', 'success', 10000);
  }

  async getHorseUshjaSuspensionInfo() {
    this.horse.ushja.loading = true;
    const { data, error } = await this.usefService.getUSHJAhorseSuspensionInfo(+this.horse.usef.id);
    if (error) {
      this.horse.ushja.loading = false;
      console.log('Error: ', error);
      return;
    }
    const suspensionData = data.find(h => (h.id_caballo || this.horse?.id) == this.horse?.id) || {};
    this.horse.ushja.suspended = (suspensionData.ushja_suspended || 0) == 1;
    this.horse.ushja.suspension_reason = suspensionData.ushja_suspension_reason || '';
    this.horse.ushja.suspension_updated = moment(suspensionData.ushja_suspension_updated).isValid()
      ? moment(suspensionData.ushja_suspension_updated).format('DD/MM/YYYY HH:MM a') : null;
    this.horse.ushja.id = suspensionData.ushjaId;
    this.horse.ushja.loading = false;
    setTimeout(() => {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
      });
    });
    $.NotificationApp.send('Success', 'USHJA suspension info was updated successfully.', 'bottom-right', '#06d5a1', 'success', 10000);
  }

  public async getPersonUsefSuspensionInfo(person: Person) {
    person.usef.loading = true;
    if (!person?.usef?.id) {
      person.usef.suspended = true;
      person.usef.suspension_reason = 'Invalid USEF ID / USEF ID is not present.';
      person.usef.suspension_updated = null;
      person.usef.loading = false;
      // Update tooltips
      $(function () {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
      });
      return;
    }
    const { data, error } = await this.usefService.getUSEFpersonSuspensionInfo(person?.usef?.id, person?.usef?.entity_type || 'I');
    if (error) {
      console.log('Error: ', error);
      person.usef.loading = false;
      return;
    }
    const [suspensionData] = data;
    person.usef.suspended = (suspensionData.usef_suspended || 0) == 1;
    person.usef.suspension_reason = suspensionData.usef_suspension_reason || '';
    person.usef.suspension_updated = suspensionData.usef_suspension_updated || null;
    person.usef.loading = false;
    // Update tooltips
    $(function () {
      $('[data-toggle="tooltip"]').tooltip('dispose');
      $('[data-toggle="tooltip"]').tooltip();
    });

    $.NotificationApp.send(
      'Success',
      'The suspension information has been updated successfully.',
      'bottom-right',
      '#06d5a1',
      'success',
      10000);
    return;
  }

  public async getPersonUshjaSuspensionInfo(person: Person) {
    person.ushja.loading = true;
    if (!person?.usef.id) {
      person.ushja.suspended = true;
      person.ushja.suspension_reason = 'Invalid USHJA ID / USHJA ID is not present.';
      person.ushja.suspension_updated = null;
      person.ushja.loading = false;
      $(function () {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
      });
      return;
    }
    const { data, error } = await this.usefService.getUSHJApersonSuspensionInfo(person.usef.id);
    if (error) {
      console.log('Error: ', error);
      person.ushja.loading = false;
      return;
    }

    const [suspensionData] = data;
    person.ushja.id = suspensionData.ushja_id || '';
    person.ushja.suspended = (suspensionData.ushja_suspended || 0) == 1;
    person.ushja.suspension_reason = suspensionData.ushja_suspension_reason || '';
    person.ushja.suspension_updated = suspensionData.ushja_suspension_updated || null;
    person.ushja.loading = false;
    // Update tooltips
    $(function () {
      $('[data-toggle="tooltip"]').tooltip('dispose');
      $('[data-toggle="tooltip"]').tooltip();
    });
    $.NotificationApp.send(
      'Success',
      'The suspension information has been updated successfully.',
      'bottom-right',
      '#06d5a1',
      'success',
      10000
    );
  }

  public agregarComision(amount: number, feeAmount: number, onlyComision: boolean = false): number {
    const incluirMonto = onlyComision ? 0 : 1;
    const comision = incluirMonto + feeAmount;
    return amount * comision;
  }

  public toggleEditCardholderName(e, stripeCard) {
    e.stopPropagation();
    stripeCard.editable = !stripeCard.editable;
    if (stripeCard.editable) {
      setTimeout(() => {
        $(`#${stripeCard.id}`).select();
      });
    }
  }

  public async updateCardholderName(e, stripeCard) {
    const name = e.target.value;
    const { paymentMethod, message } = await this.stripeService.updateCardholderName(stripeCard.id, name, this.concurso?.id).toPromise();
    if (message) {
      $.NotificationApp.send('Error', message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    stripeCard.billing_details.name = paymentMethod.billing_details.name;
    stripeCard.editable = false;
  }

  public async deleteCard(customerRefNum, id_persona) {
    const { error } = await firstValueFrom(
      this.chaseService.deleteCard(customerRefNum, id_persona)
    ).then(r => (
      { ...r, error: r?.message ?? false }
    )).catch(r => (
      { error: r?.message ?? 'It has not been possible to delete the card.' }
    ));
    if (error) {
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const { cards, err } = await firstValueFrom(
      this.entriesService.tarjetasRelacionadas(this.concurso?.id, [this.entrySeleccionado])
    ).then(r => ({ ...r, err: r?.message ?? false }))
      .catch(r => ({ err: r?.message ?? 'It has not been possible to get the related cards.' }));
    if (err) {
      $.NotificationApp.send('Error', err, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.cards = (cards || []).map(c => ({ ...c, ccAccountNum: c.ccAccountNum.replace(/[xX]/g, ''), ccExp: `${c.ccExp.substr(0, 4)}/${c.ccExp.substr(-2)}` }));
    return cards;
  }

  public showAddCard() {
    this.cardForm.reset({
      number: '',
      date: '',
      name: '',
      save: false
    });
    this.show.set('add-card-form', true);
    setTimeout(() => {
      $.App.init();
    }, 500);
  }

  public hideAddCard() {
    this.show.set('add-card-form', false);
  }

  public async removeRiderFromEntry(binomio) {
    try {
      const response = await firstValueFrom(this.entriesService.removeRider(binomio, this.idUsuario));
      if (!response.error) {
        this.riders_clases.delete(binomio);
        this.binomios = [...this.riders_clases.values()];
        $.NotificationApp.send('Success', 'The rider has been removed successfully.', 'bottom-right', '#0acf97', 'success', 5000);
      } else {
        $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        console.log(response.message);
      }
    } catch (error) {
      this.handleError(error, 'It has not been possible to remove the rider.');
    }
  }

  public async getPersonNrhaData() {
    this.cargando = true;
    const nrha = this.personForm.value.nrha;

    console.log('Get NRHA data: ', nrha);
    if (!nrha) {
      this.cargando = false;
      $.NotificationApp.send('Invalid NRHA ID.', 'The Nrha ID is invalid or not assigned.', 'bottom-right', '#ffbc00', 'warning');
      return;
    }
    const response = await this.personaService.getNRHAInfo(nrha).toPromise().then(r => r || {}, r => r || {});
    if (response.error) {
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#ffbc00', 'warning');
      this.cargando = false;
      return;
    }

    const data = response[0];
    this.personForm.patchValue({
      name: (data.firstName || '') + (data.middleName ? ' ' + data.middleName : ''),
      lastname: data.lastName ?? '',
      email: data.emailAddress ?? '',
      telephone: data.phoneNumber ?? '',
      usefAddress: {
        address: data.line1 ?? '',
        address2: data.line2 ?? '',
        city: data.city ?? '',
        state: data.state ?? '',
        zip: data.zip ?? '',
        country: data.country ?? ''
      }

    });
    this.cargando = false;
  }

  public async getPaymentInfo(data, i) {
    console.log('Getting Payment Info: ', data, i);
    const paymentIntentId = data.match(/pi_[^\s]+/)[0];
    console.log('Payment Intent ID: ', paymentIntentId);

    const response = await this.stripeService.getPaymentInfo(paymentIntentId, this.concurso?.id).toPromise().then(r => r, r => r);

    this.pagos[i].moreInfo = response.message;
    // return paymentIntentId;

  }

  /** Stripe */
  public async handleSubmit(paymentIntent: any) {
    if (!paymentIntent) {
      $.NotificationApp.send(
        "Error",
        "Payment failed - No response received",
        'bottom-right',
        '#fa5c7c',
        'error'
      );
      return;
    }

    if (paymentIntent.status !== 'succeeded') {
      $.NotificationApp.send(
        "Error",
        `Payment failed, status: ${paymentIntent.status}`,
        'bottom-right',
        '#fa5c7c',
        'error'
      );
      return;
    }

    const cardFee = paymentIntent.cardFee;
    const paymentIntentId = paymentIntent.id;

    $('#cardPaymentStripeModal').modal('hide');
    this.stripeFormVisible = false;
    await this.addPayment({ pi: paymentIntentId, comision: cardFee });
  }

  public handleError(reason: any, messageToUser?: string) {
    $('#loader').hide();
    const message = (reason.error || {}).text || (((reason.error || {}).error || {}).message || reason.message || reason.error || 'Error during request.');
    $.NotificationApp.send('Error', messageToUser || message, 'bottom-right', '#fa5c7c', 'error');
    console.log(`%c Handling Error:`, 'color: white; font-size: 11px; background-color: #a02d24; padding: 1px 2px; border-radius: 3px;');
    console.error(message);
    return { error: true, message: messageToUser || message };
  }

  public cleanString(input: string): string {
    console.log(input);
    const regex = /pi_\w+/;
    // encuentra el primer elemento que cumpla con la expresión regular
    const match = input.match(regex);
    // si hay un match, devolver el valor, si no, devolver un string vacío (falsy)
    return match ? match[0] : '';
  }

  // Paginacion
  public onGoTo(page: number): void {
    this.current = page;
  }

  public onNext(page: number): void {
    this.current = page + 1;
  }

  public onPrevious(page: number): void {
    this.current = page - 1;
  }

  public async getEntriesPaginated(page: number) {
    const response = await this.entriesService.getEntriesPaginated(this.concurso?.id, page);
    if (response.error) {
      $('#loader').hide();
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.entries = (response.items || [])
      .map(e => ({
        ...e,
        id: e.id_binomio,
        horse: e.caballo.fullname,
        id_caballo: e.idCaballo,
        id_concurso: e.idConcurso,
        id_responsable: e.idResponsable,
        trainers_id: e.idEntrenador,
        trainers: `${e.trainer.name} ${e.trainer.lastname}`,
        riders: `${e.rider.name} ${e.rider.lastname}`
      }));
    this.total = response.meta.totalPages;
    this.totalItems = response.meta.totalItems;
    if (this.entrySeleccionado) { this.selectEntry(this.entrySeleccionado); }
    $('#loader').hide();
  }

  // Method to open Stripe modal
  public openStripePayment() {
    const amount = parseFloat(this.formPago.get('monto').value || '0');

    // Debug logging
    console.log('Opening Stripe modal from entries with:', {
      amount,
      showId: this.concurso?.id,
      stripeCustomers: this.stripeCustomers?.map(c => ({
        id: c.id,
        name: c.name,
        personId: c.email // Check if this exists
      })),
      metadata: {
        entry: this.entrySeleccionado?.toString(),
        payee_id: this.payee?.id?.toString(),
        trainer_id: this.trainer?.id?.toString(),
        horse_id: this.horse?.id?.toString(),
        show: this.concurso?.id?.toString()
      },
      description: this.stripeDescription,
      defaultCardHolder: this.defaultCardHolder
    });

    // Rest of the code...
  }

  public async getLogs(): Promise<void> {
    if (!this.concurso?.id || !this.entrySeleccionado) {
      return;
    }
    this.logs = [];
    try {
      const response = await firstValueFrom(
        this.logService.getLogsByShowAndEntry(this.concurso.id, this.entrySeleccionado)
      );

      if (!response.error) {
        console.log('Logs: ', response);
        this.logs = response.data;
        console.log('Logs: ', this.logs);
        $('#logModal').modal('show');
      } else {
        console.error('Error fetching logs:', response.message);
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      this.loading.set('logs', false);
    }
  }

  public exportLogsToPdf(): void {
    if (!this.logs || this.logs.length === 0) {
      return;
    }

    // You would typically use a library like jspdf and jspdf-autotable here
    // This is a placeholder function that could be implemented with a PDF export library

    const entryTitle = `Entry ${this.entrySeleccionado} Activity Log`;
    const dateGenerated = new Date().toLocaleString();

    console.log('Exporting logs to PDF:', {
      title: entryTitle,
      date: dateGenerated,
      logCount: this.logs.length
    });

    // Example implementation would be:
    // 1. Import jsPDF and autoTable
    // 2. Create new document
    // 3. Add title and date
    // 4. Create table with log data
    // 5. Save the PDF with a filename based on entry number and date

    $.NotificationApp.send(
      'Export Initiated',
      'Your activity log export is being prepared.',
      'bottom-right',
      '#0acf97',
      'success'
    );
  }

  /**
   * Inscribe un caballo en un concurso
   * @param ipc string con el id de la prueba
   * @param idCategoria string con el id de la categoria
   * @param refresh booleano para refrescar la pagina
   * @returns void
   */
  public async inscribirNest(ipc, class_number): Promise<void> {
    return firstValueFrom(this.entriesService.inscribirNest(this.binomioSeleccionado, ipc, this.idUsuario, this.concurso?.id, this.entrySeleccionado, class_number)).then(
      async (response) => {
        if (!response.data.error) {
          // Actualthis.inscritosizar resultados en app
          this.inscritos[`${ipc}-${0}`] = true;
          await this.db.collection('concursos')
            .doc(`us_${this.concurso?.id}`)
            .collection('pruebas')
            .doc(`us_${ipc}`)
            .set({ cambios: true }, { merge: true });
          this.appService.updateShow(this.concurso?.id, ipc);
          // Actualizar jueces si hay cambios
          await this.db.collection('concursos')
            .doc(`us_${this.concurso?.id}`)
            .collection('pruebas')
            .doc(`us_${ipc}`)
            .set({
              cambios: true,
              cambiosIngate: true
            }, { merge: true });
          // Arreglo formateado para mostrar las inscripciones en input chips
          let pruebas: any[] = [];
          for (const i in this.pruebas) {
            pruebas = pruebas.concat(this.pruebas[i]);
          }
          const inscritosChip = Object.keys(this.inscritos).map(p => {
            const ipc = p.split('-').shift();
            const index = pruebas.findIndex(pba => pba.ipc == ipc);

            return {
              ipc: pruebas[index].ipc,
              numero: pruebas[index].numero,
              fecha: pruebas[index].inicio,
              extemporaneo: false
            };
          });

          for (const i in this.extemporaneos) {
            if (this.extemporaneos[i]) {
              const ipc = i.split('-').shift();
              const index = inscritosChip.findIndex(p => p.ipc == ipc);
              if (index > -1) {
                inscritosChip[index].extemporaneo = true;
              }
            }
          }

          this.inscritosChip = inscritosChip.sort((a, b) => {
            if (a.fecha > b.fecha) { return 1; }
            if (a.fecha < b.fecha) { return -1; }

            if (a.numero > b.numero) { return 1; }
            if (a.numero < b.numero) { return -1; }

            return 0;
          });

          this.pruebasFiltradas = [];
          for (const i in this.pruebas) {
            const pbas: any[] = this.pruebas[i].filter(p => Object.keys(this.inscritos).indexOf(`${p.ipc}-0`) > -1);
            if (pbas.length > 0) { this.pruebasFiltradas.push({ key: i, value: pbas }); }
          }
          if (true) {
            this.getRidersClasses();
            this.getEntryInfo();
            this.getCharges();
            Promise.all([this.getRidersClasses(), this.getCharges(), this.getEntryInfo()]).then(v => {
              // Limpiar formularios
              this.formCargo.reset();
              this.formCargo.setValue({
                concepto: '',
                notas: '',
                cantidad: '',
                monto: ''
              });
              this.formPago.reset();
              this.formPago.setValue({
                metodo: '',
                referencia: '',
                fecha: this.currentDate.format('YYYY-MM-DD'),
                monto: ''
              });
              $('#modal-productos').modal('hide');
              $('#loader').hide();
            });
          }
        } else {
          $('#loader').hide();
          $.NotificationApp.send('Error', response.data.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.data.message);
        }
      },
      error => {
        $('#loader').hide();
        console.log(error);
        $.NotificationApp.send('Error', 'It has not been possible to register the rider/horse combination.', 'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  /**
   * @deprecated Thu 11 Mar 2025
   * @param ipc string con el id de la prueba
   * @param idCategoria string con el id de la categoria
   * @param refresh booleano para refrescar la pagina
   * @returns void
   */
  public async inscribir(ipc, idCategoria, refresh = false): Promise<void> {
    // $('#loader')this.inscritos.show();
    const bpc = {
      idBinomio: this.binomioSeleccionado,
      ipc: ipc,
      idUsuario: this.idUsuario,
      idConcurso: this.concurso?.id,
      idCategoria: idCategoria,
      entry: this.entrySeleccionado
    };
    const continuar = true;
    refresh = true;
    if (!continuar) {
      $('#loader').hide();
      $.NotificationApp.send('Error', 'This entry is already registered in this class.', 'bottom-right', '#fa5c7c', 'error');
    } else {
      return firstValueFrom(this.entriesService.inscribir(bpc)).then(
        async (response) => {
          if (!response.error) {
            // Actualthis.inscritosizar resultados en app
            if (response.accion === 'inscribir') {
              this.inscritos[`${ipc}-${idCategoria}`] = true;
              delete this.scratcheados[`${ipc}-${idCategoria}`];
              delete this.extemporaneos[`${ipc}-${idCategoria}`];
              await this.db.collection('concursos')
                .doc(`us_${this.concurso?.id}`)
                .collection('pruebas')
                .doc(`us_${ipc}`)
                .set({ cambios: true }, { merge: true });
              this.appService.updateShow(this.concurso?.id, ipc);
            } else if (response.accion === 'scratchear') {
              this.scratcheados[`${ipc}-${idCategoria}`] = true;
              delete this.inscritos[`${ipc}-${idCategoria}`];
              delete this.extemporaneos[`${ipc}-${idCategoria}`];
              this.appService.deleteEntriesAndUpdate(this.concurso?.id, ipc);
            } else if (response.accion === 'desinscribir') {
              delete this.inscritos[`${ipc}-${idCategoria}`];
              delete this.scratcheados[`${ipc}-${idCategoria}`];
              delete this.extemporaneos[`${ipc}-${idCategoria}`];
              // Eliminar inscripcion de binomio
              if (this.binomioSeleccionado !== null) {
                this.riders_clases.get(this.binomioSeleccionado).inscripciones = this.riders_clases.get(this.binomioSeleccionado)
                  .inscripciones.filter(p => p.ipc !== ipc);
                this.binomios = [...this.riders_clases.values()];
              }
              await this.appService.deleteEntriesAndUpdate(this.concurso?.id, ipc);
            } else if (response.accion === 'extemporaneo') {
              this.extemporaneos[`${ipc}-${idCategoria}`] = true;
              delete this.inscritos[`${ipc}-${idCategoria}`];
              delete this.scratcheados[`${ipc}-${idCategoria}`];
              await this.db.collection('concursos')
                .doc(`us_${this.concurso?.id}`)
                .collection('pruebas')
                .doc(`us_${ipc}`)
                .set({ cambios: true }, { merge: true });
              this.appService.updateShow(this.concurso?.id, ipc);
            }
            // Actualizar jueces si hay cambios
            await this.db.collection('concursos')
              .doc(`us_${this.concurso?.id}`)
              .collection('pruebas')
              .doc(`us_${ipc}`)
              .set({
                cambios: true,
                cambiosIngate: true
              }, { merge: true });
            // Arreglo formateado para mostrar las inscripciones en input chips
            let pruebas = [];
            for (const i in this.pruebas) {
              pruebas = pruebas.concat(this.pruebas[i]);
            }
            const inscritosChip = Object.keys(this.inscritos).map(p => {
              const ipc = p.split('-').shift();
              const index = pruebas.findIndex(pba => pba.ipc == ipc);

              return {
                ipc: pruebas[index].ipc,
                numero: pruebas[index].numero,
                fecha: pruebas[index].inicio,
                extemporaneo: false
              };
            });

            for (const i in this.extemporaneos) {
              if (this.extemporaneos[i]) {
                const ipc = i.split('-').shift();
                const index = inscritosChip.findIndex(p => p.ipc == ipc);
                if (index > -1) {
                  inscritosChip[index].extemporaneo = true;
                }
              }
            }

            this.inscritosChip = inscritosChip.sort((a, b) => {
              if (a.fecha > b.fecha) { return 1; }
              if (a.fecha < b.fecha) { return -1; }

              if (a.numero > b.numero) { return 1; }
              if (a.numero < b.numero) { return -1; }

              return 0;
            });

            this.pruebasFiltradas = [];
            for (const i in this.pruebas) {
              const pbas: any[] = this.pruebas[i].filter(p => Object.keys(this.inscritos).indexOf(`${p.ipc}-0`) > -1);
              if (pbas.length > 0) { this.pruebasFiltradas.push({ key: i, value: pbas }); }
            }
            if (refresh) {
              this.getRidersClasses();
              this.getEntryInfo();
              this.getCharges();
              Promise.all([this.getRidersClasses(), this.getCharges(), this.getEntryInfo()]).then(v => {
                // Limpiar formularios
                this.formCargo.reset();
                this.formCargo.setValue({
                  concepto: '',
                  notas: '',
                  cantidad: '',
                  monto: ''
                });
                this.formPago.reset();
                this.formPago.setValue({
                  metodo: '',
                  referencia: '',
                  fecha: this.currentDate.format('YYYY-MM-DD'),
                  monto: ''
                });
                $('#modal-productos').modal('hide');
                $('#loader').hide();
              });
            }
          } else {
            $('#loader').hide();
            $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader').hide();
          console.log(error);
          $.NotificationApp.send('Error', 'It has not been possible to register the rider/horse combination.', 'bottom-right', '#fa5c7c', 'error');
        }
      );
    }
  }

  /**
   * @deprecated Thu 13 Mar 2025
   * @param idBinomio string con el id del binomio
   * @param ipc string con el id de la prueba
   * @param categoria string con el id de la categoria
   * @returns void
   */
  public desinscribir(idBinomio, ipc, categoria) {
    $('#loader').show();
    this.binomioSeleccionado = idBinomio;
    this.inscribir(ipc, categoria, true);
  }

  /**
   * Desinscribe un caballo en un concurso
   * @param ipc string con el id de la prueba
   * @param class_number string con el numero de clase
   * @returns void
   */
  public desinscribirNest(idBinomio, ipc, class_number): Promise<void> {
    return firstValueFrom(this.entriesService.desinscribirNest(idBinomio, ipc, this.idUsuario, this.concurso?.id, this.entrySeleccionado, class_number)).then(
      async (response) => {
        if (!response.data.error) {
          this.binomioSeleccionado = idBinomio;
          delete this.inscritos[`${ipc}-${0}`];
          delete this.scratcheados[`${ipc}-${0}`];
          delete this.extemporaneos[`${ipc}-${0}`];
          // Eliminar inscripcion de binomio
          if (this.binomioSeleccionado !== null) {
            this.riders_clases.get(this.binomioSeleccionado).inscripciones = this.riders_clases.get(this.binomioSeleccionado)
              .inscripciones.filter(p => p.ipc !== ipc);
            this.binomios = [...this.riders_clases.values()];
          }
          await this.appService.deleteEntriesAndUpdate(this.concurso?.id, ipc);
          await this.db.collection('concursos')
            .doc(`us_${this.concurso?.id}`)
            .collection('pruebas')
            .doc(`us_${ipc}`)
            .set({
              cambios: true,
              cambiosIngate: true
            }, { merge: true });
          // Arreglo formateado para mostrar las inscripciones en input chips
          let pruebas = [];
          for (const i in this.pruebas) {
            pruebas = pruebas.concat(this.pruebas[i]);
          }
          const inscritosChip = Object.keys(this.inscritos).map(p => {
            const ipc = p.split('-').shift();
            const index = pruebas.findIndex(pba => pba.ipc == ipc);

            return {
              ipc: pruebas[index].ipc,
              numero: pruebas[index].numero,
              fecha: pruebas[index].inicio,
              extemporaneo: false
            };
          });

          for (const i in this.extemporaneos) {
            if (this.extemporaneos[i]) {
              const ipc = i.split('-').shift();
              const index = inscritosChip.findIndex(p => p.ipc == ipc);
              if (index > -1) {
                inscritosChip[index].extemporaneo = true;
              }
            }
          }

          this.inscritosChip = inscritosChip.sort((a, b) => {
            if (a.fecha > b.fecha) { return 1; }
            if (a.fecha < b.fecha) { return -1; }

            if (a.numero > b.numero) { return 1; }
            if (a.numero < b.numero) { return -1; }

            return 0;
          });

          this.pruebasFiltradas = [];
          for (const i in this.pruebas) {
            const pbas: any[] = this.pruebas[i].filter(p => Object.keys(this.inscritos).indexOf(`${p.ipc}-0`) > -1);
            if (pbas.length > 0) { this.pruebasFiltradas.push({ key: i, value: pbas }); }
          }

          Promise.all([this.getRidersClasses(), this.getCharges(), this.getEntryInfo()]).then(v => {
            // Limpiar formularios
            this.formCargo.reset();
            this.formCargo.setValue({
              concepto: '',
              notas: '',
              cantidad: '',
              monto: ''
            });
            this.formPago.reset();
            this.formPago.setValue({
              metodo: '',
              referencia: '',
              fecha: this.currentDate.format('YYYY-MM-DD'),
              monto: ''
            });
            $('#modal-productos').modal('hide');
            $('#loader').hide();
          });
        } else {
          $('#loader').hide();
          $.NotificationApp.send('Error', response.data.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.data.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send('Error', 'It has not been possible to register the rider/horse combination.', 'bottom-right', '#fa5c7c', 'error');
      }
    );
  }
}
